import React, { useContext, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Form,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  UncontrolledTooltip
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup";
import { IntzServiceContext } from "services/intzService";
import ControlledTypehead from "components/Common/ControlledTypehead";
import { AUTH_TYPE, CLIENT_SYNC_TYPE, ELSE_BEHAVIOR_TYPE, METHOD_TYPE, NODE_TYPE } from "services/intzEnum";
import { getMethodTypeCssClasses } from '../../AppAction/AppActionUIHelper';
import DummyDeleteModal from "pages/Configuration/DummyDeleteModal";
import MappingEntryModal from "./MappingEntryModal";
import TableContainerV8 from "components/Common/TableContainerV8";
import { getTargetCssClasses } from "../IntegrationUIHelper";
// Column
const Id = (cell) => {
    return cell.getValue() ? <span className={cell.row.original.syncType == CLIENT_SYNC_TYPE.DELETED ? "text-decoration-line-through text-secondary" : ""}>{cell.getValue()}</span> : <span className={"badge badge-soft-success"}>new</span>;
};

const SourcePropertyName = (cell) => {
    return cell.getValue() ? <span className={cell.row.original.syncType == CLIENT_SYNC_TYPE.DELETED ? "text-decoration-line-through text-secondary" : ""}>{cell.getValue()}</span> : '';
};
const TargetPropertyName = (cell) => {
    return cell.getValue() ? <span className={cell.row.original.syncType == CLIENT_SYNC_TYPE.DELETED ? "text-decoration-line-through text-secondary" : ""}>{cell.getValue()}</span> : '';
};
const Expression = (cell) => {
    return cell.getValue() ? <span className={cell.row.original.syncType == CLIENT_SYNC_TYPE.DELETED ? "text-decoration-line-through text-secondary" : ""}>{cell.getValue()}</span> : '';
};
const TargetType = (cell) => {
  return cell.getValue() ? <span className={cell.row.original.syncType == CLIENT_SYNC_TYPE.DELETED ? "text-decoration-line-through text-secondary" : ""}><span className={getTargetCssClasses(cell.getValue())}>{cell.getValue()}</span></span> : '';
};
const TransformNodeModal = props => {
    const { show, isEdit, data, onCloseClick } = props;
    const { intzSvc } = useContext(IntzServiceContext);

    const [entityData, setEntityData] = useState({});
    const [isEntryEdit, setIsEntryEdit] = useState(false);
    const [modal, setModal] = useState(false);
    const [dummyModal, setDummyModal] = useState(false);
    const [entries, setEntries] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [initialValues, setInitialValues] = React.useState({
        id: '',
        description:  '',
        sideNodeId: '',
        nextNodeId: '',
        exceptionNodeId:'',
        transformId:'',
        transform:{mappings:[]},
        isLogging : false
    });
    const formik  = useFormik({
    enableReinitialize: true,

    initialValues: initialValues,
    validationSchema: Yup.object({
        description: Yup.string().required("Please enter description"),
        transform: Yup.object({
            mappings: Yup.array().required("Please enter mappings")
        })
    }),

    onSubmit: (values) => {
        values.transform.mappings = entries;
        setEntries([]);
        onCloseClick(values);
    },
    });

    const columns = useMemo(
        () => [
    
          {
            header: '#',
            accessorKey: 'id',
            filterable: false,
            enableSorting: true,
            cell: (cellProps) => {
              return <Id {...cellProps} />;
            }
          },
          {
            header: 'Source Property',
            accessorKey: 'sourcePropertyName',
            filterable: false,
            enableSorting: true,
            cell: (cellProps) => {
              return <SourcePropertyName {...cellProps} />;
            }
          },
          {
            header: 'Target Property',
            accessorKey: 'targetPropertyName',
            filterable: false,
            enableSorting: true,
            cell: (cellProps) => {
              return <TargetPropertyName {...cellProps} />;
            }
          },
          {
            header: 'Expression',
            accessorKey: 'expression',
            enableSorting: false,
            filterable: false,
            cell: (cellProps) => {
              return <Expression {...cellProps} />;
              ;
            }
          },
          {
            header: 'Target',
            accessorKey: 'targetType',
            enableSorting: false,
            filterable: false,
            cell: (cellProps) => {
              return <TargetType {...cellProps} />;
              ;
            }
          },
          {
            header: 'Action',
            enableSorting: false,
            cell: (cellProps) => {
              return (
                <UncontrolledDropdown>
                  <DropdownToggle tag="a" href="#" className="card-drop">
                    <i className="mdi mdi-dots-horizontal font-size-18"></i>
                  </DropdownToggle>
    
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem href="#"
                      onClick={() => {
                        const entry = cellProps.row.original;
                        onEditClick(entry);
                      }
                      }
                    >
                      <i className="mdi mdi-pencil font-size-16 text-success me-1" id="edittooltip"></i>
                      Edit
                      <UncontrolledTooltip placement="top" target="edittooltip">
                        Edit
                      </UncontrolledTooltip>
                    </DropdownItem>
    
                    <DropdownItem href="#"
                      onClick={() => {
                        const entry = cellProps.row.original;
                        onDeleteClick(entry);
                      }}>
                      <i className="mdi mdi-trash-can font-size-16 text-danger me-1" id="deletetooltip"></i>
                      Delete
                      <UncontrolledTooltip placement="top" target="deletetooltip">
                        Delete
                      </UncontrolledTooltip>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              );
            }
          },
        ],
        []
      );

    useEffect(() => {
        if(show) {
            setInitialValues({...data});
            setEntries(data.transform?.mappings);
        } else {
            setInitialValues(initialValues);
        }
        
    }, [show]);

    
    const onDeleteClick = (entityData) => {
        setEntityData(entityData);
        setDummyModal(true);
      };
  
      const onEditClick = (entityData) => {
      setEntityData(entityData);
      setIsEntryEdit(true);
      setModal(true);
      };
  
      const onAddClick = () => {
      setEntityData({});
      setIsEntryEdit(false);
      setModal(true);
      };
      const handleModalClose = (entry) => {
          if(entry) {
            if (entry.id) {
              const index = entries.findIndex(row => row.id === entry.id);
              
              if(index !== -1) {
                entry.syncType = CLIENT_SYNC_TYPE.UPDATED;
                entries[index] = entry;
                  setEntries([...entries]);
              }
            } else {
              entry.syncType = CLIENT_SYNC_TYPE.CREATED;
              setEntries([entry,...entries]);
            }
  
          }
          setModal(false);
      
      };
      const handleDummyModalClose = (entry) => {
        // table 버그로 생각되는데 cell에서 바로 불러지는경우 이상현상이 일어난다
        // 때문에 dummy modal을 도입함
        if(entry) {
          if (entry.id) {
            const index = entries.findIndex(row => row.id === entry.id);
            
            if(index !== -1) {
              entry.syncType = CLIENT_SYNC_TYPE.DELETED;
              entries[index] = entry;
                setEntries([...entries]);
            }
          }
        }
        setDummyModal(false);
    
    };

    return (
    <Modal isOpen={show} size="xl">
        <ModalHeader tag="h4">
        {!!isEdit ? "Edit Transform Node" : "Add new Transform Node"}
        </ModalHeader>
        <ModalBody>
        <Form
            onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
            }}
            
        >
            <Row>
            {isEdit && <Col md="6">
                    <div className="mb-3">
                <Label className="form-label">Id</Label>
                <Input
                    name="id"
                    type="text"
                    readOnly
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.id ? ("#CN"+formik.values.id) : ""}
                    invalid={
                    formik.touched.id && formik.errors.id ? true : false
                    }
                    disabled={isLoading}
                />
                {formik.touched.id && formik.errors.id ? (
                    <FormFeedback type="invalid">{formik.errors.id}</FormFeedback>
                ) : null}
                </div></Col>}
                </Row>
                <Row>
            <Col md="12">
                <div className="mb-3">
                <Label className="form-label">Description</Label>
                <Input
                    name="description"
                    type="text"
                    placeholder="Node description"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description || ""}
                    invalid={
                    formik.touched.description && formik.errors.description ? true : false
                    }
                    disabled={isLoading}
                />
                {formik.touched.description && formik.errors.description ? (
                    <FormFeedback type="invalid">{formik.errors.description}</FormFeedback>
                ) : null}
                </div>

            </Col>
            </Row>
            <Row>
            <Col md="6">
            <div className="mb-3">
                <Label className="form-label">Log Enabled</Label><br/>
                <div className="form-check form-switch mb-3" >
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="isLogging"
                            checked={formik.values.isLogging ?? false}
                            onClick={e => {
                                formik.setFieldValue('isLogging', !formik.values.isLogging);
                            }}
                            onChange={e => {
                            }}
                          />
                        </div>
                </div>
            </Col>
            </Row>
            <Row>
            <Col md="12">
                <TableContainerV8
                    columns={columns}
                    data={entries}
                    initialSortBy={"id"}
                    isGlobalFilter={true}
                    addButtonLabel={"Mapping"}
                    onAddClick={onAddClick}
                    customPageSize={5}
                    className="custom-header-css"
                />

            </Col>
            </Row>
            <Row>
            <Col><hr /></Col>
            <Col style={{ flex: "0 0 80px" }}> Relationships </Col>
            <Col><hr /></Col>
            </Row> 
            <Row>
            <Col md="6">
            <div className="mb-3">
                <Label className="form-label">Next Node</Label>
                <ControlledTypehead
                odataFetch = {intzSvc.searchNode}
                value={formik.values.nextNodeId}
                filterColumn = "description"
                fetchCondition = {'integrationId eq ' + formik.values.integrationId}
                labelKey= {(option) => `${option["description"]}`}
                valueSelected = {(id) => {
                    formik.setFieldValue('nextNodeId',id);
                }}
                invalid={
                    formik.errors.nextNodeId ? true : false
                }
                />
                {formik.errors.nextNodeId ? (
                    <div className="text-danger"><small>{formik.errors.nextNodeId}</small></div>
                ) : null}
                </div>
            </Col>
            <Col md="6">
            <div className="mb-3">
                <Label className="form-label">Exception Node</Label>
                <ControlledTypehead
                odataFetch = {intzSvc.searchNode}
                value={formik.values.exceptionNodeId}
                enableNone = {true}
                filterColumn = "description"
                fetchCondition = {'integrationId eq ' + formik.values.integrationId}
                labelKey= {(option) => `${option["description"]}`}
                valueSelected = {(id) => {
                    formik.setFieldValue('exceptionNodeId',id);
                }}
                invalid={
                    formik.errors.exceptionNodeId ? true : false
                }
                />
                {formik.errors.exceptionNodeId ? (
                    <div className="text-danger"><small>{formik.errors.exceptionNodeId}</small></div>
                ) : null}
                </div>
            </Col>
            </Row>    
            <Row>
            <Col md="12">
                <button type="button" className="btn btn-danger" style={{float:"left"}} onClick={() => onCloseClick({...formik.values,syncType:CLIENT_SYNC_TYPE.DELETED})} disabled={isLoading}>Delete</button>
                <div className="text-end mt-2">
                <button type="button" className="btn btn-secondary" onClick={() => onCloseClick()} disabled={isLoading}>Cancel</button>
                &nbsp;&nbsp;
                <button
                    type="submit"
                    className="btn btn-success save-integration"
                    disabled={isLoading || !formik.isValid}

                >
                    Save
                </button>
                
                </div>
            </Col>
            </Row>
            <MappingEntryModal
                show={modal}
                isEdit={isEntryEdit}
                configurationId={formik.values.id}
                entityData={entityData}
                onClose={handleModalClose}
      />
      <DummyDeleteModal
                show={dummyModal}
                entityData={entityData}
                onClose={handleDummyModalClose}
      />
        </Form>
        
        </ModalBody>
    </Modal>
    )
}

export default TransformNodeModal