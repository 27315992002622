import React, { useContext, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Form,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  UncontrolledTooltip
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup";
import { IntzServiceContext } from "services/intzService";
import ControlledTypehead from "components/Common/ControlledTypehead";
import { AUTH_TYPE, CLIENT_SYNC_TYPE, METHOD_TYPE, NODE_TYPE } from "services/intzEnum";
import { getMethodTypeCssClasses } from '../../AppAction/AppActionUIHelper';
import DummyDeleteModal from "pages/Configuration/DummyDeleteModal";
import ParameterEntryModal from "./ParameterEntryModal";
import TableContainerV8 from "components/Common/TableContainerV8";
// Column
const Id = (cell) => {
    return cell.getValue() ? <span className={cell.row.original.syncType == CLIENT_SYNC_TYPE.DELETED ? "text-decoration-line-through text-secondary" : ""}>{cell.getValue()}</span> : <span className={"badge badge-soft-success"}>new</span>;
};

const Key = (cell) => {
    return cell.getValue() ? <span className={cell.row.original.syncType == CLIENT_SYNC_TYPE.DELETED ? "text-decoration-line-through text-secondary" : ""}>{cell.getValue()}</span> : '';
};
const Value = (cell) => {
    return cell.getValue() ? <span className={cell.row.original.syncType == CLIENT_SYNC_TYPE.DELETED ? "text-decoration-line-through text-secondary" : ""}>{cell.getValue()}</span> : '';
};

const AppActionNodeModal = props => {
    const { show, data, onCloseClick } = props;
    const { intzSvc } = useContext(IntzServiceContext);

    const [isLoading, setIsLoading] = useState(false);
    const [entityData, setEntityData] = useState({});
    const [isEntryEdit, setIsEntryEdit] = useState(false);
    const [modal, setModal] = useState(false);
    const [dummyModal, setDummyModal] = useState(false);
    const [entries, setEntries] = useState([]);
    const [appEndpointId, setAppEndpointId] = useState();
    const [appEndpointList, setAppEndpointList] = useState([]);
    const [appId, setAppId] = useState();
    const [appList, setAppList] = useState([]);
    const [appActionList, setAppActionList] = useState([]);
    const [isActionSet,setIsActionSet] = useState(false); 



    const [initialValues, setInitialValues] = React.useState({
        id: '',
        description:  '',
        type: NODE_TYPE.APPACTION,
        sideNodeId: '',
        nextNodeId: '',
        exceptionNodeId:'',
        appActionId:'',
        nodeParameters: [],
        isLogging : false
    });
    const formik  = useFormik({
    enableReinitialize: true,

    initialValues: initialValues,
    validationSchema: Yup.object({
        description: Yup.string().required("Please enter description"),
        appActionId: Yup.number().required("Please select an action")
    }),

    onSubmit: (values) => {
        values.nodeParameters = entries;
        onCloseClick(values);
    },
    });

    const columns = useMemo(
        () => [
    
          {
            header: '#',
            accessorKey: 'id',
            filterable: false,
            enableSorting: true,
            cell: (cellProps) => {
              return <Id {...cellProps} />;
            }
          },
          {
            header: 'Key',
            accessorKey: 'key',
            filterable: false,
            enableSorting: true,
            cell: (cellProps) => {
              return <Key {...cellProps} />;
            }
          },
          {
            header: 'Value',
            accessorKey: 'value',
            filterable: false,
            enableSorting: true,
            cell: (cellProps) => {
              return <Value {...cellProps} />;
            }
          },
          {
            header: 'Action',
            enableSorting: false,
            cell: (cellProps) => {
              return (
                <UncontrolledDropdown>
                  <DropdownToggle tag="a" href="#" className="card-drop">
                    <i className="mdi mdi-dots-horizontal font-size-18"></i>
                  </DropdownToggle>
    
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem href="#"
                      onClick={() => {
                        const entry = cellProps.row.original;
                        onEditClick(entry);
                      }
                      }
                    >
                      <i className="mdi mdi-pencil font-size-16 text-success me-1" id="edittooltip"></i>
                      Edit
                      <UncontrolledTooltip placement="top" target="edittooltip">
                        Edit
                      </UncontrolledTooltip>
                    </DropdownItem>
    
                    <DropdownItem href="#"
                      onClick={() => {
                        const entry = cellProps.row.original;
                        onDeleteClick(entry);
                      }}>
                      <i className="mdi mdi-trash-can font-size-16 text-danger me-1" id="deletetooltip"></i>
                      Delete
                      <UncontrolledTooltip placement="top" target="deletetooltip">
                        Delete
                      </UncontrolledTooltip>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              );
            }
          },
        ],
        []
      );

    useEffect(() => {
        if(show) {
            setInitialValues({...data});
            setEntries(data.nodeParameters);
            if (data.appActionId) {
              setIsActionSet(true);
              intzSvc.searchAppAction(100,0,'createdOn','desc', 
              'id eq ' + data.appActionId).then(({data}) => {
                setAppActionList(data.value);
              }).finally(() => {
              });
            } else {
              setIsActionSet(false);
              intzSvc.searchAppEndpoint(100,0,'createdOn','desc').then(({data}) => {
                setAppEndpointList(data.value);
              }).finally(() => {
              });
              intzSvc.searchApp(100,0,'createdOn','desc').then(({data}) => {
                setAppList(data.value);
              }).finally(() => {
              });
            }
        } else {
            setInitialValues(initialValues);
            setIsActionSet(false);
        }
        
    }, [show]);
    useEffect(() => {
      intzSvc.searchAppEndpoint(100,0,'createdOn','desc',appId ? 
      'appId eq ' + appId : '' ).then(({data}) => {
        setAppEndpointList(data.value);
      }).finally(() => {
      });
    }, [appId]);
    useEffect(() => {
      if (appEndpointId) { intzSvc.searchAppAction(100,0,'createdOn','desc', appEndpointId ?
      'endpointId eq ' + appEndpointId  : '').then(({data}) => {
        setAppActionList(data.value);
      }).finally(() => {
      }); }
     else {
      setAppActionList([]);
     }

    }, [appEndpointId]);

    const onDeleteClick = (entityData) => {
        setEntityData(entityData);
        setDummyModal(true);
      };
  
      const onEditClick = (entityData) => {
      setEntityData(entityData);
      setIsEntryEdit(true);
      setModal(true);
      };
  
      const onAddClick = () => {
      setEntityData({});
      setIsEntryEdit(false);
      setModal(true);
      };

    const handleModalClose = (entry) => {
        if(entry) {
          if (entry.id) {
            const index = entries.findIndex(row => row.id === entry.id);
            
            if(index !== -1) {
              entry.syncType = CLIENT_SYNC_TYPE.UPDATED;
              entries[index] = entry;
                setEntries([...entries]);
            }
          } else {
            entry.syncType = CLIENT_SYNC_TYPE.CREATED;
            setEntries([entry,...entries]);
          }

        }
        setModal(false);
    
    };

    const handleDummyModalClose = (entry) => {
        // table 버그로 생각되는데 cell에서 바로 불러지는경우 이상현상이 일어난다
        // 때문에 dummy modal을 도입함
        if(entry) {
          if (entry.id) {
            const index = entries.findIndex(row => row.id === entry.id);
            
            if(index !== -1) {
              entry.syncType = CLIENT_SYNC_TYPE.DELETED;
              entries[index] = entry;
                setEntries([...entries]);
            }
          }
        }
        setDummyModal(false);
    
    };
    return (
    <Modal isOpen={show} size="lg">
        <ModalHeader tag="h4">
        {"Edit App Action Node"}
        </ModalHeader>
        <ModalBody>
        <Form
            onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
            }}
            
        >
            <Row>
            <Col md="6">
                    <div className="mb-3">
                <Label className="form-label">Id</Label>
                <Input
                    name="id"
                    type="text"
                    readOnly
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.id ? ("#ND"+formik.values.id) : ""}
                    invalid={
                    formik.touched.id && formik.errors.id ? true : false
                    }
                    disabled={isLoading}
                />
                {formik.touched.id && formik.errors.id ? (
                    <FormFeedback type="invalid">{formik.errors.id}</FormFeedback>
                ) : null}
                </div></Col>
                
                </Row>
                {!isActionSet && <Row><Col md="6">
                    <div className="mb-3">
                <Label className="form-label">App</Label>
                <select
                    className="form-select"
                    value={appId}
                    onChange={(e) => {
                      setAppId(e.target.value);
                      setAppEndpointId("");
                      formik.setFieldValue('appActionId','');
                      formik.setFieldValue('appAction',undefined);
                    }}>
                    <option key={''} value={''}>
                    Select App
                      </option>
                    {appList.map(app => (
                      <option key={app.id} value={app.id}>
                        {app.description}
                      </option>
                    ))}
                  </select>
                </div></Col>
                <Col md="6">
                    <div className="mb-3">
                <Label className="form-label">App Endpoint</Label>
                <select
                    className="form-select"
                    value={appEndpointId}
                    onChange={(e) => {
                      setAppEndpointId(e.target.value);
                      formik.setFieldValue('appActionId','');
                      formik.setFieldValue('appAction',undefined);
                    }}>
                    <option key={''} value={''}>
                    Select App Endpoint
                      </option>
                    {appEndpointList.map(appEndpoint => (
                      <option key={appEndpoint.id} value={appEndpoint.id}>
                        {appEndpoint.description}
                      </option>
                    ))}
                  </select>
                </div></Col></Row>}
                <Row>
                <Col md="6">
            <div className="mb-3">
                <Label className="form-label">App Action</Label>
                <Input
                    name="appActionId"
                    type="select"
                    className="form-select"
                    style={{ pointerEvents: isActionSet ? 'none' : 'auto' }}
                    onChange={(e) => {
                      if (!isActionSet) {
                        formik.setFieldValue('appActionId',e.target.value);
                        if (e.target.value) intzSvc.getAppAction(e.target.value).then(({data}) =>{
                            formik.setFieldValue('appAction',data);
                        });
                        }

                    }}
                    onBlur={formik.handleBlur}
                    value={
                        formik.values.appActionId || ""
                    }
                    invalid={
                        formik.touched.appActionId && formik.errors.appActionId ? true : false
                    }
                > <option key={''} value={''}>
                Select App Action
                </option>
                {appActionList.map(action => (
                <option key={action.id} value={action.id}>
                    {action.description}
                </option>
                ))}
                </Input>
                {formik.errors.appActionId ? (
                    <div className="text-danger"><small>{formik.errors.appActionId}</small></div>
                ) : null}
                </div>
            </Col>
            <Col md="6">
            <div className="mb-3">
                <Label className="form-label">Log Enabled</Label><br/>
                <div className="form-check form-switch mb-3" >
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="isLogging"
                            checked={formik.values.isLogging ?? false}
                            onClick={e => {
                                formik.setFieldValue('isLogging', !formik.values.isLogging);
                            }}
                            onChange={e => {
                            }}
                          />
                        </div>
                </div>
            </Col>
            </Row>
                <Row>
            <Col md="12">
                <div className="mb-3">
                <Label className="form-label">Description</Label>
                <Input
                    name="description"
                    type="textarea"
                    placeholder="Node description"
                    rows="3"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description || ""}
                    invalid={
                    formik.touched.description && formik.errors.description ? true : false
                    }
                    disabled={isLoading}
                />
                {formik.touched.description && formik.errors.description ? (
                    <FormFeedback type="invalid">{formik.errors.description}</FormFeedback>
                ) : null}
                </div>

            </Col>
            </Row>
            <Row>
            <Col md="6">
            <div className="mb-3">
                <Label className="form-label">Endpoint</Label><br/>
                {formik.values.appAction?.endpoint?.description || ""}
                </div>
                
            </Col>
            <Col md="6">
            <div className="mb-3">
                <Label className="form-label">Method</Label><br/>
                <span className={getMethodTypeCssClasses(formik.values.appAction?.methodType)}>{formik.values.appAction?.methodType || ""}</span>
                </div>
                
            </Col>
            </Row>
            <Row>
            <Col md="12">
            <div className="mb-3">
                <Label className="form-label">Route</Label><br/>
                {formik.values.appAction?.route ? formik.values.appAction?.route : ""}
                </div>
                
            </Col>
            </Row>
            <Row>
            <Col md="6">
            <div className="mb-3">
                <Label className="form-label">Request Property</Label><br/>
                {formik.values.appAction?.requestPropertyName ? formik.values.appAction?.requestPropertyName : ""}
                </div>
                
            </Col>
            <Col md="6">
            <div className="mb-3">
                <Label className="form-label">Result Property</Label><br/>
                {formik.values.appAction?.resultPropertyName ? formik.values.appAction?.resultPropertyName : ""}
                </div>
                
            </Col>
            </Row>
            <Row>
            <Col md="12">
                <div className="mb-3">
                <Label className="form-label">Assertion</Label><br/>
                {formik.values.appAction?.assertion || ""}
                </div>
                
            </Col>
            </Row>
            <Row>
            <Col><hr /></Col>
            <Col style={{ flex: "0 0 80px" }}> Parameters </Col>
            <Col><hr /></Col>
            </Row>
            <Row>
            <Col md="12">
                <TableContainerV8
                    columns={columns}
                    data={entries}
                    initialSortBy={"id"}
                    isGlobalFilter={true}
                    addButtonLabel={"Parameter"}
                    onAddClick={onAddClick}
                    customPageSize={5}
                    className="custom-header-css"
                />

            </Col>
            </Row>
            <Row  className="mt-3">
            <Col><hr /></Col>
            <Col style={{ flex: "0 0 80px" }}> Relationships </Col>
            <Col><hr /></Col>
            </Row>
            <Row>
            <Col md="6">
            <div className="mb-3">
                <Label className="form-label">Next Node</Label>
                <ControlledTypehead
                odataFetch = {intzSvc.searchNode}
                value={formik.values.nextNodeId}
                filterColumn = "description"
                fetchCondition = {'integrationId eq ' + formik.values.integrationId}
                labelKey= {(option) => `${option["description"]}`}
                valueSelected = {(id) => {
                    formik.setFieldValue('nextNodeId',id);
                }}
                invalid={
                    formik.errors.nextNodeId ? true : false
                }
                />
                {formik.errors.nextNodeId ? (
                    <div className="text-danger"><small>{formik.errors.nextNodeId}</small></div>
                ) : null}
                </div>
            </Col>
            <Col md="6">
            <div className="mb-3">
                <Label className="form-label">Exception Node</Label>
                <ControlledTypehead
                odataFetch = {intzSvc.searchNode}
                value={formik.values.exceptionNodeId}
                enableNone = {true}
                filterColumn = "description"
                fetchCondition = {'integrationId eq ' + formik.values.integrationId}
                labelKey= {(option) => `${option["description"]}`}
                valueSelected = {(id) => {
                    formik.setFieldValue('exceptionNodeId',id);
                }}
                invalid={
                    formik.errors.exceptionNodeId ? true : false
                }
                />
                {formik.errors.exceptionNodeId ? (
                    <div className="text-danger"><small>{formik.errors.exceptionNodeId}</small></div>
                ) : null}
                </div>
            </Col>
            </Row>
            <Row>
            <Col md="12">
                <button type="button" className="btn btn-danger" style={{float:"left"}} onClick={() => onCloseClick({...formik.values,syncType:CLIENT_SYNC_TYPE.DELETED})} disabled={isLoading}>Delete</button>
                <div className="text-end">
                <button type="button" className="btn btn-secondary" onClick={() => onCloseClick()} disabled={isLoading}>Cancel</button>
                &nbsp;&nbsp;
                <button
                    type="submit"
                    className="btn btn-success save-integration"
                    disabled={isLoading || !formik.isValid}
                >
                    Save
                </button>
                
                </div>
            </Col>
            </Row>
        </Form>
        
        </ModalBody>
        <ParameterEntryModal
                show={modal}
                isEdit={isEntryEdit}
                appActionId={formik.values.id}
                entityData={entityData}
                onClose={handleModalClose}
      />
      <DummyDeleteModal
                show={dummyModal}
                entityData={entityData}
                onClose={handleDummyModalClose}
      />
    </Modal>
    )
}

export default AppActionNodeModal