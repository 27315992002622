import { INTEGRATION_LOG_TYPE } from "services/intzEnum";

  
  export function getLogTypeCssClasses(status) {
    var type;
    switch(status) {
      case INTEGRATION_LOG_TYPE.ENTRY:
      type="info"
      break;
      case INTEGRATION_LOG_TYPE.NODE:
      type="warning"
      break;
      case INTEGRATION_LOG_TYPE.EXIT:
      type="success"
      break;
      case INTEGRATION_LOG_TYPE.ERROR:
      type="danger"
      break;
      default:
        type=""
    }

    return `badge badge-soft-${
      type
    }`;
  }