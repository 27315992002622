import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Form
} from "reactstrap"
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik"
import * as Yup from "yup";

const ConfigurationEntryModal = props => {
    const { show, entityData, isEdit, onClose, errorMessage, configurationId } = props;

    return (
    <Modal isOpen={show}>
        <ModalHeader tag="h4">
            {!!isEdit ? "Edit Entry" : "Add new Entry"}
        </ModalHeader>
        <ModalBody>
        <Formik
            enableReinitialize
            initialValues={entityData || {
                id: '',
                key: '',
                value: ''
            }}
            validationSchema={Yup.object({
                key: Yup.string().required("Please enter key"),
                value: Yup.string().required("Please enter value")
            })}
            onSubmit={(values, { resetForm }) => {
                resetForm();
                onClose({integrationConfigurationId : configurationId, ...values});
            }}
        >
            {({ errors, touched }) => (
            <FormikForm>
                <Row>
                    <Col className="col-12">
                        {isEdit  && <div className="mb-3">
                        <Label className="form-label">#</Label>
                        <Field name="id" as={Input} readOnly invalid={touched.id && errors.id ? true : false} />
                        <ErrorMessage name="id" component={FormFeedback} />
                        </div>}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="mb-3">
                            <Label className="form-label">Key</Label>
                            <Field name="key" as={Input} placeholder="Key" invalid={touched.key && errors.key ? true : false} />
                            <ErrorMessage name="key" component={FormFeedback} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="mb-3">
                            <Label className="form-label">Value</Label>
                            <Field name="value" as={Input} type="textarea" rows="3" placeholder="Value" invalid={touched.value && errors.value ? true : false} />
                            <ErrorMessage name="value" component={FormFeedback} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="text-end">
                            <button type="button" className="btn btn-secondary" onClick={() => onClose()}>Cancel</button>
                            &nbsp;&nbsp;
                            <button type="submit" className="btn btn-success save-integration">Save</button>
                        </div>
                    </Col>
                </Row>
            </FormikForm>
            )}
        </Formik>
        </ModalBody>
    </Modal>
    )
}

export default ConfigurationEntryModal;
