import { INTEGRATION_CONFIGURATION_STATUS, RECURRING_TYPE } from "services/intzEnum";


  export function getStatusCssClasses(status) {
    var type;
    switch(status) {
      case INTEGRATION_CONFIGURATION_STATUS.DRAFT:
        type="info"
        break;
        case INTEGRATION_CONFIGURATION_STATUS.LIVE:
        type="success"
        break;
        case INTEGRATION_CONFIGURATION_STATUS.OFFLINE:
        type="warning"
        break;
      case INTEGRATION_CONFIGURATION_STATUS.DELETED:
        type="secondary"
        break;
          
      default:
        type=""
    }

    return `badge badge-soft-${
      type
    }`;
  }
  
  export function getRecurringTypeCssClasses(status) {
    var type;
    switch(status) {
      case RECURRING_TYPE.NONE:
        type="info"
        break;
        case RECURRING_TYPE.CRON:
        type="success"
        break;
        case RECURRING_TYPE.CHAINED:
        type="warning"
        break;
      default:
        type=""
    }

    return `badge badge-soft-${
      type
    }`;
  }