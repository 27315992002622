import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Form
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup";
import { IntzServiceContext } from "services/intzService";
import ControlledTypehead from "components/Common/ControlledTypehead";
import { CLIENT_SYNC_TYPE, NODE_TYPE } from "services/intzEnum";


const TransactionConfigurationModal = props => {
    const { show, isEdit, data, onCloseClick } = props;
    const { intzSvc } = useContext(IntzServiceContext);

    const [isLoading, setIsLoading] = useState(false);
    const initData = {
        id: '',
        integrationId :'',
        maxRetry:  0,
        keyPropertyName:'',
        resultPropertyName: '',
        additionalProperties: '',
        exceptionNodeId:'',
        transactionBeginNodeId:'',
        transactionEndNodeId: '',
        transactionBeginNode:{},
        transactionEndNode: {}
    }
    const [initialValues, setInitialValues] = React.useState(initData);
    const formik  = useFormik({
    enableReinitialize: true,

    initialValues: initialValues,
    validationSchema: Yup.object({
      keyPropertyName: Yup.string().required("Please enter key property"),
      resultPropertyName: Yup.string().required("Please enter result property")
    }),

    onSubmit: (values) => {
        onCloseClick(values);
    },
    });

    useEffect(() => {
        if (show) { 
            setInitialValues({...data})
        } else {
            setInitialValues(initData);
        }

    }, [show]);

    return (
    <Modal isOpen={show} size="lg">
        <ModalHeader tag="h4">
        {!!isEdit ? "Edit Transaction Configuration" : "Add new TransactionConfiguration"}
        </ModalHeader>
        <ModalBody>
        <Form
            onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
            }}
            
        >
            <Row>
            {isEdit && <Col md="6">
                    <div className="mb-3">
                <Label className="form-label">Id</Label>
                <Input
                    name="id"
                    type="text"
                    readOnly
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.id ? ("#TC"+formik.values.id) : ""}
                    invalid={
                    formik.touched.id && formik.errors.id ? true : false
                    }
                    disabled={isLoading}
                />
                {formik.touched.id && formik.errors.id ? (
                    <FormFeedback type="invalid">{formik.errors.id}</FormFeedback>
                ) : null}
                </div></Col>}
                </Row>
                <Row>
                <Col md="6">
                    <div className="mb-3">
                <Label className="form-label">Transaction Begin Nodde</Label>
                <ControlledTypehead
                odataFetch = {intzSvc.searchNode}
                fetchCondition = {'integrationId eq ' + formik.values.integrationId}
                value={formik.values.transactionBeginNodeId}
                filterColumn = "description"
                labelKey= {(option) => `${option["description"]}`}
                valueSelected = {(id) => {
                    formik.setFieldValue('transactionBeginNodeId',id);
                    formik.setFieldValue('transactionBeginNode',{});
                }}
                invalid={
                    formik.errors.transactionBeginNodeId ? true : false
                }
                />
                {formik.errors.transactionBeginNodeId ? (
                    <div className="text-danger"><small>{formik.errors.transactionBeginNodeId}</small></div>
                ) : null}
                </div></Col>
                <Col md="6">
                    <div className="mb-3">
                <Label className="form-label">Transaction End Node</Label>
                <ControlledTypehead
                odataFetch = {intzSvc.searchNode}
                fetchCondition = {'integrationId eq ' + formik.values.integrationId}
                value={formik.values.transactionEndNodeId}
                filterColumn = "description"
                labelKey= {(option) => `${option["description"]}`}
                valueSelected = {(id) => {
                    formik.setFieldValue('transactionEndNodeId',id);
                    formik.setFieldValue('transactionEndNode',{});
                }}
                invalid={
                    formik.errors.transactionEndNodeId ? true : false
                }
                />
                {formik.errors.transactionEndNodeId ? (
                    <div className="text-danger"><small>{formik.errors.transactionEndNodeId}</small></div>
                ) : null}
                </div></Col>
                </Row>
                <Row>

                  <Col md="6">
                      <div className="mb-3">
                  <Label className="form-label">Key Property</Label>
                  <Input
                      name="keyPropertyName"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.keyPropertyName}
                      invalid={
                      formik.touched.keyPropertyName && formik.errors.keyPropertyName ? true : false
                      }
                      disabled={isLoading}
                  />
                  {formik.touched.keyPropertyName && formik.errors.keyPropertyName ? (
                      <FormFeedback type="invalid">{formik.errors.keyPropertyName}</FormFeedback>
                  ) : null}
                  </div></Col>
                  <Col md="6">
                      <div className="mb-3">
                  <Label className="form-label">Result Property</Label>
                  <Input
                      name="resultPropertyName"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.resultPropertyName}
                      invalid={
                      formik.touched.resultPropertyName && formik.errors.resultPropertyName ? true : false
                      }
                      disabled={isLoading}
                  />
                  {formik.touched.resultPropertyName && formik.errors.resultPropertyName ? (
                      <FormFeedback type="invalid">{formik.errors.resultPropertyName}</FormFeedback>
                  ) : null}
                  </div>
                  </Col>
                </Row>
                <Row>
                <Col md="3">
                    <div className="mb-3">
                <Label className="form-label">Max Retry</Label>
                <Input
                    name="maxRetry"
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.maxRetry}
                    invalid={
                    formik.touched.maxRetry && formik.errors.maxRetry ? true : false
                    }
                    disabled={isLoading}
                />
                {formik.touched.maxRetry && formik.errors.maxRetry ? (
                    <FormFeedback type="invalid">{formik.errors.maxRetry}</FormFeedback>
                ) : null}
                </div></Col>


                </Row>
                <Row>
                <Col md="12">
                <div className="mb-3">
                <Label className="form-label">Additional Properties</Label>
                <Input
                    name="additionalProperties"
                    type="textarea"
                    rows="3"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.additionalProperties || ""}
                    invalid={
                    formik.touched.additionalProperties && formik.errors.additionalProperties ? true : false
                    }
                    disabled={isLoading}
                />
                {formik.touched.additionalProperties && formik.errors.additionalProperties ? (
                    <FormFeedback type="invalid">{formik.errors.additionalProperties}</FormFeedback>
                ) : null}
                </div>

            </Col>

                
            </Row>
                
            
            <Row>
            <Col md="12">
                <button type="button" className="btn btn-danger" style={{float:"left"}} onClick={() => onCloseClick({...formik.values,syncType:CLIENT_SYNC_TYPE.DELETED})} disabled={isLoading}>Delete</button>
                <div className="text-end">
                <button type="button" className="btn btn-secondary" onClick={() => onCloseClick()} disabled={isLoading}>Cancel</button>
                &nbsp;&nbsp;
                <button
                    type="submit"
                    className="btn btn-success save-integration"
                    disabled={isLoading || !formik.isValid}
                >
                    Save
                </button>
                
                </div>
            </Col>
            </Row>
        </Form>
        
        </ModalBody>
    </Modal>
    )
}

export default TransactionConfigurationModal