import React, { useContext, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Form,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip
} from "reactstrap"
import { useFormik, useFormikContext } from "formik"
import * as Yup from "yup";
import { IntzServiceContext } from "services/intzService";
import ControlledTypehead from "components/Common/ControlledTypehead";
import { AUTH_TYPE, CLIENT_SYNC_TYPE, METHOD_TYPE, RECURRING_TYPE } from "services/intzEnum";
import TableContainerV8 from "components/Common/TableContainerV8";
import ConfigurationEntryModal from "./ConfigurationEntryModal";
import DummyDeleteModal from "./DummyDeleteModal";

// Column
const Id = (cell) => {
return cell.getValue() ? <span className={cell.row.original.syncType == CLIENT_SYNC_TYPE.DELETED ? "text-decoration-line-through text-secondary" : ""}>{cell.getValue()}</span> : <span className={"badge badge-soft-success"}>new</span>;
};

const Key = (cell) => {
    return cell.getValue() ? <span className={cell.row.original.syncType == CLIENT_SYNC_TYPE.DELETED ? "text-decoration-line-through text-secondary" : ""}>{cell.getValue()}</span> : '';
};

const Value = (cell) => {
    return cell.getValue() ? <span className={cell.row.original.syncType == CLIENT_SYNC_TYPE.DELETED ? "text-decoration-line-through text-secondary" : ""}>{cell.getValue()}</span> : '';
};

const ConfigurationModal = props => {
    const { show, isEdit, id, onClose } = props;
    const { intzSvc } = useContext(IntzServiceContext);
    const [isLoading, setIsLoading] = useState(false);
    const [entries, setEntries] = useState([]);

    const formik  = useFormik({
    enableReinitialize: true,

    initialValues: {
        id: '',
        name : '',
        description:  '',
        cronExpression: '',
        configurationEntries:[]
    },
    validationSchema: Yup.object({
        description: Yup.string().required("Please enter description"),
        integrationId: Yup.number().required("Please select an integration"),
        recurringType: Yup.string().required("Please select recurring type")
    }),

    onSubmit: (values) => {
      values.configurationEntries = entries;
        if (isEdit) {
            intzSvc.updateIntegrationConfiguration(values).then(({data}) => {
                formik.resetForm();
                setEntries([]);
                onClose(true);
            });
        } else {
            intzSvc.createIntegrationConfiguration(values).then(({data}) => {
                formik.resetForm();
                setEntries([]);
                onClose(true);
            });
        }

    },
    });

    // Customber Column
  const columns = useMemo(
    () => [

      {
        header: '#',
        accessorKey: 'id',
        filterable: false,
        enableSorting: true,
        cell: (cellProps) => {
          return <Id {...cellProps} />;
        }
      },
      {
        header: 'Key',
        accessorKey: 'key',
        filterable: false,
        enableSorting: true,
        cell: (cellProps) => {
          return <Key {...cellProps} />;
        }
      },
      {
        header: 'Value',
        accessorKey: 'value',
        enableSorting: true,
        filterable: false,
        cell: (cellProps) => {
          return <Value {...cellProps} />;
          ;
        }
      },
      {
        header: 'Action',
        enableSorting: false,
        cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" href="#" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem href="#"
                  onClick={() => {
                    const entry = cellProps.row.original;
                    onEditClick(entry);
                  }
                  }
                >
                  <i className="mdi mdi-pencil font-size-16 text-success me-1" id="edittooltip"></i>
                  Edit
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </DropdownItem>

                <DropdownItem href="#"
                  onClick={() => {
                    const entry = cellProps.row.original;
                    onDeleteClick(entry);
                  }}>
                  <i className="mdi mdi-trash-can font-size-16 text-danger me-1" id="deletetooltip"></i>
                  Delete
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        }
      },
    ],
    []
  );

    useEffect(() => {
        if (show) {
            if (id) {   
                setIsLoading(true);
                intzSvc.getIntegrationConfiguration(id).then(({data}) => {
                    formik.setValues(data);
                    setEntries(data.configurationEntries);
                    setIsLoading(false);
                });
            } 
        } else {
            formik.resetForm();
            setEntries([]);
        }
    }, [show]);


    const [entityData, setEntityData] = useState({});
    const [isEntryEdit, setIsEntryEdit] = useState(false);
    const [modal, setModal] = useState(false);
    const [dummyModal, setDummyModal] = useState(false);

    /*const onDeleteClick = (entry) => {
      if(entry) {
        if (entry.id) {
          const index = entries.findIndex(row => row.id === entry.id);
          //if (CLIENT_SYNC_TYPE.DELETED == "DELETED") console.log("matched");
          if(index !== -1) {
            entry.syncType = "DELETED";
            entries[index] = entry;
              setEntries([...entries]);
          }
        } 

      }
    };*/

    const onDeleteClick = (entityData) => {
      setEntityData(entityData);
      setDummyModal(true);
    };

    const onEditClick = (entityData) => {
    setEntityData(entityData);
    setIsEntryEdit(true);
    setModal(true);
    };

    const onAddClick = () => {
    setEntityData({});
    setIsEntryEdit(false);
    setModal(true);
    };
    const handleModalClose = (entry) => {
        if(entry) {
          if (entry.id) {
            const index = entries.findIndex(row => row.id === entry.id);
            
            if(index !== -1) {
              entry.syncType = CLIENT_SYNC_TYPE.UPDATED;
              entries[index] = entry;
                setEntries([...entries]);
            }
          } else {
            entry.syncType = CLIENT_SYNC_TYPE.CREATED;
            setEntries([entry,...entries]);
          }

        }
        setModal(false);
    
    };
    const handleDummyModalClose = (entry) => {
      // table 버그로 생각되는데 cell에서 바로 불러지는경우 이상현상이 일어난다
      // 때문에 dummy modal을 도입함
      if(entry) {
        if (entry.id) {
          const index = entries.findIndex(row => row.id === entry.id);
          
          if(index !== -1) {
            entry.syncType = CLIENT_SYNC_TYPE.DELETED;
            entries[index] = entry;
              setEntries([...entries]);
          }
        }
      }
      setDummyModal(false);
  
  };

    return (
    <Modal isOpen={show} size="lg">
        <ModalHeader tag="h4">
        {!!isEdit ? "Edit Configuration" : "Add new Configuration"}
        </ModalHeader>
        <ModalBody>
        <Form
            onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
            }}
            
        >
            <Row>
            {isEdit && <Col md="6">
                    <div className="mb-3">
                <Label className="form-label">Id</Label>
                <Input
                    name="id"
                    type="text"
                    readOnly
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.id ? ("#CO"+formik.values.id) : ""}
                    invalid={
                    formik.touched.id && formik.errors.id ? true : false
                    }
                    disabled={isLoading}
                />
                {formik.touched.id && formik.errors.id ? (
                    <FormFeedback type="invalid">{formik.errors.id}</FormFeedback>
                ) : null}
                </div></Col>}
                <Col md="6">
            <div className="mb-3">
                <Label className="form-label">Integration</Label>
                <ControlledTypehead
                odataFetch = {intzSvc.searchIntegration}
                disabled = {isEdit}
                value={formik.values.integrationId}
                filterColumn = "name"
                labelKey= {(option) => `${option["name"]}`}
                valueSelected = {(id) => {
                    formik.setFieldValue('integrationId',id);
                }}
                invalid={
                    formik.errors.integrationId ? true : false
                }
                />
                {formik.errors.integrationId ? (
                    <div className="text-danger"><small>{formik.errors.integrationId}</small></div>
                ) : null}
                </div>
            </Col>
            </Row>
            <Row>
            <Col md="12">
                <div className="mb-3">
                <Label className="form-label">Name</Label>
                <Input
                    name="name"
                    type="text"
                    placeholder="Configuration description"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name || ""}
                    invalid={
                    formik.touched.name && formik.errors.name ? true : false
                    }
                    disabled={isLoading}
                />
                {formik.touched.name && formik.errors.name ? (
                    <FormFeedback type="invalid">{formik.errors.name}</FormFeedback>
                ) : null}
                </div>

            </Col>
            </Row>
                <Row>
            <Col md="12">
                <div className="mb-3">
                <Label className="form-label">Description</Label>
                <Input
                    name="description"
                    type="textarea"
                    rows="3"
                    placeholder="Configuration description"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description || ""}
                    invalid={
                    formik.touched.description && formik.errors.description ? true : false
                    }
                    disabled={isLoading}
                />
                {formik.touched.description && formik.errors.description ? (
                    <FormFeedback type="invalid">{formik.errors.description}</FormFeedback>
                ) : null}
                </div>

            </Col>
            </Row>
            <Row>
            <Col md="6">
            <div className="mb-3">
                <Label className="form-label">Recurring Type</Label>
                      <Input
                        name="recurringType"
                        type="select"
                        className="form-select"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={
                            formik.values.recurringType || ""
                        }
                        invalid={
                            formik.touched.recurringType && formik.errors.recurringType ? true : false
                        }
                      > <option value="">Select Type</option>
                        <option value={RECURRING_TYPE.NONE}>NONE</option>
                        <option value={RECURRING_TYPE.CRON}>CRON</option>
                        <option value={RECURRING_TYPE.CHAINED}>CHAINED</option>
                      </Input>
                      {formik.touched.recurringType && formik.errors.recurringType ? (
                        <FormFeedback type="invalid">{formik.errors.recurringType}</FormFeedback>
                      ) : null}
                </div>
            </Col>
            <Col md="12">
            <div className="mb-3">
                <Label className="form-label">Cron Expression</Label>
                <Input
                    name="cronExpression"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.cronExpression ? formik.values.cronExpression : ""}
                    invalid={
                    formik.touched.cronExpression && formik.errors.cronExpression ? true : false
                    }
                    disabled={isLoading}
                />
                {formik.touched.cronExpression && formik.errors.cronExpression ? (
                    <FormFeedback type="invalid">{formik.errors.cronExpression}</FormFeedback>
                ) : null}
                </div>
            </Col>
            
            </Row>
            <Row>
            <Col md="12">
                <div className="mb-3">
                <Label className="form-label">Next Configuration</Label>
                <ControlledTypehead
                odataFetch = {intzSvc.searchIntegrationConfiguration}
                value={formik.values.nextIntegrationConfigurationId}
                filterColumn = "name"
                labelKey= {(option) => `${option["name"]}`}
                valueSelected = {(id) => {
                    formik.setFieldValue('nextIntegrationConfigurationId',id);
                }}
                invalid={
                    formik.errors.nextIntegrationConfigurationId ? true : false
                }
                />
                {formik.errors.nextIntegrationConfigurationId ? (
                    <div className="text-danger"><small>{formik.errors.nextIntegrationConfigurationId}</small></div>
                ) : null}
                </div>

            </Col>
            </Row>
            <Row>
            <Col md="12">
                <TableContainerV8
                    columns={columns}
                    data={entries}
                    initialSortBy={"key"}
                    isGlobalFilter={true}
                    addButtonLabel={"Entry"}
                    onAddClick={onAddClick}
                    customPageSize={5}
                    className="custom-header-css"
                />

            </Col>
            </Row>
            <Row>
            <Col md="12">
                <div className="text-end mt-2">
                <button type="button" className="btn btn-secondary" onClick={() => onClose()} disabled={isLoading}>Cancel</button>
                &nbsp;&nbsp;
                <button
                    type="submit"
                    className="btn btn-success save-integration"
                    disabled={isLoading}
                >
                    Save
                </button>
                
                </div>
            </Col>
            </Row>
            <ConfigurationEntryModal
                show={modal}
                isEdit={isEntryEdit}
                configurationId={formik.values.id}
                entityData={entityData}
                onClose={handleModalClose}
      />
      <DummyDeleteModal
                show={dummyModal}
                entityData={entityData}
                onClose={handleDummyModalClose}
      />
        </Form>
        
        </ModalBody>
    </Modal>
    )
}
export default ConfigurationModal;