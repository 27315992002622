import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
    Button,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown
} from "reactstrap";
import ReactFlow, {
    MiniMap,
    Controls,
    Panel,
    Background,
    addEdge,
    MarkerType
  } from 'reactflow';
import 'reactflow/dist/style.css';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import LinearProgress from "../../../components/Common/LinearProgress";
import AppActionNode from "./AppActionNode";
import './IntegrationDetail.css';
import { IntzServiceContext } from "services/intzService";
import { useParams } from "react-router-dom";
import { CLIENT_SYNC_TYPE, ELSE_BEHAVIOR_TYPE, NODE_TYPE } from "services/intzEnum";
import IteratorNode from "./IteratorNode";
import ConditionNode from "./ConditionNode";
import TransformNode from "./TransformNode";
import TransactionConfigurationGroupNode from "./TransactionConfigurationGroupNode";
import { useDispatch, useSelector } from 'react-redux';
import { setIntegration } from "store/integration/actions";
import IntegrationDetailModal from "./IntegrationDetailModal";
import { getStatusCssClasses } from "../IntegrationUIHelper";
import SimpleNoticeModal from "components/Common/SimpleNoticeModal";

const IntegrationDetail = props => {

  //meta title
  document.title = "Integration Details";
  let { id } = useParams();

  //const [integration, setIntegration] = useState({});
  const { intzSvc } = useContext(IntzServiceContext);
  const [isLoading, setIsLoading] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [nodes, setNodes, onNodesChange] = useState([]);
  const integration = useSelector(state=> state.Integration.data);

  const dispatch = useDispatch();
  const [edges, setEdges, onEdgesChange] = useState([]);


  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);
  const defaultEdgeOptions = { animated: true };
  const nodeTypes = useMemo(() => ({ "APPACTION" : AppActionNode, "ITERATOR" : IteratorNode, "CONDITION" : ConditionNode, "TRANSFORM" : TransformNode , "TRANSACTION":TransactionConfigurationGroupNode}), []);
  const onClick = useCallback(() => {
    //
  }, []);

const updateGroupPosition = (nodes,setNodes) => {
  var paddingTop = 150;
  var paddingOther = 50;
  nodes.filter(node => node.type == "TRANSACTION").forEach( (parentNode,parentNodeIndex) => {
  // Create a copy of the nodes
  let updatedNodes = [...nodes];

  let groupedNodes = updatedNodes.filter(node => node.parentNode == parentNode.id);
  if (groupedNodes.length > 0) {

  let offsetX = Math.min(...groupedNodes.map(n => n.position.x)) - paddingOther;

  let maxWidth = Math.max(...groupedNodes.map(n => n.position.x + n.style.width)) + paddingOther;


  parentNode.position.x = parentNode.position.x + offsetX;
  parentNode.style.width = maxWidth - offsetX; //도대체 왜 width는 바로적용이 안될까..
  
  
  
  let offsetY = Math.min(...groupedNodes.map(n => n.position.y)) - paddingTop;

  let maxHeight = Math.max(...groupedNodes.map(n => n.position.y + n.style.height)) + paddingOther;


  parentNode.position.y = parentNode.position.y + offsetY;
  parentNode.style.height = maxHeight - offsetY; //도대체 왜 width는 바로적용이 안될까.. (position이 변하면 rerender를 하는데 width, height변화는 rerender가 안되는듯)
  
  
  
  // Put the updated parent node back into the nodes array


  updatedNodes[parentNodeIndex] = parentNode;

  for (let eachNode of updatedNodes) {
    if (eachNode.parentNode == parentNode.id) {
      eachNode.position.x = eachNode.position.x - offsetX + 0.1 ; // invoke rerender when no offset
      eachNode.position.y = eachNode.position.y - offsetY + 0.1 ;
    }
  }
  


  }
  



  // Call the setter function to update the nodes state
  setNodes(updatedNodes);
  
  });
};
  


  const onNodeDrag = useCallback((event, node) => {
    /*setNodes((ns) => { 
      return positionGroupNode(ns.map((n) => n.id === node.id ? {...n, position: node.position} : n))
    });*/
    setNodes((ns) => ns.map((n) => n.id === node.id ? {...n, position: node.position, 
      data:{...n.data, syncType:n.data.syncType == CLIENT_SYNC_TYPE.DELETED ? CLIENT_SYNC_TYPE.DELETED : CLIENT_SYNC_TYPE.UPDATED}} : n));

  }, [setNodes])


  useEffect(() => {
    if (id) {   
      setIsLoading(true);
      intzSvc.getIntegration(id).then(({data}) => {
          dispatch(setIntegration(data));
          setIsLoading(false);
      });
  } 
  }, [id]);

  useEffect(() => {
    refreshDiagram(integration);
  }, [integration]);


  const groupNodes= (nodes, groups) => {
    const usedIds = new Set();
    function findNextAndGroup(node, group, endId) {
      group.push(node);
      usedIds.add(node.id);

      // If groupEndId is defined and equals groupId, it's the end of this group
      if (node.id == endId) {
        return;
      }

      if (node.sideNodeId && !usedIds.has(node.sideNodeId)) {
          const sideNode = nodes.find(nextNode => nextNode.id === node.sideNodeId);
          if (sideNode) {
              findNextAndGroup(sideNode, group, endId);
          }
      }

      if (node.nextNodeId && !usedIds.has(node.nextNodeId)) {
          const nextNode = nodes.find(nextNode => nextNode.id === node.nextNodeId);
          if (nextNode) {
              findNextAndGroup(nextNode, group, endId);
          }
      }

      if (node.exceptionNodeId && !usedIds.has(node.exceptionNodeId)) {
        const exceptionNode = nodes.find(nextNode => nextNode.id === node.exceptionNodeId);
        if (exceptionNode) {
            findNextAndGroup(exceptionNode, group, endId);
        }
    }

  }

    return groups.reduce((acc,item) => {
      var group = [];
      if (nodes.find(n => n.id == item.data.transactionBeginNodeId)) {
        findNextAndGroup(nodes.find(n => n.id == item.data.transactionBeginNodeId), group, item.data.transactionEndNodeId);
      }
      acc[item.data.id] = group;
      return acc;
    },{})


  }


  const refreshDiagram = (data) => {
    
    if (data.nodes) {
      
      // TODO: show error message when transaction is not properly setup
      // multiple, nested transactions -> 안함


      var groups = [...data.transactionConfigurations.map(tc => {
        var existingGroup = nodes.find(n=> n.id == "tc_" + tc.id)
        if (existingGroup) {
          return {...existingGroup, data:tc, style:{width:tc.width, height:tc.height}};
        } else {
          return {
            id: "tc_"+tc.id,
            type: "TRANSACTION",
            data: tc,
            style:{width:tc.width, height:tc.height},
            position:{x:tc.positionX, y:tc.positionY}
          }
        }
      })];


      var groupedNodes = groupNodes(data.nodes, groups);

      var nodeData = data.nodes.map(node => {
        var parentNodeId = null;
        for (let groupId in groupedNodes) {
          if (groupedNodes[groupId].some(obj => obj.id === node.id)) {
            parentNodeId = "tc_" + groupId;
          }
        }
        if (data.entryNodeId == node.id) node = {...node,isStartNode:true}
        if (data.globalExceptionNodeId == node.id) node = {...node,isGlobalExceptionNode:true}


        var existingNode = nodes.find(n => n.id == node.id+"");
        if (existingNode) {
          return { ...existingNode, data: node, style:{width:node.width, height:node.height},
          parentNode: parentNodeId}
        } else {
          return { id: node.id+"", type: node.type, position: { x: node.positionX, y: node.positionY }, style : {width:node.width, height:node.height}, data: node,
          parentNode: parentNodeId}
        }
        
      });
    
      setNodes(groups.concat(nodeData));
      var nextNode = data.nodes.filter(node => node.nextNodeId).map(node => ({ id:node.id +"_n_" + node.nextNodeId, source: node.id + "", target: node.nextNodeId + "",
      sourceHandle: "bottom", targetHandle: "top",
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 20,
        height: 20,
        color: '#a6b0cf',
      },
      style: {
        strokeWidth: 2,
        stroke: '#a6b0cf',
      }     }));
      var sideNode = data.nodes.filter(node => node.sideNodeId).map(node => ({ id:node.id +"_s_" + node.sideNodeId, source: node.id + "", target: node.sideNodeId + "",
      sourceHandle: "right", targetHandle: "top",
      markerEnd: {
        width: 20,
        height: 20,
        color: '#a6b0cf',
      },
      style: {
        strokeWidth: 2,
        stroke: '#a6b0cf',
      }     }));

      var exceptionNode = data.nodes.filter(node => node.exceptionNodeId).map(node => ({ id:node.id +"_e_" + node.exceptionNodeId, source: node.id + "", target: node.exceptionNodeId + "",
      sourceHandle: "left", targetHandle: "top",
      markerEnd: {
        width: 20,
        height: 20,
        color: '#e83e8c',
      },
      style: {
        strokeWidth: 2,
        stroke: '#e83e8c',
      }     }));
      setEdges(nextNode.concat(sideNode).concat(exceptionNode));

      updateGroupPosition(groups.concat(nodeData),setNodes)
    }
  }
  
  const onSaveChanges = () => {

    //check delete error
    const deletedNodes = integration.nodes.filter(node => node.syncType === CLIENT_SYNC_TYPE.DELETED);
    const deletedNodeIds = deletedNodes.map(node => node.id);

    const nodesWithRelationToDeletedNodes = integration.nodes.filter(node =>
      deletedNodeIds.includes(node.nextNodeId) ||
      deletedNodeIds.includes(node.sideNodeId) ||
      deletedNodeIds.includes(node.exceptionNodeId)
    );

    const tcWithRelationToDeletedNodes = integration.transactionConfigurations.filter(tc =>
      deletedNodeIds.includes(tc.transactionBeginNodeId) ||
      deletedNodeIds.includes(tc.transactionEndNodeId)
    );

    if (nodesWithRelationToDeletedNodes.length > 0 || tcWithRelationToDeletedNodes.length > 0) {
      setErrorMessage("Please remove relationships to deleted nodes");
      setErrorModal(true);
      return;
    }

    integration.nodes.map(node => {
      var uiNode = nodes.find(n => n.id === node.id+"");
      node.positionX = Math.floor(uiNode.position.x);
      node.positionY = Math.floor(uiNode.position.y);
      node.syncType = uiNode.data.syncType;
    });
    integration.transactionConfigurations.map(tc => {
      var uiNode = nodes.find(n => n.id === "tc_"+tc.id);
      tc.positionX = Math.floor(uiNode.position.x);
      tc.positionY = Math.floor(uiNode.position.y);
      tc.syncType = uiNode.data.syncType;
    });

    setIsLoading(true);
    intzSvc.updateIntegration(integration).then(() => {
      intzSvc.getIntegration(id).then(({data}) => {
        dispatch(setIntegration(data));
        setIsLoading(false);
    });
    });



    //이걸 어케 하는게 좋을지 모르겠는데 계획은 이러함
    // 1. SyncStatus created인 애들을 create해줌 
    // 2. Create된 id기준으로 업데이트를 해줘야함 (관계가 다른node의 side note, exception node, nextNode에 있을수 있음, tc 에도 있을수 있음)
    // 3. syncstatus updated인 애들을 update해줌
    // 4. integration자체를 업데이트해줄 필요가 있을까? -> 이건 patch로 entryNode, globalExceptionNode 이렇게만 업데이트

    // 위 솔루션의 문제점은 저 update가 한번에 transaction으로 묶이지 않에서 중간에 뻑나는 경우가 생긴다는것이다.
    // 서버에서 트랜즈액션 안에서 해결을 하면 더 좋겠지만 문제가 있는데
    // created 된 애들은 id 0 으로 서버에 보내줘야 되서 연결을 해 줄수가 없다 서버에서도 클라이언트에서도
    // 하지만 상황상 이걸 하는게 정석일거 같다..
    // 이것도 방법이 있긴 한데 new한애들은 save를 누르는순간 실제로 new를 해주는거임 <<-- 이게 맞네

    // 그럼 delete는? ->delete 버튼을 모달에 두고 상태를 deleted로 하되 save changes를 누를때 전부 검새를 해서 연결이 있으면 
    // save안되게 하면 될듯..?

    
  };
  const handleModalClose = (data) => {
    if (data) {
      dispatch(setIntegration({...data,syncType:CLIENT_SYNC_TYPE.UPDATED}));
      setEditModal(false);
    } else {
      setEditModal(false);
    }
  }
  const handleErrorClose = () => {
    setErrorModal(false)
  }
  
  const addNode = (type) => {
    var newNode = { integrationId: id,positionX: 100,positionY: 100, width:400,height:200, description: "Untitled"};

    switch (type) {
      case NODE_TYPE.APPACTION:
        newNode = {description:"", type:NODE_TYPE.APPACTION, ...newNode};
        break;
      case NODE_TYPE.ITERATOR:
        newNode = {description:"",iterator:{}, type:NODE_TYPE.ITERATOR, ...newNode};
        break;
      case NODE_TYPE.CONDITION:
        newNode = {description:"",condition:{elseBehaviorType :ELSE_BEHAVIOR_TYPE.BREAK}, type:NODE_TYPE.CONDITION, ...newNode};
        break;
      case NODE_TYPE.TRANSFORM:
        newNode = {description:"",transform:{}, type:NODE_TYPE.TRANSFORM, ...newNode};
        break;
      case NODE_TYPE.TRANSACTION:
        break;
    }


    setIsLoading(true);
    if (type == NODE_TYPE.TRANSACTION) {
      intzSvc.createTransactionConfiguration(newNode).then(() => {
        intzSvc.getIntegration(id).then(({data}) => {
          dispatch(setIntegration(data));
          setIsLoading(false);
        });
      });
    } else {
      intzSvc.createNode(newNode).then(() => {
        intzSvc.getIntegration(id).then(({data}) => {
          if(data.nodes.length == 1) {
            data.entryNodeId = data.nodes[0].id;
            intzSvc.updateIntegration(data).then(() => {
              dispatch(setIntegration(data));
              setIsLoading(false);
            });
          } else {
            dispatch(setIntegration(data));
            setIsLoading(false);
          }


        });
      });
    }

  };

  const isUpdated = () => {
    return integration.syncType == CLIENT_SYNC_TYPE.UPDATED || nodes.some(n=> n.data.syncType== CLIENT_SYNC_TYPE.UPDATED || n.data.syncType== CLIENT_SYNC_TYPE.CREATED || n.data.syncType== CLIENT_SYNC_TYPE.DELETED);
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Integration" breadcrumbItem="Integration Detail" 
          altTitle={(isUpdated() ? "* "  : "") + (integration ? integration.name : "")} button={<><span className={getStatusCssClasses(integration?.status)}>{integration?.status}</span>
          <Button className="btn btn-sm btn-link text-white" onClick={() => {setEditModal(true)}}><i className="bx bx-cog"></i></Button>
          </>} />
          <Row><Col>
          
  <UncontrolledDropdown
    >
      <DropdownToggle tag="a" href="#" className={isUpdated() ?"btn btn-add" : "btn btn-add btn-secondary"} disabled={isUpdated()}>
        Add a Node{" "}
        <i className="mdi mdi-chevron-down" />
      </DropdownToggle>

      <DropdownMenu className="dropdown-add">
        <DropdownItem onClick={()=> addNode(NODE_TYPE.APPACTION)}>App Action</DropdownItem>
        <DropdownItem onClick={()=> addNode(NODE_TYPE.ITERATOR)}>Iterator</DropdownItem>
        <DropdownItem onClick={()=> addNode(NODE_TYPE.CONDITION)}>Condition</DropdownItem>
        <DropdownItem onClick={()=> addNode(NODE_TYPE.TRANSFORM)}>Transform</DropdownItem>
        <DropdownItem onClick={()=> addNode(NODE_TYPE.TRANSACTION)} disabled={integration.transactionConfigurations?.length > 0}>Transaction</DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>


      <Button onClick={onSaveChanges} className="btn btn-info btn-save" disabled={!isUpdated()}>
        Save Changes
      </Button>
          </Col></Row>
          <div style={{ width: '100vw', height: '80vh',maxWidth: '100%'}}>
            {isLoading && <LinearProgress/>}
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes} 
        defaultEdgeOptions={defaultEdgeOptions}
        onNodeDrag={onNodeDrag}
        onNodeDragStop={() => updateGroupPosition(nodes,setNodes)}
      >
        <svg style={{ overflow: 'visible', position: 'absolute' }}>
    <defs>
      <marker id="arrowhead" markerWidth="10" markerHeight="7" 
      refX="0" refY="3.5" orient="auto">
        <polygon points="0 0, 10 3.5, 0 7" />
      </marker>
    </defs>
  </svg>
              <Controls />
        <MiniMap />
        <Background variant="dots" gap={24} size={1} />
        </ReactFlow>
    </div>
          </Container>
          </div>
          <IntegrationDetailModal
          data={integration}
          show={editModal}
          isEdit={true}
          onCloseClick={handleModalClose}
          />
          <SimpleNoticeModal 
          message={errorMessage}
          onCloseClick={handleErrorClose}
          show={errorModal}
          />
    </React.Fragment>
  );
};


export default IntegrationDetail;
