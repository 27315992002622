import React, { useEffect, useState, useMemo, useContext } from "react";
import Moment from 'react-moment';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
  FormFeedback,
  Label,
  Form,
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import { IntzServiceContext } from "services/intzService";
import ControlledTableContainerV8 from "components/Common/ControlledTableContainerV8";
import { getStatusCssClasses } from "./IntegrationTransactionUIHelper";
import IntegrationTransactionModal from "./IntegrationTransactionModal";
import { INTEGRATION_TRANSACTION_STATUS } from "services/intzEnum";

// Column
const Id = (cell) => {
  return cell.getValue() ? "#TR" + cell.getValue() : '';
};
const Configuration = (cell) => {
  return cell.getValue() ? cell.getValue() : '';
};
const Key = (cell) => {
    return cell.getValue() ? cell.getValue() : '';
};
const Status = (cell) => {
  return cell.getValue() ? <span className={getStatusCssClasses(cell.getValue())}>{cell.getValue()}</span> : '';
};
const Result = (cell) => {
  return cell.getValue() ? cell.getValue() : '';
};
const ErrorMessage = (cell) => {
  return cell.getValue() ? cell.getValue() : '';
};

const StartedOn = (cell) => {
    return cell.getValue() ? <Moment format='lll'>{cell.getValue()}</Moment>: '';
};
const FinishedOn = (cell) => {
  return cell.getValue() ? <Moment format='lll'>{cell.getValue()}</Moment>: '';
};
const IntegrationTransactionList = props => {

  //meta title
  document.title = "Transactions | Integratiz";
  const { intzSvc } = useContext(IntzServiceContext);
  const [modal, setModal] = useState(false);

  const [rerender, setRerender] = useState(0);
  const [integrationConfigurationId , setIntegrationConfigurationId] = useState();
  const [integrationConfigurationList, setIntegrationConfigurationList] = useState([]);
  const [status, setStatus] = useState("");

  const handleEntityClick = arg => {

  };

  // Customber Column
  const columns = useMemo(
    () => [

      {
        header: 'Id',
        accessorKey: 'id',
        filterable: false,
        enableSorting: true,
        cell: (cellProps) => {
          return <Id {...cellProps} />;
        }
      },
      {
        header: 'Configuration',
        accessorKey: 'integrationConfiguration.name',
        filterable: false,
        enableSorting: false,
        cell: (cellProps) => {
          return <Configuration {...cellProps} />;
        }
      },
      {
        header: 'Key',
        accessorKey: 'key',
        enableSorting: true,
        filterable: false,
        cell: (cellProps) => {
          return <Key {...cellProps} />;
          ;
        }
      },
      {
        header: 'Status',
        accessorKey: 'status',
        enableSorting: true,
        filterable: false,
        cell: (cellProps) => {
          return <Status {...cellProps} />;
        }
      },
      {
        header: 'Result',
        accessorKey: 'result',
        enableSorting: false,
        filterable: false,
        cell: (cellProps) => {
          return <Result {...cellProps} />;
        }
      },
      {
        header: 'Error Message',
        accessorKey: 'errorMessage',
        enableSorting: false,
        filterable: false,
        cell: (cellProps) => {
          return <ErrorMessage {...cellProps} />;
        }
      },
      {
        header: 'Started On',
        accessorKey: 'startedOn',
        enableSorting: true,
        cell: (cellProps) => {
          return <StartedOn {...cellProps} />;
        }
      },
      {
        header: 'Finished On',
        accessorKey: 'finishedOn',
        enableSorting: true,
        cell: (cellProps) => {
          return <FinishedOn {...cellProps} />;
        }
      },
      {
        header: 'Action',
        enableSorting: false,
        cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" href="#" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem href="#"
                  onClick={() => {
                    const entityData = cellProps.row.original;
                    onDetailsClick(entityData);
                  }
                  }
                >
                  <i className="mdi mdi-clipboard-text-outline font-size-16 text-success me-1" id="edittooltip"></i>
                  Details
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Details
                  </UncontrolledTooltip>
                </DropdownItem>

              </DropdownMenu>
            </UncontrolledDropdown>
          );
        }
      },
    ],
    []
  );
  //delete 
  const [entityData, setEntityData] = useState({});

  const onDetailsClick = (entityData) => {
    setEntityData(entityData);
    setModal(true);
  };
  const handleModalClose = () => {
      setModal(false);
  };
  useEffect(() => {
    intzSvc.searchIntegrationConfiguration(100,0,'createdOn','desc').then(({data}) => {
      setIntegrationConfigurationList(data.value);
    }).finally(() => {
    });
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <Breadcrumbs title="Transaction" breadcrumbItem="List" 
          altTitle={"LIST : "} button={
            <div style={{"display":"flex"}}>
              <select
          className="form-select form-select-sm mt-1 me-2"
          style={{"width": "200px"}}
          value={status}
          onChange={(e) => {
            setStatus(e.target.value);
            setRerender(rerender +  1);
          }}>
          <option key={''} value={''}>
          All Status
            </option>
            <option value={INTEGRATION_TRANSACTION_STATUS.SUCCESS}>
            Success
            </option>
            <option value={INTEGRATION_TRANSACTION_STATUS.FAILED}>
            Failed
            </option>
            <option value={INTEGRATION_TRANSACTION_STATUS.SUCCESS_WITH_WARNING}>
            Success with warning
            </option>
            <option value={INTEGRATION_TRANSACTION_STATUS.PROCESSING}>
            Processing
            </option>
        </select>
        <select
            className="form-select form-select-sm mt-1"
            value={integrationConfigurationId}
            onChange={(e) => {
              setIntegrationConfigurationId(e.target.value);
              setRerender(rerender +  1);
            }}>
            <option key={''} value={''}>
            All Configurations
              </option>
            {integrationConfigurationList.map(configuration => (
              <option key={configuration.id} value={configuration.id}>
                {configuration.description}
              </option>
            ))}
          </select>
          </div>
          } />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <ControlledTableContainerV8
                    columns={columns}
                    odataFetch={intzSvc.searchTransaction}
                    initialSortBy={"id"}
                    fetchCondition={(status && integrationConfigurationId) ? ("status eq '"+ status + "' and integrationConfigurationId eq " + integrationConfigurationId) : 
                    status ? "status eq '"+ status + "'": integrationConfigurationId ? "integrationConfigurationId eq " + integrationConfigurationId : ""}
                    isGlobalFilter={true}
                    customPageSize={10}
                    rerender={rerender}
                    className="custom-header-css"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <IntegrationTransactionModal
                show={modal}
                id={entityData?.id}
                onClose={handleModalClose}
      />
    </React.Fragment>
  );
};

export default IntegrationTransactionList;