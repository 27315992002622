import React, { useEffect } from "react";
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Form
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CLIENT_SYNC_TYPE, TARGET_TYPE } from "services/intzEnum";

const MappingEntryModal = props => {
    const { show, entityData, isEdit, onClose, errorMessage, transformId } = props;
    const [initialValues, setInitialValues] = React.useState({
        id: '',
        sourcePropertyName: '',
        targetPropertyName: '',
        expression: '',
        targetType: TARGET_TYPE.OUTPUT,
        syncType: CLIENT_SYNC_TYPE.CREATED,
    });
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: Yup.object({
            targetPropertyName: Yup.string().required("Please enter target property name")
        }),
        onSubmit: (values, { resetForm }) => {
            onClose({transformId : transformId, syncType : isEdit ? CLIENT_SYNC_TYPE.UPDATED : CLIENT_SYNC_TYPE.CREATED,  ...values});
            resetForm();
        }
    });
    useEffect(() => {
        if(show) {
            if (entityData && Object.keys(entityData).length > 0) {
                setInitialValues({...entityData});
            } else {
                setInitialValues(initialValues)
            }
        }
        
    }, [show]);
    return (
        <Modal isOpen={show}  size="lg">
            <ModalHeader tag="h4">
                {!!isEdit ? "Edit Mapping" : "Add new Mapping"}
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col className="col-12">
                            {isEdit && (
                                <div className="mb-3">
                                    <Label className="form-label">#</Label>
                                    <Input
                                        name="id"
                                        type="text"
                                        readOnly
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.id}
                                        invalid={formik.touched.id && !!formik.errors.id}
                                    />
                                    {formik.touched.id && formik.errors.id && (
                                        <FormFeedback type="invalid">{formik.errors.id}</FormFeedback>
                                    )}
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="mb-3">
                                <Label className="form-label">Source Property</Label>
                                <Input
                                    name="sourcePropertyName"
                                    type="text"
                                    placeholder="Property Name"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.sourcePropertyName}
                                    invalid={formik.touched.sourcePropertyName && !!formik.errors.sourcePropertyName}
                                />
                                {formik.touched.sourcePropertyName && formik.errors.sourcePropertyName && (
                                    <FormFeedback type="invalid">{formik.errors.sourcePropertyName}</FormFeedback>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="9">
                            <div className="mb-3">
                                <Label className="form-label">Target Property</Label>
                                <Input
                                    name="targetPropertyName"
                                    type="text"
                                    placeholder="Property Name"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.targetPropertyName}
                                    invalid={formik.touched.targetPropertyName && !!formik.errors.targetPropertyName}
                                />
                                {formik.touched.targetPropertyName && formik.errors.targetPropertyName && (
                                    <FormFeedback type="invalid">{formik.errors.targetPropertyName}</FormFeedback>
                                )}
                            </div>
                        </Col>
                        <Col md="3">
                        <div className="mb-3">
                            <Label className="form-label">Target</Label><br/>
                            <Input
                                name="targetType"
                                type="select"
                                className="form-select"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={
                                    formik.values.targetType
                                }
                                invalid={
                                    formik.touched.targetType && formik.errors.targetType ? true : false
                                }
                            > 
                                <option value={TARGET_TYPE.OUTPUT}>Output</option>
                                <option value={TARGET_TYPE.INPUT}>Input</option>
                                <option value={TARGET_TYPE.CONTEXT}>Context</option>
                                <option value={TARGET_TYPE.PARENT}>Parent</option>
                            </Input>
                            {formik.touched.targetType && formik.errors.targetType ? (
                                <FormFeedback type="invalid">{formik.errors.targetType}</FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="mb-3">
                                <Label className="form-label">Expression</Label>
                                <Input
                                    name="expression"
                                    type="textarea"
                                    rows="3"
                                    placeholder="Expression"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.expression}
                                    invalid={formik.touched.expression && !!formik.errors.expression}
                                />
                                {formik.touched.expression && formik.errors.expression && (
                                    <FormFeedback type="invalid">{formik.errors.expression}</FormFeedback>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            <Button type="button" className="btn btn-secondary" onClick={() => onClose()}>Cancel</Button>
                            &nbsp;&nbsp;
                            <Button type="submit" className="btn btn-success save-integration">Save</Button>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    );
}

export default MappingEntryModal;
