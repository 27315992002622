import React, { useEffect } from "react"
import { CLIENT_SYNC_TYPE } from "services/intzEnum";

const DummyDeleteModal = props => {
    const { show, entityData, onClose } = props;

    useEffect(() => {
        if (show) {
            entityData.syncType = CLIENT_SYNC_TYPE.DELETED;
            onClose(entityData);
        }
    }, [show]);
    return (<></>);
    
}

export default DummyDeleteModal;
