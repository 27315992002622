import React, { useEffect, useState, useMemo, useContext } from "react";
import Moment from 'react-moment';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
  FormFeedback,
  Label,
  Form,
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import { IntzServiceContext } from "services/intzService";
import ControlledTableContainerV8 from "components/Common/ControlledTableContainerV8";
import { getMethodTypeCssClasses } from './AppActionUIHelper';
import AppActionModal from "./AppActionModal";

// Column
const Id = (cell) => {
  return cell.getValue() ? "#AA" + cell.getValue() : '';
};
const Description = (cell) => {
    return cell.getValue() ? cell.getValue() : '';
};
const MethodType = (cell) => {
  return cell.getValue() ? <span className={getMethodTypeCssClasses(cell.getValue())}>{cell.getValue()}</span> : '';
};

const Route = (cell) => {
  return cell.getValue() ? cell.getValue() : '';
};

const CreatedOn = (cell) => {
    return cell.getValue() ? <Moment format='ll'>{cell.getValue()}</Moment>: '';
};

const AppActionList = props => {

  //meta title
  document.title = "App Action | Integratiz";
  const { intzSvc } = useContext(IntzServiceContext);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  
  const handleEntityClick = arg => {

  };

  // Customber Column
  const columns = useMemo(
    () => [

      {
        header: 'Id',
        accessorKey: 'id',
        filterable: false,
        enableSorting: true,
        cell: (cellProps) => {
          return <Id {...cellProps} />;
        }
      },
      {
        header: 'Description',
        accessorKey: 'description',
        enableSorting: true,
        filterable: false,
        cell: (cellProps) => {
          return <Description {...cellProps} />;
          ;
        }
      },
      {
        header: 'Method',
        accessorKey: 'methodType',
        enableSorting: false,
        filterable: false,
        cell: (cellProps) => {
          return <MethodType {...cellProps} />;
        }
      },
      {
        header: 'Route',
        accessorKey: 'route',
        enableSorting: false,
        filterable: false,
        cell: (cellProps) => {
          return <Route {...cellProps} />;
        }
      },
      {
        header: 'Created On',
        accessorKey: 'createdOn',
        enableSorting: true,
        cell: (cellProps) => {
          return <CreatedOn {...cellProps} />;
        }
      },
      {
        header: 'Action',
        enableSorting: false,
        cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" href="#" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem href="#"
                  onClick={() => {
                    const entityData = cellProps.row.original;
                    onEditClick(entityData);
                  }
                  }
                >
                  <i className="mdi mdi-pencil font-size-16 text-success me-1" id="edittooltip"></i>
                  Edit
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </DropdownItem>

                <DropdownItem href="#"
                  onClick={() => {
                    const entityData = cellProps.row.original;
                    onDeleteClick(entityData);
                  }}>
                  <i className="mdi mdi-trash-can font-size-16 text-danger me-1" id="deletetooltip"></i>
                  Delete
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        }
      },
    ],
    []
  );
  //delete 
  const [deleteModal, setDeleteModal] = useState(false);
  const [entityData, setEntityData] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  const [appEndpointId, setAppEndpointId] = useState();
  const [rerender, setRerender] = useState(0);
  const [appEndpointList, setAppEndpointList] = useState([]);
  const [appId, setAppId] = useState();
  const [appList, setAppList] = useState([]);
  const onDeleteClick = (entityData) => {
    setEntityData(entityData);
    setErrorMessage("");
    setDeleteModal(true);
  };

  const handleDeleteEntity = () => {
    if (entityData && entityData.id) {
      intzSvc.deleteAppAction(entityData.id).then(({data}) => {
        setDeleteModal(false);
        setRerender(rerender + 1);
      }).catch(err => {
        setErrorMessage(err.response.data);
      });
    }
  };

  const onEditClick = (entityData) => {
    setEntityData(entityData);
    setErrorMessage("");
    setIsEdit(true);
    setModal(true);
  };
  const onAddClick = () => {
    setEntityData({});
    setErrorMessage("");
    setIsEdit(false);
    setModal(true);
  };
  const handleModalClose = (isUpdated) => {
      if(isUpdated) {
        setRerender(rerender + 1);
      }
      setModal(false);
    
  };
  useEffect(() => {
    intzSvc.searchAppEndpoint(100,0,'createdOn','desc').then(({data}) => {
      setAppEndpointList(data.value);
    }).finally(() => {
    });
    intzSvc.searchApp(100,0,'createdOn','desc').then(({data}) => {
      setAppList(data.value);
    }).finally(() => {
    });
  }, []);

  useEffect(() => {
    intzSvc.searchAppEndpoint(100,0,'createdOn','desc',appId ? 'appId eq ' + appId : '').then(({data}) => {
      setAppEndpointList(data.value);
    }).finally(() => {
    });
  }, [appId]);


  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEntity}
        onCloseClick={() => setDeleteModal(false)}
        entityName={"action"}
        errorMessage={errorMessage}
      />
      <div className="page-content">
        <Container fluid>
        <Breadcrumbs title="App Action" breadcrumbItem="List" 
          altTitle={"LIST : "} button={
            <div style={{"display":"flex"}}>
              <select
          className="form-select form-select-sm mt-1 me-2"
          value={appId}
          onChange={(e) => {
            setAppId(e.target.value);
            setAppEndpointId("");
            setRerender(rerender +  1);
          }}>
          <option key={''} value={''}>
          All Apps
            </option>
          {appList.map(app => (
            <option key={app.id} value={app.id}>
              {app.description}
            </option>
          ))}
        </select>
        <select
            className="form-select form-select-sm mt-1"
            value={appEndpointId}
            onChange={(e) => {
              setAppEndpointId(e.target.value);
              setRerender(rerender +  1);
            }}>
            <option key={''} value={''}>
            All Endpoints
              </option>
            {appEndpointList.map(appEndpoint => (
              <option key={appEndpoint.id} value={appEndpoint.id}>
                {appEndpoint.description}
              </option>
            ))}
          </select>
          </div>
          } />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <ControlledTableContainerV8
                    columns={columns}
                    odataFetch={intzSvc.searchAppAction}
                    initialSortBy={"createdOn"}
                    isGlobalFilter={true}
                    fetchCondition={(appId && appEndpointId) ? ("endpoint/appId eq "+ appId + " and endpointId eq " + appEndpointId) : 
                    appId ? "endpoint/appId eq "+ appId : appEndpointId ? "endpointId eq " + appEndpointId : ""}
                    addButtonLabel={"Action"}
                    onAddClick={onAddClick}
                    customPageSize={10}
                    rerender={rerender}
                    className="custom-header-css"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <AppActionModal
                show={modal}
                isEdit={isEdit}
                id={entityData?.id}
                onClose={handleModalClose}
                errorMessage={errorMessage}
      />
    </React.Fragment>
  );
};

export default AppActionList;
