import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Form
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup";
import { IntzServiceContext } from "services/intzService";
import ControlledTypehead from "components/Common/ControlledTypehead";
import { AUTH_TYPE, CLIENT_SYNC_TYPE, METHOD_TYPE, NODE_TYPE } from "services/intzEnum";
import { getMethodTypeCssClasses } from '../../AppAction/AppActionUIHelper';


const IteratorNodeModal = props => {
    const { show, isEdit, data, onCloseClick } = props;
    const { intzSvc } = useContext(IntzServiceContext);

    const [isLoading, setIsLoading] = useState(false);
    const [initialValues, setInitialValues] = React.useState({
        id: '',
        description:  '',
        type: NODE_TYPE.ITERATOR,
        sideNodeId: '',
        nextNodeId: '',
        exceptionNodeId:'',
        iteratorId:'',
        iterator: {inputArrayName:'', outputArrayName:'',isBreakWhenException:false, isAggregateResult:false},
        isLogging : false
    });
    const formik  = useFormik({
    enableReinitialize: true,

    initialValues: initialValues,
    validationSchema: Yup.object({
        description: Yup.string().required("Please enter description"),
        iterator: Yup.object({
            inputArrayName: Yup.string().required("Please enter input array name"),
            outputArrayName: Yup.string().required("Please enter output array name"),
        })
    }),

    onSubmit: (values) => {
        onCloseClick(values);
    },
    });

    useEffect(() => {
        setInitialValues({...data});
    }, [show]);

    return (
    <Modal isOpen={show} size="lg">
        <ModalHeader tag="h4">
        {!!isEdit ? "Edit Iterator Node" : "Add new Iterator Node"}
        </ModalHeader>
        <ModalBody>
        <Form
            onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
            }}
            
        >
            <Row>
            {isEdit && <Col md="6">
                    <div className="mb-3">
                <Label className="form-label">Id</Label>
                <Input
                    name="id"
                    type="text"
                    readOnly
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.id ? ("#ND"+formik.values.id) : ""}
                    invalid={
                    formik.touched.id && formik.errors.id ? true : false
                    }
                    disabled={isLoading}
                />
                {formik.touched.id && formik.errors.id ? (
                    <FormFeedback type="invalid">{formik.errors.id}</FormFeedback>
                ) : null}
                </div></Col>}
                </Row>
                <Row>
            <Col md="12">
                <div className="mb-3">
                <Label className="form-label">Description</Label>
                <Input
                    name="description"
                    type="textarea"
                    placeholder="Node description"
                    rows="3"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description || ""}
                    invalid={
                    formik.touched.description && formik.errors.description ? true : false
                    }
                    disabled={isLoading}
                />
                {formik.touched.description && formik.errors.description ? (
                    <FormFeedback type="invalid">{formik.errors.description}</FormFeedback>
                ) : null}
                </div>

            </Col>
            </Row>
                <Row>
                <Col md="6">
                    <div className="mb-3">
                <Label className="form-label">Input Array</Label>
                <Input
                    name="iterator.inputArrayName"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.iterator?.inputArrayName}
                    invalid={
                    formik.touched.iterator?.inputArrayName && formik.errors.iterator?.inputArrayName ? true : false
                    }
                    disabled={isLoading}
                />
                {formik.touched.iterator?.inputArrayName && formik.errors.iterator?.inputArrayName ? (
                    <FormFeedback type="invalid">{formik.errors.iterator?.inputArrayName}</FormFeedback>
                ) : null}
                </div></Col>
                <Col md="6">
                    <div className="mb-3">
                <Label className="form-label">Output Array</Label>
                <Input
                    name="iterator.outputArrayName"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.iterator?.outputArrayName}
                    invalid={
                    formik.touched.iterator?.outputArrayName && formik.errors.iterator?.outputArrayName ? true : false
                    }
                    disabled={isLoading}
                />
                {formik.touched.iterator?.outputArrayName && formik.errors.iterator?.outputArrayName ? (
                    <FormFeedback type="invalid">{formik.errors.iterator?.outputArrayName}</FormFeedback>
                ) : null}
                </div></Col>
                </Row>
                <Row></Row>
                <Row>
                
            <Col md="6">
            <div className="mb-3">
                <Label className="form-label">Break when exception</Label><br/>
                <div className="form-check form-switch mb-3" >
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="iterator.isBreakWhenException"
                            checked={formik.values.iterator?.isBreakWhenException ?? false}
                            onClick={e => {
                                formik.setFieldValue('iterator.isBreakWhenException', !formik.values?.iterator.isBreakWhenException);
                            }}
                            onChange={e => {
                            }}
                          />
                        </div>
                </div>
            </Col>
            <Col md="6">
            <div className="mb-3">
                <Label className="form-label">Aggregate Result</Label><br/>
                <div className="form-check form-switch mb-3" >
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="iterator.isBreakWhenException"
                            checked={formik.values.iterator?.isAggregateResult ?? false}
                            onClick={e => {
                                formik.setFieldValue('iterator.isAggregateResult', !formik.values?.iterator.isAggregateResult);
                            }}
                            onChange={e => {
                            }}
                          />
                        </div>
                </div>
            </Col>
            <Col md="6">
            <div className="mb-3">
                <Label className="form-label">Log Enabled</Label><br/>
                <div className="form-check form-switch mb-3" >
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="isLogging"
                            checked={formik.values.isLogging ?? false}
                            onClick={e => {
                                formik.setFieldValue('isLogging', !formik.values.isLogging);
                            }}
                            onChange={e => {
                            }}
                          />
                        </div>
                </div>
            </Col>
            </Row>
            <Row>
            <Col><hr /></Col>
            <Col style={{ flex: "0 0 80px" }}> Relationships </Col>
            <Col><hr /></Col>
            </Row>
            <Row>
            <Col md="6">
            <div className="mb-3">
                <Label className="form-label">Node to iterate</Label>
                <ControlledTypehead
                odataFetch = {intzSvc.searchNode}
                value={formik.values.sideNodeId}
                filterColumn = "description"
                fetchCondition = {'integrationId eq ' + formik.values.integrationId}
                labelKey= {(option) => `${option["description"]}`}
                valueSelected = {(id) => {
                    formik.setFieldValue('sideNodeId',id);
                }}
                invalid={
                    formik.errors.sideNodeId ? true : false
                }
                />
                {formik.errors.sideNodeId ? (
                    <div className="text-danger"><small>{formik.errors.sideNodeId}</small></div>
                ) : null}
                </div>
            </Col>
            
            </Row>  
            <Row>
            <Col md="6">
            <div className="mb-3">
                <Label className="form-label">Next Node</Label>
                <ControlledTypehead
                odataFetch = {intzSvc.searchNode}
                value={formik.values.nextNodeId}
                filterColumn = "description"
                fetchCondition = {'integrationId eq ' + formik.values.integrationId}
                labelKey= {(option) => `${option["description"]}`}
                valueSelected = {(id) => {
                    formik.setFieldValue('nextNodeId',id);
                }}
                invalid={
                    formik.errors.nextNodeId ? true : false
                }
                />
                {formik.errors.nextNodeId ? (
                    <div className="text-danger"><small>{formik.errors.nextNodeId}</small></div>
                ) : null}
                </div>
            </Col>
            <Col md="6">
            <div className="mb-3">
                <Label className="form-label">Exception Node</Label>
                <ControlledTypehead
                odataFetch = {intzSvc.searchNode}
                value={formik.values.exceptionNodeId}
                enableNone = {true}
                filterColumn = "description"
                fetchCondition = {'integrationId eq ' + formik.values.integrationId}
                labelKey= {(option) => `${option["description"]}`}
                valueSelected = {(id) => {
                    formik.setFieldValue('exceptionNodeId',id);
                }}
                invalid={
                    formik.errors.exceptionNodeId ? true : false
                }
                />
                {formik.errors.exceptionNodeId ? (
                    <div className="text-danger"><small>{formik.errors.exceptionNodeId}</small></div>
                ) : null}
                </div>
            </Col>
            </Row>    
            
            <Row>
            <Col md="12">
                <button type="button" className="btn btn-danger" style={{float:"left"}} onClick={() => onCloseClick({...formik.values,syncType:CLIENT_SYNC_TYPE.DELETED})} disabled={isLoading}>Delete</button>
                <div className="text-end">
                <button type="button" className="btn btn-secondary" onClick={() => onCloseClick()} disabled={isLoading}>Cancel</button>
                &nbsp;&nbsp;
                <button
                    type="submit"
                    className="btn btn-success save-integration"
                    disabled={isLoading || !formik.isValid}

                >
                    Save
                </button>
                
                </div>
            </Col>
            </Row>
        </Form>
        
        </ModalBody>
    </Modal>
    )
}

export default IteratorNodeModal