import { METHOD_TYPE } from "services/intzEnum";

  
  export function getMethodTypeCssClasses(status) {
    var type;
    switch(status) {
      case METHOD_TYPE.GET:
      type="info"
      break;
      case METHOD_TYPE.POST:
      type="success"
      break;
      case METHOD_TYPE.PUT:
      type="warning"
      break;
      case METHOD_TYPE.PATCH:
      type="danger"
      break;
      case METHOD_TYPE.DELETE:
      type="secondary"
      break;
      default:
        type=""
    }

    return `badge badge-soft-${
      type
    }`;
  }