import React, { useEffect, useState, useMemo, useContext } from "react";
import Moment from 'react-moment';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
  FormFeedback,
  Label,
  Form,
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import { IntzServiceContext } from "services/intzService";
import ControlledTableContainerV8 from "components/Common/ControlledTableContainerV8";
import { getLogTypeCssClasses } from './IntegrationLogUIHelper';
import IntegrationLogModal from "./IntegrationLogModal";

// Column
const Id = (cell) => {
  return cell.getValue() ? "#TL" + cell.getValue() : '';
};
const Configuration = (cell) => {
    return cell.getValue() ? cell.getValue() : '';
};
const NodeId = (cell) => {
  return cell.getValue() ? "#ND" + cell.getValue() : '';
};
const TransactionKey = (cell) => {
  return cell.getValue() ? cell.getValue() : '';
};
const LogType = (cell) => {
  return cell.getValue() ? <span className={getLogTypeCssClasses(cell.getValue())}>{cell.getValue()}</span> : '';
};
const LoggedOn = (cell) => {
    return cell.getValue() ? <Moment format='lll'>{cell.getValue()}</Moment>: '';
};

const IntegrationLogList = props => {

  //meta title
  document.title = "Logs | Integratiz";
  const { intzSvc } = useContext(IntzServiceContext);
  const [modal, setModal] = useState(false);
  const [integrationConfigurationId , setIntegrationConfigurationId] = useState();
  const [integrationConfigurationList, setIntegrationConfigurationList] = useState([]);

  const handleEntityClick = arg => {

  };

  // Customber Column
  const columns = useMemo(
    () => [

      {
        header: 'Id',
        accessorKey: 'id',
        filterable: false,
        enableSorting: true,
        cell: (cellProps) => {
          return <Id {...cellProps} />;
        }
      },
      {
        header: 'Configuration',
        accessorKey: 'integrationConfiguration.name',
        enableSorting: false,
        filterable: false,
        cell: (cellProps) => {
          return <Configuration {...cellProps} />;
          ;
        }
      },
      {
        header: 'Node#',
        accessorKey: 'nodeId',
        enableSorting: false,
        filterable: false,
        cell: (cellProps) => {
          return <NodeId {...cellProps} />;
        }
      },
      {
        header: 'Transaction Key',
        accessorKey: 'transactionKey',
        enableSorting: true,
        filterable: false,
        cell: (cellProps) => {
          return <TransactionKey {...cellProps} />;
        }
      },
      {
        header: 'Type',
        accessorKey: 'type',
        enableSorting: true,
        filterable: false,
        cell: (cellProps) => {
          return <LogType {...cellProps} />;
        }
      },
      {
        header: 'Logged On',
        accessorKey: 'loggedOn',
        enableSorting: true,
        cell: (cellProps) => {
          return <LoggedOn {...cellProps} />;
        }
      },
      {
        header: 'Action',
        enableSorting: false,
        cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" href="#" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem href="#"
                  onClick={() => {
                    const entityData = cellProps.row.original;
                    onDetailsClick(entityData);
                  }
                  }
                >
                  <i className="mdi mdi-clipboard-text-outline font-size-16 text-success me-1" id="edittooltip"></i>
                  Details
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Details
                  </UncontrolledTooltip>
                </DropdownItem>

              </DropdownMenu>
            </UncontrolledDropdown>
          );
        }
      },
    ],
    []
  );
  //delete 
  const [deleteModal, setDeleteModal] = useState(false);
  const [entityData, setEntityData] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  const [rerender, setRerender] = useState(0);

  const onDetailsClick = (entityData) => {
    setEntityData(entityData);
    setModal(true);
  };
  const handleModalClose = () => {
      setModal(false);
  };

  const onDeleteClick = (entityData) => {
    setEntityData(entityData);
    setDeleteModal(true);
  };

  const handleDeleteEntity = () => {
    if (entityData && entityData.id) {
      // intzSvc.deleteConfiguration(configuration.id);
      setDeleteModal(false);
    }
  };

  const handleAddEntity = () => {
    // navigate to add integration
  };

  useEffect(() => {
    intzSvc.searchIntegrationConfiguration(100,0,'createdOn','desc').then(({data}) => {
      setIntegrationConfigurationList(data.value);
    }).finally(() => {
    });
  }, []);

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEntity}
        errorMessage={errorMessage}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
        <Breadcrumbs title="Log" breadcrumbItem="List" 
          altTitle={"LIST : "} button={
            
        <select
            className="form-select form-select-sm mt-1"
            value={integrationConfigurationId}
            onChange={(e) => {
              setIntegrationConfigurationId(e.target.value);
              setRerender(rerender +  1);
            }}>
            <option key={''} value={''}>
            All Configurations
              </option>
            {integrationConfigurationList.map(configuration => (
              <option key={configuration.id} value={configuration.id}>
                {configuration.description}
              </option>
            ))}
          </select>
          } />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <ControlledTableContainerV8
                    columns={columns}
                    odataFetch={intzSvc.searchIntegrationLog}
                    initialSortBy={"id"}
                    isGlobalFilter={true}
                    fetchCondition={integrationConfigurationId ? "integrationConfigurationId eq " + integrationConfigurationId : ""}
                    customPageSize={10}
                    rerender={rerender}
                    className="custom-header-css"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <IntegrationLogModal
                show={modal}
                id={entityData?.id}
                onCloseClick={handleModalClose}
      />
    </React.Fragment>
  );
};

export default IntegrationLogList;