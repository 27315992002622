import { INTEGRATION_TRANSACTION_STATUS } from "services/intzEnum";


  export function getStatusCssClasses(status) {
    var type;
    switch(status) {
      case INTEGRATION_TRANSACTION_STATUS.PROCESSING:
        type="info"
        break;
        case INTEGRATION_TRANSACTION_STATUS.FAILED:
        type="danger"
        break;
        case INTEGRATION_TRANSACTION_STATUS.SUCCESS:
        type="success"
        break;
      case INTEGRATION_TRANSACTION_STATUS.SUCCESS_WITH_WARNING:
        type="warning"
        break;
          
      default:
        type=""
    }

    return `badge badge-soft-${
      type
    }`;
  }