import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Form
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup";
import { IntzServiceContext } from "services/intzService";
import ControlledTypehead from "components/Common/ControlledTypehead";
import { AUTH_TYPE, CLIENT_SYNC_TYPE, ELSE_BEHAVIOR_TYPE, METHOD_TYPE, NODE_TYPE } from "services/intzEnum";
import { getMethodTypeCssClasses } from '../../AppAction/AppActionUIHelper';


const ConditionNodeModal = props => {
    const { show, isEdit, data, onCloseClick } = props;
    const { intzSvc } = useContext(IntzServiceContext);

    const [isLoading, setIsLoading] = useState(false);
    const [initialValues, setInitialValues] = React.useState({
        id: '',
        description:  '',
        type: NODE_TYPE.CONDITION,
        sideNodeId: '',
        nextNodeId: '',
        exceptionNodeId:'',
        conditionId:'',
        condition: {expression:'', elseBehaviorType:ELSE_BEHAVIOR_TYPE.BREAK},
        isLogging : false
    });
    const formik  = useFormik({
    enableReinitialize: true,

    initialValues: initialValues,
    validationSchema: Yup.object({
        description: Yup.string().required("Please enter description"),
        condition: Yup.object({
            expression: Yup.string().required("Please enter expression")
        })
    }),

    onSubmit: (values) => {
        onCloseClick(values);
    },
    });

    useEffect(() => {
        setInitialValues({...data});
    }, [show]);

    return (
    <Modal isOpen={show} size="lg">
        <ModalHeader tag="h4">
        {!!isEdit ? "Edit Condition Node" : "Add new Condition Node"}
        </ModalHeader>
        <ModalBody>
        <Form
            onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
            }}
            
        >
            <Row>
            {isEdit && <Col md="6">
                    <div className="mb-3">
                <Label className="form-label">Id</Label>
                <Input
                    name="id"
                    type="text"
                    readOnly
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.id ? ("#CN"+formik.values.id) : ""}
                    invalid={
                    formik.touched.id && formik.errors.id ? true : false
                    }
                    disabled={isLoading}
                />
                {formik.touched.id && formik.errors.id ? (
                    <FormFeedback type="invalid">{formik.errors.id}</FormFeedback>
                ) : null}
                </div></Col>}
                </Row>
                <Row>
            <Col md="12">
                <div className="mb-3">
                <Label className="form-label">Description</Label>
                <Input
                    name="description"
                    type="text"
                    placeholder="Node description"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description || ""}
                    invalid={
                    formik.touched.description && formik.errors.description ? true : false
                    }
                    disabled={isLoading}
                />
                {formik.touched.description && formik.errors.description ? (
                    <FormFeedback type="invalid">{formik.errors.description}</FormFeedback>
                ) : null}
                </div>

            </Col>
            </Row>
            <Row>
            <Col md="12">
                <div className="mb-3">
                <Label className="form-label">Expression</Label>
                <Input
                    name="condition.expression"
                    type="textarea"
                    placeholder="Condition expression"
                    rows="3"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.condition?.expression || ""}
                    invalid={
                    formik.touched.condition?.expression && formik.errors.condition?.expression ? true : false
                    }
                    disabled={isLoading}
                />
                {formik.touched.condition?.expression && formik.errors.condition?.expression ? (
                    <FormFeedback type="invalid">{formik.errors.condition?.expression}</FormFeedback>
                ) : null}
                </div>

            </Col>
            </Row>
            <Row>
            <Col md="6">
            <div className="mb-3">
                <Label className="form-label">Else Behavior</Label>
                      <Input
                        name="condition.elseBehaviorType"
                        type="select"
                        className="form-select"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={
                            formik.values.condition?.elseBehaviorType || ""
                        }
                        invalid={
                            formik.touched.condition?.elseBehaviorType && formik.errors.condition?.elseBehaviorType ? true : false
                        }
                      > 
                        <option value={ELSE_BEHAVIOR_TYPE.BREAK}>BREAK</option>
                        <option value={ELSE_BEHAVIOR_TYPE.EXCEPTION}>EXCEPTION</option>
                        <option value={ELSE_BEHAVIOR_TYPE.ELSENODE}>ELSENODE</option>
                      </Input>
                      {formik.touched.condition?.elseBehaviorType && formik.errors.condition?.elseBehaviorType ? (
                        <FormFeedback type="invalid">{formik.errors.condition?.elseBehaviorType}</FormFeedback>
                      ) : null}
                </div>
            </Col>
            </Row>
            <Row>
            <Col md="6">
            <div className="mb-3">
                <Label className="form-label">Log Enabled</Label><br/>
                <div className="form-check form-switch mb-3" >
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="isLogging"
                            checked={formik.values.isLogging ?? false}
                            onClick={e => {
                                formik.setFieldValue('isLogging', !formik.values.isLogging);
                            }}
                            onChange={e => {
                            }}
                          />
                        </div>
                </div>
            </Col>
            </Row>
            <Row>
            <Col><hr /></Col>
            <Col style={{ flex: "0 0 80px" }}> Relationships </Col>
            <Col><hr /></Col>
            </Row>
            {formik.values.condition?.elseBehaviorType == ELSE_BEHAVIOR_TYPE.ELSENODE && <Row>
            <Col md="6">
            <div className="mb-3">
                <Label className="form-label">Else Node</Label>
                <ControlledTypehead
                odataFetch = {intzSvc.searchNode}
                value={formik.values.sideNodeId}
                filterColumn = "description"
                fetchCondition = {'integrationId eq ' + formik.values.integrationId}
                labelKey= {(option) => `${option["description"]}`}
                valueSelected = {(id) => {
                    formik.setFieldValue('sideNodeId',id);
                }}
                invalid={
                    formik.errors.sideNodeId ? true : false
                }
                />
                {formik.errors.sideNodeId ? (
                    <div className="text-danger"><small>{formik.errors.sideNodeId}</small></div>
                ) : null}
                </div>
            </Col>
            
            </Row>}
            <Row>
            <Col md="6">
            <div className="mb-3">
                <Label className="form-label">Next Node</Label>
                <ControlledTypehead
                odataFetch = {intzSvc.searchNode}
                value={formik.values.nextNodeId}
                filterColumn = "description"
                fetchCondition = {'integrationId eq ' + formik.values.integrationId}
                labelKey= {(option) => `${option["description"]}`}
                valueSelected = {(id) => {
                    formik.setFieldValue('nextNodeId',id);
                }}
                invalid={
                    formik.errors.nextNodeId ? true : false
                }
                />
                {formik.errors.nextNodeId ? (
                    <div className="text-danger"><small>{formik.errors.nextNodeId}</small></div>
                ) : null}
                </div>
            </Col>
            <Col md="6">
            <div className="mb-3">
                <Label className="form-label">Exception Node</Label>
                <ControlledTypehead
                odataFetch = {intzSvc.searchNode}
                value={formik.values.exceptionNodeId}
                enableNone = {true}
                filterColumn = "description"
                fetchCondition = {'integrationId eq ' + formik.values.integrationId}
                labelKey= {(option) => `${option["description"]}`}
                valueSelected = {(id) => {
                    formik.setFieldValue('exceptionNodeId',id);
                }}
                invalid={
                    formik.errors.exceptionNodeId ? true : false
                }
                />
                {formik.errors.exceptionNodeId ? (
                    <div className="text-danger"><small>{formik.errors.exceptionNodeId}</small></div>
                ) : null}
                </div>
            </Col>
            </Row>
            
            <Row>
            <Col md="12">
                <button type="button" className="btn btn-danger" style={{float:"left"}} onClick={() => onCloseClick({...formik.values,syncType:CLIENT_SYNC_TYPE.DELETED})} disabled={isLoading}>Delete</button>
                <div className="text-end">
                <button type="button" className="btn btn-secondary" onClick={() => onCloseClick()} disabled={isLoading}>Cancel</button>
                &nbsp;&nbsp;
                <button
                    type="submit"
                    className="btn btn-success save-integration"
                    disabled={isLoading || !formik.isValid}

                >
                    Save
                </button>
                
                </div>
            </Col>
            </Row>
        </Form>
        
        </ModalBody>
    </Modal>
    )
}

export default ConditionNodeModal