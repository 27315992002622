import { CLIENT_SYNC_TYPE } from "services/intzEnum";
import {
    UPDATE_NODE,SET_INTEGRATION, UPDATE_TRANSACTION
} from "./actionTypes";

const INIT_STATE = {
    data:{}
};

const Integration = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_INTEGRATION:
            return {
                ...state,
                data:action.payload
            };
        case UPDATE_NODE:
            const newNode = {...action.payload, syncType: action.payload.syncType == CLIENT_SYNC_TYPE.DELETED ? CLIENT_SYNC_TYPE.DELETED : CLIENT_SYNC_TYPE.UPDATED};

            const newNodeData = state.data?.nodes.map(node => 
              node.id === newNode.id ? newNode : node
            );
          
            return {...state, data: {...state.data,nodes:newNodeData}};
        case UPDATE_TRANSACTION:
            const newTc = {...action.payload, syncType: action.payload.syncType == CLIENT_SYNC_TYPE.DELETED ? CLIENT_SYNC_TYPE.DELETED : CLIENT_SYNC_TYPE.UPDATED};

            const newTcData = state.data?.transactionConfigurations.map(tc => 
                tc.id === newTc.id ? newTc : tc
            );
            
            return {...state, data: {...state.data,transactionConfigurations:newTcData}};
        default:
            return state;
    }
}


export default Integration;