import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Handle, Position } from 'reactflow';
import { Button, Col, Row } from "reactstrap";
import { CLIENT_SYNC_TYPE, ELSE_BEHAVIOR_TYPE } from "services/intzEnum";
import { updateTransaction } from "store/integration/actions";
import { getBehaviorCssClasses } from "../IntegrationUIHelper";
import TransactionConfigurationModal from "./TransactionConfigurationModal";

const handleStyle = { left: 10 };

function TransactionConfigurationGroupNode({ data }) {

  const dispatch = useDispatch();
  const [editModal, setEditModal] = useState(false);
  const [entityData, setEntityData] = useState({})


  const onChange = useCallback((evt) => {
    console.log(evt.target.value);
  }, []);
  const showEditModal = () => {
    
    setEntityData({...data});
    setEditModal(true);
  }

  const handleEditModalClose = (editedTransaction) => {
    if(editedTransaction) {
      dispatch(updateTransaction(editedTransaction));
    }
    setEditModal(false);
    setEntityData({});
  }
  return (
    <div className={data.syncType == CLIENT_SYNC_TYPE.DELETED ? "intz-node intz-group-node intz-node-deleted" : "intz-node intz-group-node"}>
      <Row>
        <Col><span className='badge badge-soft-primary'>Transaction </span>  <b>{data?.syncType == CLIENT_SYNC_TYPE.UPDATED && "*"}<span className='badge badge-soft-primary'>{data?.syncType == CLIENT_SYNC_TYPE.CREATED && "NEW" }</span></b>
        <i className="bx bx-cog" style={{float:"right", marginLeft:"20px"}} onClick={showEditModal}></i></Col>
      </Row>
      <Row>
        <Col md="2" style={{minWidth: "150px",maxWidth:"200px"}}>Key Property</Col>
        <Col>{data?.keyPropertyName || '-'}</Col>
      </Row>
      <Row>
        <Col md="2" style={{minWidth: "150px",maxWidth:"200px"}}>Max Retry</Col>
        <Col>{data?.maxRetry}</Col>
      </Row>
      <Row>
        <Col md="2" style={{minWidth: "150px",maxWidth:"200px"}}>Result Property</Col>
        <Col>{data?.resultPropertyName || '-'}</Col>
      </Row>
      <Row>
        <Col md="2" style={{minWidth: "150px",maxWidth:"200px"}}>Additional Property</Col>
        <Col>{data?.additionalProperties || '-'}</Col>
      </Row>
      <TransactionConfigurationModal
                show={editModal}
                data={entityData}
                isEdit={true}
                onCloseClick={handleEditModalClose}/>
    </div>
  );
}

export default TransactionConfigurationGroupNode;