import React, { Component, useEffect, useState } from 'react';
import { Container } from "reactstrap";
import Iframe from 'react-iframe'
import Cookies from 'js-cookie';

    const HangfireDashboard = () => {
      const url = process.env.REACT_APP_INTZ_HANGFIRE_URL;
        useEffect(() => {
          
          var user = localStorage.getItem("authUser");
          var token;
          if (user) { user = JSON.parse(user); 
            if (user.stsTokenManager.accessToken) {
                token = user.stsTokenManager.accessToken;//`Bearer ${user.stsTokenManager.accessToken}`;
            }
          }
          Cookies.set('HangfireToken', token, { expires: 7, sameSite: 'None', secure: true }); 
        }, []);
      
        return (
          <>
          <Iframe
          url={url}
          width="100%"
          height="1024px"
          id=""
          className=""
          styles={{paddingTop:"70px"}}
          display="block"
          position="relative"/></>
        );
    }

export default HangfireDashboard;