import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import moment from 'moment'
import {
    Button,
    Col,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table,
    Form
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup";
import { IntzServiceContext } from "services/intzService";
import ControlledTypehead from "components/Common/ControlledTypehead";
import { INTEGRATION_TRANSACTION_STATUS } from "services/intzEnum";
import { getLogTypeCssClasses } from "./IntegrationLogUIHelper";
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';
import './IntegrationLog.css';

const IntegrationLogModal = props => {
    // readonly modal 
    const { show, id, onCloseClick } = props;
    const { intzSvc } = useContext(IntzServiceContext);
    const [isLoading, setIsLoading] = useState(false);
    const [entityData, setEntityData] = useState({});


    useEffect(() => {
        if (show) {
            if (id) {
                setIsLoading(true);
                intzSvc.getIntegrationLog(id).then(({ data }) => {
                    setEntityData(data);
                    setIsLoading(false);
                });
            }
        } else {
            setEntityData({});
        }
    }, [show]);

    return (
        <Modal isOpen={show} size="lg">
            <ModalHeader className='mb-2'tag="h4">
                {"Log Details"} {entityData.type ? <span className={getLogTypeCssClasses(entityData.type)} style={{ marginLeft: "10px", fontSize: "0.7rem" }}>{entityData.type}</span> : ''}
            </ModalHeader>
            <ModalBody className='p-0'>
                <div className="intz-modal-box">
                    <div className='ps-4 pe-4'>
                    <Row>
                        <Col md="6">
                            <div className="mb-3">
                                <Label className="form-label">Id</Label>
                                <Input
                                    name="id"
                                    type="text"
                                    readOnly
                                    value={entityData.id ? ("#IL" + entityData.id) : ""}
                                />
                            </div></Col>
                        <Col md="6">
                            <div className="mb-3">
                                <Label className="form-label">Configuration</Label>
                                <ControlledTypehead
                                    odataFetch={intzSvc.searchIntegrationConfiguration}
                                    disabled={true}
                                    value={entityData.integrationConfigurationId}
                                    filterColumn="name"
                                    labelKey={(option) => `${option["name"]}`}
                                    valueSelected={(id) => {

                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <div className="mb-3">
                                <Label className="form-label">Node</Label>
                                <ControlledTypehead
                                    odataFetch={intzSvc.searchNode}
                                    disabled={true}
                                    value={entityData.nodeId}
                                    filterColumn="description"
                                    labelKey={(option) => `${option["description"]}`}
                                    valueSelected={(id) => {

                                    }}
                                />
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <div className="mb-3">
                                <Label className="form-label">Transaction Key</Label>
                                <Input
                                    name="key"
                                    type="text"
                                    readOnly
                                    placeholder="Transaction Key"
                                    value={entityData.transactionKey || ""}
                                />
                            </div>

                        </Col>
                        <Col md="6">
                            <div className="mb-3">
                                <Label className="form-label">Logged On</Label>
                                <Input
                                    name="loggedOn"
                                    type="text"
                                    readOnly
                                    placeholder="Logged On"
                                    value={moment(entityData.loggedOn).format('MMMM Do YYYY, h:mm:ss a')}
                                />
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <div className="mb-3">
                                <Label className="form-label">Url</Label>
                                <Input
                                    name="key"
                                    type="text"
                                    readOnly
                                    placeholder="Url"
                                    value={entityData.url || "None"}
                                />
                            </div>

                        </Col>

                    </Row>
                    <Row>
                        <Col md="12">
                            <div className="mb-3">
                                <Label className="form-label">Context</Label>
                                <JSONPretty id="additionalData" data={entityData.contextVariables}></JSONPretty>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <div className="mb-3">
                                <Label className="form-label">Input Object</Label>
                                <JSONPretty id="additionalData" data={entityData.inboundOrInputObject}></JSONPretty>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <div className="mb-3">
                                <Label className="form-label">Output Object</Label>
                                <JSONPretty id="additionalData" data={entityData.outboundOrOutputObject}></JSONPretty>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <div className="mb-3">
                                <Label className="form-label">ErrorMessage</Label>
                                <pre className="text-wrap __json-pretty__ small">{entityData.errorMessage}</pre>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <div className="mb-3">
                                <Label className="form-label">Stack Trace</Label>
                                <pre className="text-wrap __json-pretty__ small">{entityData.stackTrace}</pre>
                            </div>

                        </Col>
                    </Row>
                    
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
            <Row>
                        <Col md="12">
                            <div className="text-end">
                                <button type="button" className="btn btn-secondary" onClick={() => onCloseClick()} disabled={isLoading}>Close</button>
                            </div>
                        </Col>
                    </Row>
            </ModalFooter>
        </Modal>
    )
}

export default IntegrationLogModal