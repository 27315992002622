import React, { useEffect, useState, useMemo, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import { IntzServiceContext } from "services/intzService";
import ControlledTableContainerV8 from "components/Common/ControlledTableContainerV8";
import { getStatusCssClasses } from './IntegrationUIHelper';
import IntegrationDetailModal from "./IntegrationDetail/IntegrationDetailModal";

// Column
const Id = (cell) => {
  return cell.getValue() ? "#IN" + cell.getValue() : '';
};

const Name = (cell) => {
    return cell.getValue() ? cell.getValue() : '';
};

const Description = (cell) => {
    return cell.getValue() ? cell.getValue() : '';
};

const OwnedBy = (cell) => {
    return cell.getValue() ? (cell.getValue().firstName +  " " + cell.getValue().lastName) : '';
};

const Status = (cell) => {
    return cell.getValue() ? <span className={getStatusCssClasses(cell.getValue())}>{cell.getValue()}</span> : '';
};

const CreatedOn = (cell) => {
    return cell.getValue() ? <Moment format='ll'>{cell.getValue()}</Moment>: '';
};

const IntegrationList = props => {

  //meta title
  document.title = "Integrations | Integratiz";
  const { intzSvc } = useContext(IntzServiceContext);
  let navigate = useNavigate();
  
  const columns = useMemo(
    () => [

      {
        header: 'Id',
        accessorKey: 'id',
        filterable: false,
        enableSorting: true,
        cell: (cellProps) => {
          return <Id {...cellProps} />;
        }
      },
      {
        header: 'Name',
        accessorKey: 'name',
        filterable: false,
        enableSorting: true,
        cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },
      {
        header: 'Description',
        accessorKey: 'description',
        enableSorting: true,
        filterable: false,
        cell: (cellProps) => {
          return <Description {...cellProps} />;
          ;
        }
      },
      {
        header: 'Owner',
        accessorKey: 'ownedBy',
        enableSorting: false,
        filterable: false,
        cell: (cellProps) => {
          return <OwnedBy {...cellProps} />;
        }
      },
      {
        header: 'Status',
        accessorKey: 'status',
        enableSorting: false,
        filterable: false,
        cell: (cellProps) => {
          return <Status {...cellProps} />;
        }
      },
      {
        header: 'Created On',
        accessorKey: 'createdOn',
        enableSorting: true,
        cell: (cellProps) => {
          return <CreatedOn {...cellProps} />;
        }
      },
      {
        header: 'Action',
        enableSorting: false,
        cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" href="#" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem href="#"
                  onClick={() => {
                    const entityData = cellProps.row.original;
                    onEditClick(entityData);
                  }
                  }
                >
                  <i className="mdi mdi-pencil font-size-16 text-success me-1" id="edittooltip"></i>
                  Edit
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </DropdownItem>

                <DropdownItem href="#"
                  onClick={() => {
                    const entityData = cellProps.row.original;
                    onDeleteClick(entityData);
                  }}>
                  <i className="mdi mdi-trash-can font-size-16 text-danger me-1" id="deletetooltip"></i>
                  Delete
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        }
      },
    ],
    []
  );
  //delete 
  const [deleteModal, setDeleteModal] = useState(false);
  const [entityData, setEntityData] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  const [rerender, setRerender] = useState(0);
  const [addModal, setAddModal] = useState(false);
  const onDeleteClick = (entityData) => {
    setEntityData(entityData);
    setErrorMessage("");
    setDeleteModal(true);
  };

  const onEditClick = (entityData) => {
    navigate(`/integration/${entityData.id}`);
  };


  const handleDeleteEntity = () => {
    if (entityData && entityData.id) {
      intzSvc.deleteIntegration(entityData.id).then(({data}) => {
        setDeleteModal(false);
        setRerender(rerender + 1);
      }).catch(err => {
        setErrorMessage(err.response.data);
      });
    }
  };

  const handleAddEntity = () => {
    // navigate to add integration
    setAddModal(true);
  };

  const handleModalClose = (data) => {
    if (data) {
      // new integration
      intzSvc.createIntegration(data).then(({data}) => {
        setRerender(rerender + 1);
      });
      setAddModal(false)
    } else {
      setAddModal(false);
    }
  }

  useEffect(() => {

  }, []);



  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEntity}
        errorMessage={errorMessage}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Integrations" breadcrumbItem="List" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <ControlledTableContainerV8
                    columns={columns}
                    odataFetch={intzSvc.searchIntegration}
                    initialSortBy={"name"}
                    isGlobalFilter={true}
                    addButtonLabel={"Integration"}
                    onAddClick={handleAddEntity}
                    customPageSize={10}
                    rerender={rerender}
                    className="custom-header-css"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <IntegrationDetailModal
      show={addModal}
      isEdit={false}
      onCloseClick={handleModalClose}
/>
    </React.Fragment>
  );
};

export default IntegrationList;