import React, { useEffect, useRef, useState } from 'react';
import { AsyncTypeahead } from "react-bootstrap-typeahead";

function ControlledTypehead({
  odataFetch,
  fetchCondition,
  labelKey,
  filterColumn,  //consider to change this to array
  valueSelected,
  disabled,
  value,
  rerender,
  invalid
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [entityOptions, setEntityOptions] = React.useState([]);
  const [selectedValue, setSelectedValue] = React.useState();
  const typeHeadRef = useRef(null);
  const [
    entitySearchCount,
    setEntitySearchCount,
  ] = React.useState(0);
  const [
    entitySearchString,
    setEntitySearchString,
  ] = React.useState("");
  const PER_PAGE = 5;

  useEffect(() => {
    if (typeHeadRef.current) typeHeadRef.current.setState({text: ''});
    if (value) {
      if (value != selectedValue) {
        setIsLoading(true);
        var filter = 'id eq ' + value;
        odataFetch(PER_PAGE, 0, filterColumn, "asc", filter).then(({ data }) => {
          if (data.value.length > 0) {
            setSelectedValue(data.value[0].id);
            if (typeHeadRef.current) typeHeadRef.current.setState({text: labelKey(data.value[0])});
          }
          setIsLoading(false);
        });
      }
    } 
  }, [value]);

  function handleSearch(search,condition) {
    var fc = condition ? condition : fetchCondition;
    if (!search || search.length > 2) {
      setIsLoading(true);
      setEntitySearchString(search);
      var filter = search ? `contains(${filterColumn}, '${search}')` : "";
      filter = filter ? fc ? '(' + fc + ') and ' + filter : filter : fc;
      odataFetch(PER_PAGE, 0, filterColumn, "asc", filter).then(({ data }) => {
        setEntityOptions(data.value);
        setEntitySearchCount(data["@odata.count"] );
        setIsLoading(false);
      });
    } else {

    }

  }
  function filterCallback(option, props) {
    return true;
  }
  function handlePagination(e, shownResults) {
    if (
      entityOptions.length > shownResults ||
      entityOptions.length === entitySearchCount
    ) {
      return;
    }
    setIsLoading(true);
    var filter = entitySearchString ? `contains(${filterColumn}, '${entitySearchString}')` : "";
    filter = filter ? fetchCondition ? '(' + fetchCondition + ') and ' + filter : filter : fetchCondition;
    odataFetch(PER_PAGE, entityOptions.length, filterColumn, "asc", filter).then(({ data }) => {

      setEntityOptions(entityOptions.concat(data.value));
      setEntitySearchCount(data["@odata.count"] );
      setIsLoading(false);
    });
  }
  function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }
  const onInputChange = React.useCallback(debounce((value,condition) => {
    handleSearch(value,condition);
  }, 200), []); // needed usecallback to prevent rerendering    

  return (<><AsyncTypeahead
    id="typehead"
    onChange={(selected) => {
      const value = selected.length > 0 ? selected[0].id : '';
      valueSelected(value);
      if (rerender) rerender(value);
    }}
    onInputChange={(text, event) => {
      if (!text) {
        onInputChange("",fetchCondition);
        valueSelected("");
      }
    }}
    paginate
    maxResults={PER_PAGE - 1}
    isLoading={isLoading}
    multiple={false}
    allowNew={false}
    options={entityOptions}
    labelKey={labelKey}
    minLength={0}
    //style={{ width: "200px" }}
    onSearch={handleSearch}
    filterBy={filterCallback}
    onPaginate={handlePagination}
    placeholder={"Search..."}
    disabled={disabled}
    paginationText={"Show more..."}
    useCache={false}
    ref = {typeHeadRef}
    isInvalid={invalid}
  /></>);
}

export default ControlledTypehead;