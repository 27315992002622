import { AUTH_TYPE } from "services/intzEnum";

  
  export function getAuthTypeCssClasses(status) {
    var type;
    switch(status) {
      case AUTH_TYPE.API_KEY:
        type="info"
        break;
        case AUTH_TYPE.BEARER_TOKEN:
        type="success"
        break;
        case AUTH_TYPE.BASIC_AUTH:
        type="warning"
        break;
        case AUTH_TYPE.NO_AUTH:
          type="secondary"
          break;
      default:
        type=""
    }

    return `badge badge-soft-${
      type
    }`;
  }