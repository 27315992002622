import { USER_STATUS } from "services/intzEnum";

  
  export function getStatusCssClasses(status) {
    var type;
    switch(status) {
      case USER_STATUS.ACTIVE:
        type="success"
        break;
        case USER_STATUS.INACTIVE:
        type="warning"
        break;
        case USER_STATUS.DELETED:
        type="secondary"
        break;
      default:
        type=""
    }

    return `badge badge-soft-${
      type
    }`;
  }