import React, { Fragment, useEffect } from "react";
import {
    flexRender,
    getCoreRowModel,
    useReactTable,
  } from '@tanstack/react-table'
import { Table, Row, Col, Button, Input, CardBody } from "reactstrap";
import { Filter, DefaultColumnFilter } from "./filters";
import LinearProgress from "./LinearProgress";

// Define a default UI for filtering
function GlobalFilter({
  globalFilter,
  setGlobalFilter
}) {
  const [value, setValue] = React.useState(globalFilter);

  function debounce(func, timeout = 300){
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }

  const onChange = React.useCallback(debounce(value => {
    setGlobalFilter((value && value.length > 2) ? value : undefined);
  }, 200), []); // needed usecallback to prevent rerendering



  return (
    <React.Fragment>
      <Col md={4} className="p-1">
        <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              <input
                onChange={e => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={`Search ...`}
                value={value || ""}
              />
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>

      </Col>

    </React.Fragment>
  );
}

const ControlledTableContainerV8 = ({
  columns,
  odataFetch,
  initialSortBy,
  isGlobalFilter,
  addButtonLabel,
  onAddClick,
  customPageSize,
  className,
  customPageSizeOptions,
  filterColumns,
  fetchCondition,
  rerender
}) => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);
  const [totalPage, setTotalPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(customPageSize ?? 10);
  const [sorting, setSorting] = React.useState([]);
  const [pageIndex, setPageIndex] = React.useState(0);
  const [filterValue, setFilterValue] = React.useState('');
  //const rerender = React.useReducer(() => ({}), {})[1];

  useEffect(() => {
    setLoading(true);
    if (odataFetch) { 
      var filter = filterValue ? makeOdataFilter(filterValue,filterColumns) : "";
      filter = filter ? fetchCondition ? '(' + fetchCondition + ') and ' + filter : filter : fetchCondition;
      odataFetch(pageSize, pageIndex * pageSize ,sorting[0]?.id ?? initialSortBy, sorting[0] ? (sorting[0].desc ? "desc" : "asc") : "desc" ,filter).then(({data}) => {
        setData(data.value);
        setTotalRows(data['@odata.count']);
        setTotalPage(Math.ceil(data['@odata.count']/pageSize));
        //rerender();
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [pageIndex, pageSize, sorting, filterValue,rerender]);
  
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    //getPaginationRowModel: getPaginationRowModel(),
    manualPagination:true
  });
// Manage your own state
/*const [state, setState] = React.useState(table.initialState)
 // Override the state managers for the table to your own
 table.setOptions(prev => ({
    ...prev,
    state,
    onStateChange: setState,
  }))*/
  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    let value = parseInt(event.target.value);
    if (value < 1) value = 1;
    if (value > totalPage) value = totalPage;
    setPageIndex(value - 1);
  };

  const setServersideFilter = (value) => {
    setFilterValue(value);
  }

  const makeOdataFilter = (value,columns) => {
    let returnValue = columns
    .map(entry => `contains(${entry}, '${value}')`)
    .join(' or ');

    return returnValue;
  }
  
  return (
    <Fragment>
      <Row className="mb-2">
        <Col md={customPageSizeOptions ? 3 : 2} className="p-1">
          <select
            className="form-select"
            value={pageSize}
            onChange={onChangeInSelect}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize} per Page
              </option>
            ))}
          </select>
        </Col>
        {isGlobalFilter && (
          <GlobalFilter
            globalFilter={filterValue}
            setGlobalFilter={setServersideFilter}
          />
        )}
        {addButtonLabel && (
          <Col sm="6">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={onAddClick}
              >
                <i className="mdi mdi-plus me-1" />
                Add New {addButtonLabel}
              </Button>
            </div>
          </Col>
        )}
      </Row>
      {loading && <LinearProgress/>}
      <div className="table-responsive react-table" style={{overflow:"visible"}}>
        <Table bordered hover className={className}>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id} colSpan={header.colSpan}>
                {header.isPlaceholder ? null : (
                  <div
                    {...{
                      className: header.column.getCanSort()
                        ? 'cursor-pointer select-none'
                        : '',
                      onClick: header.column.getToggleSortingHandler(),
                    }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </div>
                )}
              </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        </Table>
      </div>
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={() => setPageIndex(0)}
              disabled={pageIndex == 0}
            >
              {"<<"}
            </Button>
            <Button
              color="primary"
              onClick={() => setPageIndex(pageIndex - 1)}
              disabled={pageIndex==0}
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page{" "}
          <strong>
            {/*{pageIndex + 1} of {pageOptions.length}*/}
          </strong>
        </Col>
        <Col className="col-md-auto">
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={totalPage}
            value={pageIndex + 1}
            onChange={onChangeInInput}
          />
        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button color="primary" onClick={() => setPageIndex(pageIndex + 1)} disabled={pageIndex == (totalPage -1)}>
              {">"}
            </Button>
            <Button
              color="primary"
              onClick={() => setPageIndex(totalPage - 1)}
              disabled={pageIndex == (totalPage -1)}
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};


export default ControlledTableContainerV8;