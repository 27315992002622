import React, { useCallback, useContext, useState } from "react";
import { Handle, NodeResizeControl, Position } from 'reactflow';
import { Button, Col, Row, Table } from "reactstrap";
import SimpleNoticeModal from "components/Common/SimpleNoticeModal";
import IntegrationLogModal from "pages/IntegrationLog/IntegrationLogModal";
import { IntzServiceContext } from "services/intzService";
import { CLIENT_SYNC_TYPE } from "services/intzEnum";
import { useDispatch } from "react-redux";
import TransformNodeModal from "./TransformNodeModal";
import { updateNode } from "store/integration/actions";
import ResizeIcon from "./ResizeIcon";
import { getTargetCssClasses } from "../IntegrationUIHelper";
const handleStyle = { left: 10 };
const controlStyle = {
  background: 'transparent',
  border: 'none',
  zIndex: 500
};

function TransformNode({ data }) {

  const { intzSvc } = useContext(IntzServiceContext);
  const [modal, setModal] = useState(false);
  const [noticeModal, setNoticeModal] = useState(false);
  const [logId, setLogId] = useState();
  const [editModal, setEditModal] = useState(false);
  const [entityData, setEntityData] = useState({})
  const dispatch = useDispatch();

  const onChange = useCallback((evt) => {
    console.log(evt.target.value);
  }, []);

  const showLastLog = () => {
    if (data.id) {
      intzSvc.searchIntegrationLog(1,0,'loggedOn','desc','nodeId eq ' + data.id).then(({data}) => {
        if(data['@odata.count']>0) {
          setLogId(data.value[0].id);
          setModal(true);
        } else {
          setNoticeModal(true);
        }
    });
    }
  }

  const showEditModal = () => {
    setEntityData({...data});
    setEditModal(true);
  }
  
  const handleEditModalClose = (editedNode) => {
    if(editedNode) {
      dispatch(updateNode(editedNode));
    }
    setEditModal(false);
    setEntityData({});
  }
  
  const handleModalClose = () => {
    setModal(false);
  };
  const handleNoticeModalClose = () => {
    setNoticeModal(false);
  };
  const handleResize = (event,params) => {
    var editedNode = {...data, width:params.width, height:params.height, syncType: data.syncType == CLIENT_SYNC_TYPE.DELETED ? CLIENT_SYNC_TYPE.DELETED : CLIENT_SYNC_TYPE.UPDATED};
    dispatch(updateNode(editedNode));
  };
  return (
    <>
    <NodeResizeControl style={controlStyle} minWidth={450} minHeight={120} onResize={handleResize}>
        <ResizeIcon />
      </NodeResizeControl>
      <div className={data.syncType == CLIENT_SYNC_TYPE.DELETED ? "intz-node intz-node-deleted" : "intz-node"} style={data.isLogging ? {borderColor:"#f1b44c"} : {}}>
      <Handle id="top" type="target" position={Position.Top} isConnectable={!data.isStartNode} />
      <Handle id="left" type="source" position={Position.Left}/>
      {/*<Handle id="right" type="source" position={Position.Right}/>*/}
      <Handle id="bottom" type="source" position={Position.Bottom}/>
      <Row>
        <Col><span className='badge badge-soft-danger'>Transform </span> <b>{data.description}  {data.syncType == CLIENT_SYNC_TYPE.UPDATED && "*"}<span className='badge badge-soft-primary'>{data.syncType == CLIENT_SYNC_TYPE.CREATED && "NEW" }</span></b>
        <i className="bx bx-cog" style={{float:"right", marginLeft:"20px"}}  onClick={showEditModal}></i>
        {data.isGlobalExceptionNode && <span className='badge badge-soft-danger' style={{float:"right", marginLeft:"50px"}}>Global Exception</span>}
        {data.isStartNode && <span className='badge badge-soft-success' style={{float:"right", marginLeft:"50px"}}>Start Node</span>}</Col>
      </Row>
      <Row style={{padding:"5px"}}>
        <Col>
        <Table striped bordered hover size="sm" style={{borderColor:"#54586d", marginTop:"15px"}}>
      <thead>
        <tr>
          <th>#</th>
          <th>Source</th>
          <th>Target</th>
          <th>Expression</th>
          <th>To Input</th>
        </tr>
      </thead>
      <tbody>
      {data.transform.mappings?.map(m => 
            <tr key={m.id}>
                <td>
                {m.id ? <span className={m.syncType == CLIENT_SYNC_TYPE.DELETED ? "text-decoration-line-through text-secondary" : ""}>{m.id}</span> : <span className={"badge badge-soft-success"}>new</span>}
                </td>
                <td>
                {m.sourcePropertyName ? <span className={m.syncType == CLIENT_SYNC_TYPE.DELETED ? "text-decoration-line-through text-secondary" : ""}>{m.sourcePropertyName}</span> : <span className='badge badge-soft-secondary'>None </span>}
                </td>
                <td>
                {m.targetPropertyName ? <span className={m.syncType == CLIENT_SYNC_TYPE.DELETED ? "text-decoration-line-through text-secondary" : ""}>{m.targetPropertyName}</span> : <span className='badge badge-soft-secondary'>None </span>}
                </td>
                <td>
                {m.expression ? <span className={m.syncType == CLIENT_SYNC_TYPE.DELETED ? "text-decoration-line-through text-secondary" : ""}>{m.expression}</span> : <span className='badge badge-soft-secondary'>Same </span>}
                </td>
                <td>
                <span className={m.syncType == CLIENT_SYNC_TYPE.DELETED ? "text-decoration-line-through text-secondary" : ""}><span className={getTargetCssClasses(m.targetType)}>{m.targetType}</span></span>
                </td>
            </tr>
        )}
      </tbody>
    </Table>
        
        </Col>
      </Row>
      {data.syncType != CLIENT_SYNC_TYPE.DELETED && <Button size='sm' style={{position:"absolute", left:"10px",bottom:"10px"}} onClick={showLastLog}>Show last log</Button>}
      <IntegrationLogModal
                show={modal}
                id={logId}
                onCloseClick={handleModalClose}/>
      <SimpleNoticeModal show={noticeModal} onCloseClick={handleNoticeModalClose} message="No log found"/>
      <TransformNodeModal
                show={editModal}
                data={entityData}
                isEdit={true}
                onCloseClick={handleEditModalClose}/>
    </div>
    </>
  );
}

export default TransformNode;