import React, { Fragment, useEffect } from "react";
import {
    PaginationState,
    OnChangeFn,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    useReactTable,
  } from '@tanstack/react-table'
import { Table, Row, Col, Button, Input, CardBody } from "reactstrap";
import { Filter, DefaultColumnFilter } from "./filters";
import { CLIENT_SYNC_TYPE } from "services/intzEnum";

// Define a default UI for filtering
function GlobalFilter({
  globalFilter,
  setGlobalFilter
}) {
  const [value, setValue] = React.useState(globalFilter);

  function debounce(func, timeout = 300){
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }

  const onChange = React.useCallback(debounce(value => {
    setGlobalFilter((value && value.length > 2) ? value : undefined);
  }, 200), []); // needed usecallback to prevent rerendering



  return (
    <React.Fragment>
      <Col md={4} className="p-1">
        <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              <input
                onChange={e => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={`Search ...`}
                value={value || ""}
              />
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>

      </Col>

    </React.Fragment>
  );
}

const TableContainerV8 = ({
  columns,
  data,
  initialSortBy,
  isGlobalFilter,
  setGlobalFilter,
  addButtonLabel,
  onAddClick,
  className,
  customPageSizeOptions,
  customPageSize,
  filterColumns
}) => {
  const [sorting, setSorting] = React.useState([]);
  const [filterValue, setFilterValue] = React.useState('');
  
  useEffect(() => {
    table.setPageSize(customPageSize);
  }, [customPageSize]);

  const table = useReactTable({
    data,
    autoResetAll : false,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <Fragment>
      <Row className="mb-2">
        <Col md={customPageSizeOptions ? 3 : 2} className="p-1">
          <select
            className="form-select"
            value={table.getState().pagination.pageSize}
            onChange={e => {
              table.setPageSize(Number(e.target.value))
            }}
          >
            {[5, 10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              {pageSize} per page
            </option>
          ))}
          </select>
        </Col>
        {isGlobalFilter && (
          <GlobalFilter
            globalFilter={filterValue}
            setGlobalFilter={setGlobalFilter}
          />
        )}
        {addButtonLabel && (
          <Col sm="6">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={onAddClick}
              >
                <i className="mdi mdi-plus me-1" />
                Add New {addButtonLabel}
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <div className="table-responsive react-table">
        <Table bordered hover className={className}>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id} colSpan={header.colSpan}>
                {header.isPlaceholder ? null : (
                  <div
                    {...{
                      className: header.column.getCanSort()
                        ? 'cursor-pointer select-none'
                        : '',
                      onClick: header.column.getToggleSortingHandler(),
                    }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </div>
                )}
              </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr key={row.id} >
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        </Table>
              </div>
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              {"<<"}
            </Button>
            <Button
              color="primary"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {"<"}
              </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page{" "}
          <strong>
            {table.getState().pagination.pageIndex + 1} of{' '}
            {table.getPageCount()}
          </strong>
        </Col>
        <Col className="col-md-auto">
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={table.getPageCount()+1}
            value={table.getState().pagination.pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              table.setPageIndex(page)
            }}
          />
        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button color="primary" onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}>
              {">"}
            </Button>
            <Button
              color="primary"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              {">>"}
            </Button>
          </div>
        </Col>
            </Row>
    </Fragment>
  );
};


export default TableContainerV8;