import { ELSE_BEHAVIOR_TYPE, INTEGRATION_STATUS, TARGET_TYPE } from "services/intzEnum";


  export function getStatusCssClasses(status) {
    var type;
    switch(status) {
      case INTEGRATION_STATUS.DRAFT:
        type="info"
        break;
        case INTEGRATION_STATUS.DISABLED:
        type="warning"
        break;
        case INTEGRATION_STATUS.ACTIVE:
        type="success"
        break;
      case INTEGRATION_STATUS.DELETED:
        type="secondary"
        break;
          
      default:
        type=""
    }

    return `badge badge-soft-${
      type
    }`;
  }

  export function getBehaviorCssClasses(behaviorType) {
    var type;
    switch(behaviorType) {
      case ELSE_BEHAVIOR_TYPE.BREAK:
        type="warning"
        break;
        case ELSE_BEHAVIOR_TYPE.EXCEPTION:
        type="danger"
        break;
        case ELSE_BEHAVIOR_TYPE.ELSENODE:
        type="info"
        break;
          
      default:
        type=""
    }

    return `badge badge-soft-${
      type
    }`;
  }

  export function getTargetCssClasses(targetType) {
    var type;
    switch(targetType) {
      case TARGET_TYPE.OUTPUT:
        type="info"
        break;
        case TARGET_TYPE.INPUT:
        type="success"
        break;
        case TARGET_TYPE.CONTEXT:
        type="primary"
        break;
        case TARGET_TYPE.PARENT:
        type="warning"
        break; 
      default:
        type=""
    }

    return `badge badge-soft-${
      type
    }`;
  }

  export function debounce(func, timeout = 300){
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }
  