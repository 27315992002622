import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import IntegrationList from "pages/Integration/IntegrationList"
import HangfireDashboard from "pages/Hangfire/HangfireDashboard"
import ConfigurationList from "pages/Configuration/ConfigurationList"
import AppList from "pages/App/AppList"
import AppActionList from "pages/AppAction/AppActionList"
import AppEndpointList from "pages/AppEndpoint/AppEnpointList"
import UserList from "pages/User/UserList"
import IntegrationTransactionList from "pages/IntegrationTransaction/IntegrationTransactionList"
import IntegrationLogList from "pages/IntegrationLog/IntegrationLogList"
import IntegrationDetail from "pages/Integration/IntegrationDetail/IntegrationDetail"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard/> },

  // //profile
  { path: "/profile", component: <UserProfile/> },
  { path: "/hangfire", component: <HangfireDashboard /> },
  { path: "/integration", component: <IntegrationList /> },
  { path: "/integration/:id", component: <IntegrationDetail /> },
  { path: "/configuration", component: <ConfigurationList /> },
  { path: "/app", component: <AppList /> },
  { path: "/appAction", component: <AppActionList /> },
  { path: "/appEndpoint", component: <AppEndpointList /> },
  { path: "/user", component: <UserList /> },
  { path: "/transaction", component: <IntegrationTransactionList /> },
  { path: "/log", component: <IntegrationLogList /> },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
   {
    path: "/",
    exact: true,
    component: < Navigate to="/dashboard" />,
  },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
]

export { authProtectedRoutes, publicRoutes }

