import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Form
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup";
import { IntzServiceContext } from "services/intzService";
import ControlledTypehead from "components/Common/ControlledTypehead";
import { AUTH_TYPE, INTEGRATION_STATUS, METHOD_TYPE, NODE_TYPE } from "services/intzEnum";
import { getMethodTypeCssClasses } from '../../AppAction/AppActionUIHelper';


const IntegrationDetailModal = props => {
    const { show, isEdit, data, onCloseClick } = props;
    const { intzSvc } = useContext(IntzServiceContext);

    const [isLoading, setIsLoading] = useState(false);

    const initData = {
        id: '',
        status: INTEGRATION_STATUS.DRAFT,
        description:  '',
        entryNodeId: '',
        globalExceptionNodeId: '',
        ownedById:''
    };
    const [initialValues, setInitialValues] = React.useState(initData);
    const formik  = useFormik({
    enableReinitialize: true,

    initialValues: initialValues,
    validationSchema: Yup.object({
        description: Yup.string().required("Please enter description")
    }),

    onSubmit: (values) => {
        onCloseClick(values);
    },
    });

    useEffect(() => {
        if (show) {
            setInitialValues({...data});
        } else {
            setInitialValues(initData);
        }

    }, [show]);

    return (
    <Modal isOpen={show} size="lg">
        <ModalHeader tag="h4">
        {isEdit ? "Edit Integration" : "Add new Integration"}
        </ModalHeader>
        <ModalBody>
        <Form
            onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
            }}
            
        >
            <Row>
            {isEdit && <><Col md="6">
                    <div className="mb-3">
                <Label className="form-label">Id</Label>
                <Input
                    name="id"
                    type="text"
                    readOnly
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.id ? ("#IN"+formik.values.id) : ""}
                    invalid={
                    formik.touched.id && formik.errors.id ? true : false
                    }
                    disabled={isLoading}
                />
                {formik.touched.id && formik.errors.id ? (
                    <FormFeedback type="invalid">{formik.errors.id}</FormFeedback>
                ) : null}
                </div></Col>
                <Col md="6">
                <div className="mb-3">
            <Label className="form-label">Status</Label>
                      <Input
                        name="status"
                        type="select"
                        className="form-select"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={
                            formik.values.status || ""
                        }
                        invalid={
                            formik.touched.status && formik.errors.status ? true : false
                        }
                      > <option value="">Select Status</option>
                        <option value={INTEGRATION_STATUS.DRAFT}>DRAFT</option>
                        <option value={INTEGRATION_STATUS.DISABLED}>DISABLED</option>
                        <option value={INTEGRATION_STATUS.ACTIVE}>ACTIVE</option>
                      </Input>
                      {formik.touched.status && formik.errors.status ? (
                        <FormFeedback type="invalid">{formik.errors.status}</FormFeedback>
                      ) : null}
            </div></Col></>}
                </Row>
                <Row>
            <Col md="12">
                <div className="mb-3">
                <Label className="form-label">Name</Label>
                <Input
                    name="name"
                    type="text"
                    placeholder="Integration name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name || ""}
                    invalid={
                    formik.touched.name && formik.errors.name ? true : false
                    }
                    disabled={isLoading}
                />
                {formik.touched.name && formik.errors.name ? (
                    <FormFeedback type="invalid">{formik.errors.name}</FormFeedback>
                ) : null}
                </div>

            </Col>
            </Row>
                <Row>
            <Col md="12">
                <div className="mb-3">
                <Label className="form-label">Description</Label>
                <Input
                    name="description"
                    type="textarea"
                    placeholder="Integration description"
                    rows="3"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description || ""}
                    invalid={
                    formik.touched.description && formik.errors.description ? true : false
                    }
                    disabled={isLoading}
                />
                {formik.touched.description && formik.errors.description ? (
                    <FormFeedback type="invalid">{formik.errors.description}</FormFeedback>
                ) : null}
                </div>

            </Col>
            </Row>

            {isEdit && <Row>
            <Col md="6">
            <div className="mb-3">
                <Label className="form-label">Entry Node</Label>
                <ControlledTypehead
                odataFetch = {intzSvc.searchNode}
                value={formik.values.entryNodeId}
                filterColumn = "description"
                fetchCondition = {'integrationId eq ' + formik.values.id}
                labelKey= {(option) => `${option["description"]}`}
                valueSelected = {(id) => {
                    formik.setFieldValue('entryNodeId',id);
                }}
                invalid={
                    formik.errors.entryNodeId ? true : false
                }
                />
                {formik.errors.entryNodeId ? (
                    <div className="text-danger"><small>{formik.errors.entryNodeId}</small></div>
                ) : null}
                </div>
            </Col>
            <Col md="6">
            <div className="mb-3">
                <Label className="form-label">Global Exception Node</Label>
                <ControlledTypehead
                odataFetch = {intzSvc.searchNode}
                value={formik.values.globalExceptionNodeId}
                enableNone = {true}
                filterColumn = "description"
                fetchCondition = {'integrationId eq ' + formik.values.id}
                labelKey= {(option) => `${option["description"]}`}
                valueSelected = {(id) => {
                    formik.setFieldValue('globalExceptionNodeId',id);
                }}
                invalid={
                    formik.errors.globalExceptionNodeId ? true : false
                }
                />
                {formik.errors.globalExceptionNodeId ? (
                    <div className="text-danger"><small>{formik.errors.globalExceptionNodeId}</small></div>
                ) : null}
                </div>
            </Col>
            </Row> }
            
            <Row>
            <Col md="12">
                <div className="text-end">
                <button type="button" className="btn btn-secondary" onClick={() => onCloseClick()} disabled={isLoading}>Cancel</button>
                &nbsp;&nbsp;
                <button
                    type="submit"
                    className="btn btn-success save-integration"
                    disabled={isLoading || !formik.isValid}
                >
                    Save
                </button>
                
                </div>
            </Col>
            </Row>
        </Form>
        
        </ModalBody>
    </Modal>
    )
}

export default IntegrationDetailModal