import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
    Button,
    Col,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table,
    Form
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup";
import { IntzServiceContext } from "services/intzService";
import ControlledTypehead from "components/Common/ControlledTypehead";
import { AUTH_TYPE, METHOD_TYPE, RATE_LIMIT_THRESHOLD_TYPE } from "services/intzEnum";
const AppActionModal = props => {
    const { show, isEdit, id, onClose } = props;
    const { intzSvc } = useContext(IntzServiceContext);
    const [isLoading, setIsLoading] = useState(false);
    const [appId, setAppId] = useState();
    const [appList, setAppList] = useState([]);
    const [appEndpointList, setAppEndpointList] = useState([]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: '',
            description: '',
            assertion: '',
            methodType: '',
            route: '',
            rateLimitHttpStatusCode: 0,
            rateLimitErrorCode: '',
            rateLimitThresholdType: '',
            rateLimitThresholdValue: '',
            globalContextKey: '',
            globalContextValue: ''
        },
        validationSchema: Yup.object({
            description: Yup.string().required("Please enter description"),
            endpointId: Yup.number().required("Please select an endpoint"),
            methodType: Yup.string().required("Please select method"),
            rateLimitHttpStatusCode: Yup.number(),
            rateLimitThresholdType: Yup.string(),
            rateLimitThresholdValue: Yup.string(),
            rateLimitErrorCode: Yup.string(),
            globalContextKey: Yup.string(),
            globalContextValue: Yup.string()
        }).test('rateLimitHttpStatusCode', 
            null,
            (obj) => {
                if((obj.rateLimitErrorCode || obj.rateLimitThresholdType || obj.rateLimitThresholdValue) && !obj.rateLimitHttpStatusCode){
                    return new Yup.ValidationError('Please select http status or remove all rate limit fields', null, 'rateLimitHttpStatusCode')
                }
            }
        ).test('rateLimitThresholdType',
            null,
            (obj) => {
                if((obj.rateLimitHttpStatusCode || obj.rateLimitErrorCode || obj.rateLimitThresholdValue) && !obj.rateLimitThresholdType) {
                    return new Yup.ValidationError('Please select threshold type or remove all rate limit fields', null, 'rateLimitThresholdType')
                }
            }
        ).test('rateLimitThresholdValue',
            null,
            (obj) => {
                if((obj.rateLimitHttpStatusCode || obj.rateLimitErrorCode || obj.rateLimitThresholdType) && !obj.rateLimitThresholdValue) {
                    return new Yup.ValidationError('Please enter threhold value or remove all rate limit fields', null, 'rateLimitThresholdValue')
                }
            }
        ).test('globalContextKey',
            null,
            (obj) => {
                if(obj.globalContextValue && !obj.globalContextKey) {
                    return new Yup.ValidationError('Please enter global context key or remove global context value', null, 'globalContextKey')
                }
            }
        ).test('globalContextValue',
            null,
            (obj) => {
                if(obj.globalContextKey && !obj.globalContextValue) {
                    return new Yup.ValidationError('Please enter global context value or remove global context key', null, 'globalContextValue')
                }
            }
        ),

        onSubmit: (values) => {
            if (isEdit) {
                intzSvc.updateAppAction(values).then(({ data }) => {
                    formik.resetForm();
                    onClose(true);
                });
            } else {
                intzSvc.createAppAction(values).then(({ data }) => {
                    formik.resetForm();
                    onClose(true);
                });
            }

        },
    });

    useEffect(() => {
        if (show) {
            if (id) {
                setIsLoading(true);
                intzSvc.getAppAction(id).then(({ data }) => {
                    formik.setValues(data);
                    setIsLoading(false);
                });
                intzSvc.searchAppEndpoint(100,0,'createdOn','desc').then(({data}) => {
                    setAppEndpointList(data.value);
                  }).finally(() => {
                  });
            } else {
                intzSvc.searchApp(100,0,'createdOn','desc').then(({data}) => {
                    setAppList(data.value);
                    }).finally(() => {
                    });
            }
            
        } else {
            formik.resetForm();
        }
    }, [show]);
    useEffect(() => {
        intzSvc.searchAppEndpoint(100,0,'createdOn','desc',appId ? 'appId eq ' + appId : '').then(({data}) => {
          setAppEndpointList(data.value);
        }).finally(() => {
        });
      }, [appId]);
    return (
        <Modal isOpen={show} size="lg">
            <ModalHeader tag="h4">
                {!!isEdit ? "Edit App Action" : "Add new App Action"}
            </ModalHeader>
            <ModalBody>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                        return false;
                    }}

                >
                    <Row>
                        {isEdit && <Col md="6">
                            <div className="mb-3">
                                <Label className="form-label">Id</Label>
                                <Input
                                    name="id"
                                    type="text"
                                    readOnly
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.id ? ("#AA" + formik.values.id) : ""}
                                    invalid={
                                        formik.touched.id && formik.errors.id ? true : false
                                    }
                                    disabled={isLoading}
                                />
                                {formik.touched.id && formik.errors.id ? (
                                    <FormFeedback type="invalid">{formik.errors.id}</FormFeedback>
                                ) : null}
                            </div></Col>}
                        {!isEdit && <Col md="6">
                            <div className="mb-3">
                                <Label className="form-label">App</Label>
                                <select
                                    className="form-select"
                                    value={appId}
                                    onChange={(e) => {
                                        setAppId(e.target.value);
                                        formik.setFieldValue('endpointId', '');
                                    }}>
                                    <option key={''} value={''}>
                                    Select App
                                        </option>
                                    {appList.map(app => (
                                        <option key={app.id} value={app.id}>
                                        {app.description}
                                        </option>
                                    ))}
                                    </select>
                            </div>
                        </Col>}
                        <Col md="6">
                            <div className="mb-3">
                                <Label className="form-label">Endpoint</Label>
                                <Input
                                    name="endpointId"
                                    type="select"
                                    className="form-select"
                                    style={{ pointerEvents: isEdit ? 'none' : 'auto' }}
                                    onChange={(e) => {
                                        if (!isEdit) {
                                        formik.handleChange(e);
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={
                                        formik.values.endpointId || ""
                                    }
                                    invalid={
                                        formik.touched.endpointId && formik.errors.endpointId ? true : false
                                    }
                                > <option key={''} value={''}>
                                All Endpoints
                                </option>
                                {appEndpointList.map(appEndpoint => (
                                <option key={appEndpoint.id} value={appEndpoint.id}>
                                    {appEndpoint.description}
                                </option>
                                ))}
                                </Input>
                                {formik.errors.endpointId ? (
                                    <div className="text-danger"><small>{formik.errors.endpointId}</small></div>
                                ) : null}
                                
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <div className="mb-3">
                                <Label className="form-label">Description</Label>
                                <Input
                                    name="description"
                                    type="textarea"
                                    placeholder="App action description"
                                    rows="3"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.description || ""}
                                    invalid={
                                        formik.touched.description && formik.errors.description ? true : false
                                    }
                                    disabled={isLoading}
                                />
                                {formik.touched.description && formik.errors.description ? (
                                    <FormFeedback type="invalid">{formik.errors.description}</FormFeedback>
                                ) : null}
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <div className="mb-3">
                                <Label className="form-label">Route</Label>
                                <Input
                                    name="route"
                                    type="textarea"
                                    rows="3"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.route ? formik.values.route : ""}
                                    invalid={
                                        formik.touched.route && formik.errors.route ? true : false
                                    }
                                    disabled={isLoading}
                                />
                                {formik.touched.route && formik.errors.route ? (
                                    <FormFeedback type="invalid">{formik.errors.route}</FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <div className="mb-3">
                                <Label className="form-label">Method</Label>
                                <Input
                                    name="methodType"
                                    type="select"
                                    className="form-select"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={
                                        formik.values.methodType || ""
                                    }
                                    invalid={
                                        formik.touched.methodType && formik.errors.methodType ? true : false
                                    }
                                > <option value="">Select Type</option>
                                    <option value={METHOD_TYPE.GET}>GET</option>
                                    <option value={METHOD_TYPE.POST}>POST</option>
                                    <option value={METHOD_TYPE.PUT}>PUT</option>
                                    <option value={METHOD_TYPE.PATCH}>PATCH</option>
                                    <option value={METHOD_TYPE.DELETE}>DELETE</option>
                                </Input>
                                {formik.touched.methodType && formik.errors.methodType ? (
                                    <FormFeedback type="invalid">{formik.errors.methodType}</FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <div className="mb-3">
                                <Label className="form-label">Request Property</Label>
                                <Input
                                    name="requestPropertyName"
                                    type="text"
                                    placeholder="Property name"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.requestPropertyName || ""}
                                    invalid={
                                        formik.touched.requestPropertyName && formik.errors.requestPropertyName ? true : false
                                    }
                                    disabled={isLoading}
                                />
                                {formik.touched.requestPropertyName && formik.errors.requestPropertyName ? (
                                    <FormFeedback type="invalid">{formik.errors.requestPropertyName}</FormFeedback>
                                ) : null}
                            </div>

                        </Col>
                        <Col md="6">
                            <div className="mb-3">
                                <Label className="form-label">Result Property</Label>
                                <Input
                                    name="resultPropertyName"
                                    type="text"
                                    placeholder="Property name"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.resultPropertyName || ""}
                                    invalid={
                                        formik.touched.resultPropertyName && formik.errors.resultPropertyName ? true : false
                                    }
                                    disabled={isLoading}
                                />
                                {formik.touched.resultPropertyName && formik.errors.resultPropertyName ? (
                                    <FormFeedback type="invalid">{formik.errors.resultPropertyName}</FormFeedback>
                                ) : null}
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <div className="mb-3">
                                <Label className="form-label">Assertion</Label>
                                <Input
                                    name="assertion"
                                    type="text"
                                    placeholder="Action assertion"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.assertion || ""}
                                    invalid={
                                        formik.touched.assertion && formik.errors.assertion ? true : false
                                    }
                                    disabled={isLoading}
                                />
                                {formik.touched.assertion && formik.errors.assertion ? (
                                    <FormFeedback type="invalid">{formik.errors.assertion}</FormFeedback>
                                ) : null}
                            </div>

                        </Col>
                    </Row>
                    <Row className='mt-2 mb-1'>
                        <Col sm="12">
                            <h5>Rate Limit</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <div className="mb-3">
                                <Label className="form-label">Rate Limit Http Status Code</Label>
                                <Input
                                    name="rateLimitHttpStatusCode"
                                    type="select"
                                    className="form-select"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.rateLimitHttpStatusCode || 0}
                                    invalid={formik.touched && formik.errors.rateLimitHttpStatusCode ? true : false}
                                >
                                    <option value={0}>Select Type</option>
                                    <option value={429}>429 Too Many Request</option>
                                </Input>
                                {formik.touched && formik.errors.rateLimitHttpStatusCode ? (
                                    <FormFeedback type='invalid'>{formik.errors.rateLimitHttpStatusCode}</FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="mb-3">
                                <Label className='form-label'>Rate Limit Error Code</Label>
                                <Input
                                    name="rateLimitErrorCode"
                                    type="text"
                                    placeholder="API Response Error Code"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.rateLimitErrorCode || ""}
                                    invalid={formik.touched && formik.errors.rateLimitErrorCode ? true : false}
                                    disabled={isLoading}
                                />
                                {formik.touched && formik.errors.rateLimitErrorCode ? (
                                    <FormFeedback type="invalid">{formik.errors.rateLimitErrorCode}</FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <div className="mb-3">
                                <Label className='form-label'>Rate Limit Threshold Type</Label>
                                <Input
                                    name='rateLimitThresholdType'
                                    type='select'
                                    className='form-select'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.rateLimitThresholdType || ""}
                                    invalid={formik.touched && formik.errors.rateLimitThresholdType ? true : false}
                                >
                                    <option value="">Select Type</option>
                                    <option value={RATE_LIMIT_THRESHOLD_TYPE.REQUESTS_PER_SECOND}>Requests Per Second</option>
                                    <option value={RATE_LIMIT_THRESHOLD_TYPE.SECONDS}>Seconds</option>
                                </Input>
                                {formik.touched && formik.errors.rateLimitThresholdType ? (
                                    <FormFeedback type='invalid'>{formik.errors.rateLimitThresholdType}</FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="mb-3">
                                <Label className='form-label'>Rate Limit Threshold Value</Label>
                                <Input
                                    name='rateLimitThresholdValue'
                                    type='text'
                                    placeholder="Threhold Value"
                                    onChange={formik.handleChange}
                                    onBlur= {formik.handleBlur}
                                    value={formik.values.rateLimitThresholdValue || ""}
                                    invalid={formik.touched && formik.errors.rateLimitThresholdValue ? true : false}
                                    disabled={isLoading}
                                />
                                {formik.touched && formik.errors.rateLimitThresholdValue ? (
                                    <FormFeedback type='invalid'>{formik.errors.rateLimitThresholdValue}</FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-2 mb-1'>
                        <Col sm="12">
                            <h5>Global Context</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <div className="mb-3">
                                <Label className="form-label">Global Context Key</Label>
                                <Input
                                    name='globalContextKey'
                                    type='text'
                                    placeholder="Key"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.globalContextKey || ""}
                                    invalid={formik.touched && formik.errors.globalContextKey ? true : false}
                                    disabled={isLoading}
                                />
                                {formik.touched && formik.errors.globalContextKey ? (
                                    <FormFeedback type='invalid'>{formik.errors.globalContextKey}</FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="mb-3">
                                <Label className="form-label">Global Context Value</Label>
                                <Input
                                    name='globalContextValue'
                                    type='text'
                                    placeholder="Value"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.globalContextValue || ""}
                                    invalid={formik.touched && formik.errors.globalContextValue ? true : false}
                                    disabled={isLoading}
                                />
                                {formik.touched && formik.errors.globalContextValue ? (
                                    <FormFeedback type='invalid'>{formik.errors.globalContextValue}</FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <div className="text-end">
                                <button type="button" className="btn btn-secondary" onClick={() => onClose()} disabled={isLoading} >Cancel</button>
                                &nbsp;&nbsp;
                                <button
                                    type="submit"
                                    className="btn btn-success save-integration"
                                    disabled={isLoading}
                                >
                                    Save
                                </button>

                            </div>
                        </Col>
                    </Row>

                </Form>

            </ModalBody>
        </Modal>
    )
}

export default AppActionModal