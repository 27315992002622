import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import moment from 'moment'
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Form
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup";
import { IntzServiceContext } from "services/intzService";
import ControlledTypehead from "components/Common/ControlledTypehead";
import { INTEGRATION_TRANSACTION_STATUS } from "services/intzEnum";
import { getStatusCssClasses } from "./IntegrationTransactionUIHelper";
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';

const IntegrationTransactionModal = props => {
    // readonly modal 
    const { show, id, onClose } = props;
    const { intzSvc } = useContext(IntzServiceContext);
    const [isLoading, setIsLoading] = useState(false);
    const [entityData, setEntityData] = useState({});


    useEffect(() => {
        if (show) {
            if (id) {   
                setIsLoading(true);
                intzSvc.getIntegrationTransaction(id).then(({data}) => {
                    setEntityData(data);
                    setIsLoading(false);
                });
            } 
        } else {
            setEntityData({});
        }
    }, [show]);

    return (
    <Modal isOpen={show} size="lg">
        <ModalHeader tag="h4">
        {"Transaction Details"} {entityData.status ? <span className={getStatusCssClasses(entityData.status)} style={{marginLeft:"10px", fontSize:"0.7rem"}}>{entityData.status}</span> : ''}
        </ModalHeader>
        <ModalBody>
            <Row>
            <Col md="6">
                    <div className="mb-3">
                <Label className="form-label">Id</Label>
                <Input
                    name="id"
                    type="text"
                    readOnly
                    value={entityData.id ? ("#IT"+entityData.id) : ""}
                />
                </div></Col>
                <Col md="6">
            <div className="mb-3">
                <Label className="form-label">Configuration</Label>
                <ControlledTypehead
                odataFetch = {intzSvc.searchIntegrationConfiguration}
                disabled = {true}
                value={entityData.integrationConfigurationId}
                filterColumn = "name"
                labelKey= {(option) => `${option["name"]}`}
                valueSelected = {(id) => {
                    
                }}
                />
                </div>
            </Col>
            </Row>
            <Row>
            <Col md="12">
                <div className="mb-3">
                <Label className="form-label">Key</Label>
                <Input
                    name="key"
                    type="text"
                    readOnly
                    placeholder="Transaction Key"
                    value={entityData.key || ""}
                />
                </div>

            </Col>
            </Row>
            <Row>
            <Col md="6">
                <div className="mb-3">
                <Label className="form-label">Started On</Label>
                <Input
                    name="startedOn"
                    type="text"
                    readOnly
                    placeholder="Started On"
                    value={moment(entityData.startedOn).format('MMMM Do YYYY, h:mm:ss a')}
                />
                </div>

            </Col>
            <Col md="6">
                <div className="mb-3">
                <Label className="form-label">Finished On</Label>
                <Input
                    name="finishedOn"
                    type="text"
                    readOnly
                    placeholder="Finished On"
                    value={moment(entityData.finishedOn).format('MMMM Do YYYY, h:mm:ss a')}
                />
                </div>

            </Col>
            </Row>
            <Row>
            <Col md="6">
                <div className="mb-3">
                <Label className="form-label">Result</Label>
                <Input
                    name="result"
                    type="text"
                    readOnly
                    placeholder="N/A"
                    value={entityData.result || ""}
                />
                </div>

            </Col>
            <Col md="6">
                <div className="mb-3">
                <Label className="form-label">Retry</Label>
                <Input
                    name="retry"
                    type="text"
                    readOnly
                    placeholder="Retry"
                    value={entityData.retry}
                />
                </div>

            </Col>
            </Row>
            {(entityData.status == INTEGRATION_TRANSACTION_STATUS.FAILED || 
                entityData.status == INTEGRATION_TRANSACTION_STATUS.SUCCESS_WITH_WARNING) && <><Row>
            <Col md="12">
                <div className="mb-3">
                <Label className="form-label">Error Message</Label>
                <Input
                    name="errorMessage"
                    type="text"
                    readOnly
                    placeholder="Error Message"
                    value={entityData.errorMessage || ""}
                />
                </div>

            </Col>
            </Row>
            <Row>
            <Col md="12">
                <div className="mb-3">
                <Label className="form-label">Stack Trace</Label>
                <pre className="text-wrap __json-pretty__ small">{entityData.stackTrace}</pre>
                </div>

            </Col>
            </Row></>}
            <Row>
            <Col md="12">
                <div className="mb-3">
                <Label className="form-label">Additional Data</Label>
                <JSONPretty id="additionalData" data={entityData.additionalData}></JSONPretty>
                </div>

            </Col>
            </Row>
            <Row>
            <Col md="12">
                <div className="text-end">
                <button type="button" className="btn btn-secondary" onClick={() => onClose()} disabled={isLoading}>Close</button>
                
                
                </div>
            </Col>
            </Row>
            
        
        </ModalBody>
    </Modal>
    )
}

export default IntegrationTransactionModal