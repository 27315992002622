import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Form
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup";
import { IntzServiceContext } from "services/intzService";

const AppModal = props => {
    const { show, isEdit, entityData, onClose, errorMessage } = props;
    const { intzSvc } = useContext(IntzServiceContext);
    const [isLoading, setIsLoading] = useState(false);

    const formik  = useFormik({
    enableReinitialize: true,

    initialValues: {
        id: '',
        description:  ''
    },
    validationSchema: Yup.object({
        description: Yup.string().required("Please enter description")
    }),

    onSubmit: (values) => {
        if (isEdit) {
            intzSvc.updateApp(values).then(({data}) => {
                formik.resetForm();
                onClose(true);
            });
        } else {
            intzSvc.createApp(values).then(({data}) => {
                formik.resetForm();
                onClose(true);
            });
        }

    },
    });

    useEffect(() => {
        if (show) {
            if (isEdit && entityData) {   
                setIsLoading(true);
                intzSvc.getApp(entityData.id).then(({data}) => {
                    formik.setValues(data);
                    setIsLoading(false);
                });
            } else {
                formik.resetForm();
            }
        }
    }, [show]);

    return (
    <Modal isOpen={show}>
        <ModalHeader tag="h4">
        {!!isEdit ? "Edit App" : "Add new App"}
        </ModalHeader>
        <ModalBody>
        <Form
            onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
            }}
            
        >
            <Row>
            <Col className="col-12">
                {isEdit  && <div className="mb-3">
                <Label className="form-label">Id</Label>
                <Input
                    name="id"
                    type="text"
                    readOnly
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.id ? ("#AP"+formik.values.id) : ""}
                    invalid={
                    formik.touched.id && formik.errors.id ? true : false
                    }
                    disabled={isLoading}
                />
                {formik.touched.id && formik.errors.id ? (
                    <FormFeedback type="invalid">{formik.errors.id}</FormFeedback>
                ) : null}
                </div>}

                <div className="mb-3">
                <Label className="form-label">Description</Label>
                <Input
                    name="description"
                    type="textarea"
                    placeholder="App description"
                    rows="3"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description || ""}
                    invalid={
                    formik.touched.description && formik.errors.description ? true : false
                    }
                    disabled={isLoading}
                />
                {formik.touched.description && formik.errors.description ? (
                    <FormFeedback type="invalid">{formik.errors.description}</FormFeedback>
                ) : null}
                </div>

            </Col>
            </Row>
            <Row>
            <Col>
                <div className="text-end">
                <button type="button" className="btn btn-secondary" onClick={() => onClose()} disabled={isLoading}>Cancel</button>
                &nbsp;&nbsp;
                <button
                    type="submit"
                    className="btn btn-success save-integration"
                    disabled={isLoading}
                >
                    Save
                </button>
                
                </div>
            </Col>
            </Row>
        </Form>
        </ModalBody>
    </Modal>
    )
}

export default AppModal