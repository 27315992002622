import React, { useEffect, useState, useMemo, useContext } from "react";
import Moment from 'react-moment';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
  FormFeedback,
  Label,
  Form,
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import { IntzServiceContext } from "services/intzService";
import ControlledTableContainerV8 from "components/Common/ControlledTableContainerV8";
import { getStatusCssClasses } from "./UserUIHelper";

// Column
const Id = (cell) => {
  return cell.getValue() ? "#UR" + cell.getValue() : '';
};
const Email = (cell) => {
    return cell.getValue() ? cell.getValue() : '';
};
const FirstName = (cell) => {
  return cell.getValue() ? cell.getValue() : '';
};
const LastName = (cell) => {
  return cell.getValue() ? cell.getValue() : '';
};
const Status = (cell) => {
  return cell.getValue() ? <span className={getStatusCssClasses(cell.getValue())}>{cell.getValue()}</span> : '';
};
const CreatedOn = (cell) => {
    return cell.getValue() ? <Moment format='ll'>{cell.getValue()}</Moment>: '';
};

const UserList = props => {

  //meta title
  document.title = "Users | Integratiz";
  const { intzSvc } = useContext(IntzServiceContext);

  
  const handleEntityClick = arg => {

  };

  // Customber Column
  const columns = useMemo(
    () => [

      {
        header: 'Id',
        accessorKey: 'id',
        filterable: false,
        enableSorting: true,
        cell: (cellProps) => {
          return <Id {...cellProps} />;
        }
      },
      {
        header: 'Email',
        accessorKey: 'email',
        enableSorting: true,
        filterable: false,
        cell: (cellProps) => {
          return <Email {...cellProps} />;
          ;
        }
      },
      {
        header: 'First Name',
        accessorKey: 'firstName',
        enableSorting: true,
        filterable: false,
        cell: (cellProps) => {
          return <FirstName {...cellProps} />;
        }
      },
      {
        header: 'Last Name',
        accessorKey: 'lastName',
        enableSorting: true,
        filterable: false,
        cell: (cellProps) => {
          return <LastName {...cellProps} />;
        }
      },
      {
        header: 'Status',
        accessorKey: 'status',
        enableSorting: false,
        filterable: false,
        cell: (cellProps) => {
          return <Status {...cellProps} />;
        }
      },
      {
        header: 'Created On',
        accessorKey: 'createdOn',
        enableSorting: true,
        cell: (cellProps) => {
          return <CreatedOn {...cellProps} />;
        }
      },
      {
        header: 'Action',
        enableSorting: false,
        cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" href="#" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem href="#"
                  onClick={() => {
                    const entityData = cellProps.row.original;
                    onEditClick(entityData);
                  }
                  }
                >
                  <i className="mdi mdi-pencil font-size-16 text-success me-1" id="edittooltip"></i>
                  Edit
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </DropdownItem>

                <DropdownItem href="#"
                  onClick={() => {
                    const entityData = cellProps.row.original;
                    onDeleteClick(entityData);
                  }}>
                  <i className="mdi mdi-trash-can font-size-16 text-danger me-1" id="deletetooltip"></i>
                  Delete
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        }
      },
    ],
    []
  );
  //delete 
  const [deleteModal, setDeleteModal] = useState(false);
  const [entityData, setEntityData] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  const [rerender, setRerender] = useState(0);
  const onDeleteClick = (entityData) => {
    setEntityData(entityData);
    setDeleteModal(true);
  };

  const handleDeleteEntity = () => {
    if (entityData && entityData.id) {
      // intzSvc.deleteConfiguration(configuration.id);
      setDeleteModal(false);
    }
  };

  const handleAddEntity = () => {
    // navigate to add integration
  };

  useEffect(() => {

  }, []);

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEntity}
        errorMessage={errorMessage}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Users" breadcrumbItem="List" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <ControlledTableContainerV8
                    columns={columns}
                    odataFetch={intzSvc.searchUser}
                    initialSortBy={"createdOn"}
                    isGlobalFilter={true}
                    addButtonLabel={"Endpoint"}
                    onAddClick={handleAddEntity}
                    customPageSize={10}
                    className="custom-header-css"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserList;