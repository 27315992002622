import React, { useContext, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Form,
  UncontrolledTooltip,
  DropdownItem,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownToggle
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup";
import { IntzServiceContext } from "services/intzService";
import ControlledTypehead from "components/Common/ControlledTypehead";
import { AUTH_TYPE, CLIENT_SYNC_TYPE } from "services/intzEnum";
import HeaderEntryModal from "./HeaderEntryModal";
import DummyDeleteModal from "pages/Configuration/DummyDeleteModal";
import TableContainerV8 from "components/Common/TableContainerV8";

// Column
const Id = (cell) => {
    return cell.getValue() ? <span className={cell.row.original.syncType == CLIENT_SYNC_TYPE.DELETED ? "text-decoration-line-through text-secondary" : ""}>{cell.getValue()}</span> : <span className={"badge badge-soft-success"}>new</span>;
    };
    
    const Key = (cell) => {
        return cell.getValue() ? <span className={cell.row.original.syncType == CLIENT_SYNC_TYPE.DELETED ? "text-decoration-line-through text-secondary" : ""}>{cell.getValue()}</span> : '';
    };
    
    const Value = (cell) => {
        return cell.getValue() ? <span className={cell.row.original.syncType == CLIENT_SYNC_TYPE.DELETED ? "text-decoration-line-through text-secondary" : ""}>{cell.getValue()}</span> : '';
    };
    
const AppEndpointModal = props => {
    const { show, isEdit, id, onClose, errorMessage } = props;
    const { intzSvc } = useContext(IntzServiceContext);
    const [isLoading, setIsLoading] = useState(false);
    const [entries, setEntries] = useState([]);
    const [isEntryEdit, setIsEntryEdit] = useState(false);
    const [entityData, setEntityData] = useState({});
    const [modal, setModal] = useState(false);
    const [dummyModal, setDummyModal] = useState(false);
    const [appList, setAppList] = useState([]);

    const onDeleteClick = (entityData) => {
      setEntityData(entityData);
      setDummyModal(true);
    };

    const onEditClick = (entityData) => {
    setEntityData(entityData);
    setIsEntryEdit(true);
    setModal(true);
    };

    const onAddClick = () => {
    setEntityData({});
    setIsEntryEdit(false);
    setModal(true);
    };
    const handleModalClose = (entry) => {
        if(entry) {
          if (entry.id) {
            const index = entries.findIndex(row => row.id === entry.id);
            
            if(index !== -1) {
              entry.syncType = CLIENT_SYNC_TYPE.UPDATED;
              entries[index] = entry;
                setEntries([...entries]);
            }
          } else {
            entry.syncType = CLIENT_SYNC_TYPE.CREATED;
            setEntries([entry,...entries]);
          }

        }
        setModal(false);
    
    };
    const handleDummyModalClose = (entry) => {
      // table 버그로 생각되는데 cell에서 바로 불러지는경우 이상현상이 일어난다
      // 때문에 dummy modal을 도입함
      if(entry) {
        if (entry.id) {
          const index = entries.findIndex(row => row.id === entry.id);
          
          if(index !== -1) {
            entry.syncType = CLIENT_SYNC_TYPE.DELETED;
            entries[index] = entry;
              setEntries([...entries]);
          }
        }
      }
      setDummyModal(false);
  
  };
    const formik  = useFormik({
    enableReinitialize: true,

    initialValues: {
        id: '',
        description:  '',
        baseUrl: '',
        threshold: 0,
        authType: '',
        authKey : '',
        authValue : '',
        token : '',
        username: '',
        password: '',
        
    },
    validationSchema: Yup.object({
        description: Yup.string().required("Please enter description"),
        appId: Yup.number().required("Please select an app"),
        authType: Yup.string().required("Please select auth type")
    }),

    onSubmit: (values) => {
        values.headerEntries = entries;
        if (isEdit) {
            intzSvc.updateAppEndpoint(values).then(({data}) => {
                formik.resetForm();
                onClose(true);
            });
        } else {
            intzSvc.createAppEndpoint(values).then(({data}) => {
                formik.resetForm();
                onClose(true);
            });
        }

    },
    });

    // Customber Column
    const columns = useMemo(
        () => [
    
          {
            header: '#',
            accessorKey: 'id',
            filterable: false,
            enableSorting: true,
            cell: (cellProps) => {
              return <Id {...cellProps} />;
            }
          },
          {
            header: 'Key',
            accessorKey: 'key',
            filterable: false,
            enableSorting: true,
            cell: (cellProps) => {
              return <Key {...cellProps} />;
            }
          },
          {
            header: 'Value',
            accessorKey: 'value',
            enableSorting: true,
            filterable: false,
            cell: (cellProps) => {
              return <Value {...cellProps} />;
              ;
            }
          },
          {
            header: 'Action',
            enableSorting: false,
            cell: (cellProps) => {
              return (
                <UncontrolledDropdown>
                  <DropdownToggle tag="a" href="#" className="card-drop">
                    <i className="mdi mdi-dots-horizontal font-size-18"></i>
                  </DropdownToggle>
    
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem href="#"
                      onClick={() => {
                        const entry = cellProps.row.original;
                        onEditClick(entry);
                      }
                      }
                    >
                      <i className="mdi mdi-pencil font-size-16 text-success me-1" id="edittooltip"></i>
                      Edit
                      <UncontrolledTooltip placement="top" target="edittooltip">
                        Edit
                      </UncontrolledTooltip>
                    </DropdownItem>
    
                    <DropdownItem href="#"
                      onClick={() => {
                        const entry = cellProps.row.original;
                        onDeleteClick(entry);
                      }}>
                      <i className="mdi mdi-trash-can font-size-16 text-danger me-1" id="deletetooltip"></i>
                      Delete
                      <UncontrolledTooltip placement="top" target="deletetooltip">
                        Delete
                      </UncontrolledTooltip>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              );
            }
          },
        ],
        []
      );
    useEffect(() => {
        if (show) {
            if (id) {   
                setIsLoading(true);
                intzSvc.getAppEndpoint(id).then(({data}) => {
                    formik.setValues(data);
                    setEntries(data.headerEntries);
                    setIsLoading(false);
                });
            } 
            intzSvc.searchApp(100,0,'createdOn','desc').then(({data}) => {
              setAppList(data.value);
              }).finally(() => {
              });
        } else {
            formik.resetForm();
            setEntries([]);
        }
    }, [show]);

    return (
    <Modal isOpen={show} size="lg">
        <ModalHeader tag="h4">
        {!!isEdit ? "Edit App Endpoint" : "Add new App Endpoint"}
        </ModalHeader>
        <ModalBody>
        <Form
            onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
            }}
            
        >
            <Row>
            {isEdit && <Col md="6">
                    <div className="mb-3">
                <Label className="form-label">Id</Label>
                <Input
                    name="id"
                    type="text"
                    readOnly
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.id ? ("#AP"+formik.values.id) : ""}
                    invalid={
                    formik.touched.id && formik.errors.id ? true : false
                    }
                    disabled={isLoading}
                />
                {formik.touched.id && formik.errors.id ? (
                    <FormFeedback type="invalid">{formik.errors.id}</FormFeedback>
                ) : null}
                </div></Col>}
                <Col md="6">
            <div className="mb-3">
                <Label className="form-label">App</Label>
                <Input
                    name="appId"
                    type="select"
                    className="form-select"
                    style={{ pointerEvents: isEdit ? 'none' : 'auto' }}
                    onChange={(e) => {
                        if (!isEdit) {
                        formik.handleChange(e);
                        }
                    }}
                    onBlur={formik.handleBlur}
                    value={
                        formik.values.appId || ""
                    }
                    invalid={
                        formik.touched.appId && formik.errors.appId ? true : false
                    }
                > <option key={''} value={''}>
                Select App
                </option>
                {appList.map(app => (
                <option key={app.id} value={app.id}>
                    {app.description}
                </option>
                ))}
                </Input>
                {formik.errors.appId ? (
                    <div className="text-danger"><small>{formik.errors.appId}</small></div>
                ) : null}
                </div>
            </Col>
            </Row>
                <Row>
            <Col md="12">
                <div className="mb-3">
                <Label className="form-label">Description</Label>
                <Input
                    name="description"
                    type="textarea"
                    placeholder="App endpoint description"
                    rows="3"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description || ""}
                    invalid={
                    formik.touched.description && formik.errors.description ? true : false
                    }
                    disabled={isLoading}
                />
                {formik.touched.description && formik.errors.description ? (
                    <FormFeedback type="invalid">{formik.errors.description}</FormFeedback>
                ) : null}
                </div>

            </Col>
            </Row>
            <Row>
            <Col md="12">
            <div className="mb-3">
                <Label className="form-label">Base URL</Label>
                <Input
                    name="baseUrl"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.baseUrl ? formik.values.baseUrl : ""}
                    invalid={
                    formik.touched.baseUrl && formik.errors.baseUrl ? true : false
                    }
                    disabled={isLoading}
                />
                {formik.touched.baseUrl && formik.errors.baseUrl ? (
                    <FormFeedback type="invalid">{formik.errors.baseUrl}</FormFeedback>
                ) : null}
                </div>
            </Col>
            </Row>
            <Row>
            <Col md="6">
            <div className="mb-3">
                <Label className="form-label">Auth Type</Label>
                      <Input
                        name="authType"
                        type="select"
                        className="form-select"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={
                            formik.values.authType || ""
                        }
                        invalid={
                            formik.touched.authType && formik.errors.authType ? true : false
                        }
                      > <option value="">Select Type</option>
                        <option value={AUTH_TYPE.NO_AUTH}>NO_AUTH</option>
                        <option value={AUTH_TYPE.API_KEY}>API_KEY</option>
                        <option value={AUTH_TYPE.BEARER_TOKEN}>BEARER_TOKEN</option>
                        <option value={AUTH_TYPE.BASIC_AUTH}>BASIC_AUTH</option>
                      </Input>
                      {formik.touched.authType && formik.errors.authType ? (
                        <FormFeedback type="invalid">{formik.errors.authType}</FormFeedback>
                      ) : null}
                </div>
            </Col>
            <Col md="6">
            <div className="mb-3">
                <Label className="form-label">Threshold</Label>
                <Input
                    name="threshold"
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.threshold ? (formik.values.threshold) : ""}
                    invalid={
                    formik.touched.threshold && formik.errors.threshold ? true : false
                    }
                    disabled={isLoading}
                />
                {formik.touched.threshold && formik.errors.threshold ? (
                    <FormFeedback type="invalid">{formik.errors.threshold}</FormFeedback>
                ) : null}
                </div>
            </Col>
            </Row>
            {formik.values.authType == AUTH_TYPE.API_KEY && <><Row>
            <Col md="12">
            <div className="mb-3">
                <Label className="form-label">Auth Key</Label>
                <Input
                    name="authKey"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.authKey ? formik.values.authKey : ""}
                    invalid={
                    formik.touched.authKey && formik.errors.authKey ? true : false
                    }
                    disabled={isLoading}
                />
                {formik.touched.authKey && formik.errors.basauthKeyeUrl ? (
                    <FormFeedback type="invalid">{formik.errors.authKey}</FormFeedback>
                ) : null}
                </div>
            </Col>
            </Row>
            <Row>
            <Col md="12">
            <div className="mb-3">
                <Label className="form-label">Auth Value</Label>
                <Input
                    name="authValue"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.authValue ? formik.values.authValue : ""}
                    invalid={
                    formik.touched.authValue && formik.errors.authValue ? true : false
                    }
                    disabled={isLoading}
                />
                {formik.touched.authValue && formik.errors.authValue ? (
                    <FormFeedback type="invalid">{formik.errors.authValue}</FormFeedback>
                ) : null}
                </div>
            </Col>
            </Row></>}
            {formik.values.authType == AUTH_TYPE.BASIC_AUTH && <Row>
            <Col md="6">
            <div className="mb-3">
                <Label className="form-label">Username</Label>
                <Input
                    name="username"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.username ? formik.values.username : ""}
                    invalid={
                    formik.touched.username && formik.errors.username ? true : false
                    }
                    disabled={isLoading}
                />
                {formik.touched.username && formik.errors.username ? (
                    <FormFeedback type="invalid">{formik.errors.username}</FormFeedback>
                ) : null}
                </div>
            </Col>
            <Col md="6">
            <div className="mb-3">
                <Label className="form-label">Password</Label>
                <Input
                    name="password"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password ? formik.values.password : ""}
                    invalid={
                    formik.touched.password && formik.errors.password ? true : false
                    }
                    disabled={isLoading}
                />
                {formik.touched.password && formik.errors.password ? (
                    <FormFeedback type="invalid">{formik.errors.password}</FormFeedback>
                ) : null}
                </div>
            </Col>
            </Row>}
            {formik.values.authType == AUTH_TYPE.BEARER_TOKEN && <Row>
            <Col md="12">
            <div className="mb-3">
                <Label className="form-label">Token</Label>
                <Input
                    name="token"
                    type="textarea"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.token}
                    invalid={
                    formik.touched.token && formik.errors.token ? true : false
                    }
                    disabled={isLoading}
                />
                {formik.touched.token && formik.errors.token ? (
                    <FormFeedback type="invalid">{formik.errors.token}</FormFeedback>
                ) : null}
                </div>  
            </Col>
            </Row>}
            <Row>
            <Col md="12">
                <TableContainerV8
                    columns={columns}
                    data={entries}
                    initialSortBy={"key"}
                    isGlobalFilter={true}
                    addButtonLabel={"Entry"}
                    onAddClick={onAddClick}
                    customPageSize={5}
                    className="custom-header-css"
                />

            </Col>
            </Row>
            <Row>
            <Col md="12">
            <div className="text-end mt-2">
                <button type="button" className="btn btn-secondary" onClick={() => onClose()} disabled={isLoading}>Cancel</button>
                &nbsp;&nbsp;
                <button
                    type="submit"
                    className="btn btn-success save-integration"
                    disabled={isLoading}
                >
                    Save
                </button>
                
                </div>
            </Col>
            </Row>
            <HeaderEntryModal
                show={modal}
                isEdit={isEntryEdit}
                endpoint={formik.values.id}
                entityData={entityData}
                onClose={handleModalClose}
      />
      <DummyDeleteModal
                show={dummyModal}
                entityData={entityData}
                onClose={handleDummyModalClose}
      />
        </Form>
        
        </ModalBody>
    </Modal>
    )
}

export default AppEndpointModal