import React, { useCallback, useContext, useState } from "react";
import { Handle, NodeResizeControl, Position } from 'reactflow';
import { Button, Col, Row } from "reactstrap";
import { CLIENT_SYNC_TYPE, ELSE_BEHAVIOR_TYPE } from "services/intzEnum";
import { getBehaviorCssClasses } from "../IntegrationUIHelper";
import SimpleNoticeModal from "components/Common/SimpleNoticeModal";
import IntegrationLogModal from "pages/IntegrationLog/IntegrationLogModal";
import { IntzServiceContext } from "services/intzService";
import ConditionNodeModal from "./ConditionNodeModal";
import { useDispatch } from "react-redux";
import { updateNode } from "store/integration/actions";
import ResizeIcon from "./ResizeIcon";
const handleStyle = { left: 10 };
const controlStyle = {
  background: 'transparent',
  border: 'none',
  zIndex: 500
};
function ConditionNode({ data }) {

  const { intzSvc } = useContext(IntzServiceContext);
  const [modal, setModal] = useState(false);
  const [noticeModal, setNoticeModal] = useState(false);
  const [logId, setLogId] = useState();
  const [editModal, setEditModal] = useState(false);
  const [entityData, setEntityData] = useState({})
  const dispatch = useDispatch();

  const onChange = useCallback((evt) => {
    console.log(evt.target.value);
  }, []);

const showLastLog = () => {
  if (data.id) {
    intzSvc.searchIntegrationLog(1,0,'loggedOn','desc','nodeId eq ' + data.id).then(({data}) => {
      if(data['@odata.count']>0) {
        setLogId(data.value[0].id);
        setModal(true);
      } else {
        setNoticeModal(true);
      }
  });
  }
}

const showEditModal = () => {
  setEntityData({...data});
  setEditModal(true);
}

const handleEditModalClose = (editedNode) => {
  if(editedNode) {
    dispatch(updateNode(editedNode));
  }
  setEditModal(false);
  setEntityData({});
}

const handleModalClose = () => {
  setModal(false);
};
const handleNoticeModalClose = () => {
  setNoticeModal(false);
};
const handleResize = (event,params) => {
  var editedNode = {...data, width:params.width, height:params.height, syncType: data.syncType == CLIENT_SYNC_TYPE.DELETED ? CLIENT_SYNC_TYPE.DELETED : CLIENT_SYNC_TYPE.UPDATED};
  dispatch(updateNode(editedNode));
};
  return (
    <>
    <NodeResizeControl style={controlStyle} minWidth={450} minHeight={120} onResize={handleResize}>
        <ResizeIcon />
      </NodeResizeControl>
      
      <div className={data.syncType == CLIENT_SYNC_TYPE.DELETED ? "intz-node intz-node-deleted" : "intz-node"} style={data.isLogging ? {borderColor:"#f1b44c"} : {}}>
      <Handle id="top" type="target" position={Position.Top} isConnectable={!data.isStartNode} />
      <Handle id="left" type="source" position={Position.Left}/>
      {data.condition.elseBehaviorType == ELSE_BEHAVIOR_TYPE.ELSENODE && <Handle id="right" type="source" position={Position.Right}/>}
      <Handle id="bottom" type="source" position={Position.Bottom}/>
      <Row>
        <Col><span className='badge badge-soft-info'>Condition </span> <b>{data.description} {data.syncType == CLIENT_SYNC_TYPE.UPDATED && "*"}<span className='badge badge-soft-primary'>{data.syncType == CLIENT_SYNC_TYPE.CREATED && "NEW" }</span></b>
        <i className="bx bx-cog" style={{float:"right", marginLeft:"20px"}} onClick={showEditModal}></i>
        {data.isGlobalExceptionNode && <span className='badge badge-soft-danger' style={{float:"right", marginLeft:"50px"}}>Global Exception</span>}
        {data.isStartNode && <span className='badge badge-soft-success' style={{float:"right", marginLeft:"50px"}}>Start Node</span>}</Col>
      </Row>
      <Row>
        <Col md="2" style={{minWidth: "150px"}}>Else Behavior</Col>
        <Col>{data.condition.elseBehaviorType ? <span className={getBehaviorCssClasses(data.condition.elseBehaviorType)}>{data.condition.elseBehaviorType}</span> : '-'}</Col>
      </Row>
      <Row>
        <Col md="2" style={{minWidth: "150px"}}>Expression</Col>
        <Col>{data.condition.expression || '-'}</Col>
      </Row>
      {data.syncType != CLIENT_SYNC_TYPE.DELETED && <Button size='sm' style={{position:"absolute", left:"10px",bottom:"10px"}} onClick={showLastLog}>Show last log</Button>}
      <IntegrationLogModal
                show={modal}
                id={logId}
                onCloseClick={handleModalClose}/>
      <SimpleNoticeModal show={noticeModal} onCloseClick={handleNoticeModalClose} message="No log found"/>
      <ConditionNodeModal
                show={editModal}
                data={entityData}
                isEdit={true}
                onCloseClick={handleEditModalClose}/>
    </div>
    </>
  );
}

export default ConditionNode;