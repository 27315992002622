import SimpleNoticeModal from "components/Common/SimpleNoticeModal";
import IntegrationLogModal from "pages/IntegrationLog/IntegrationLogModal";
import React, { useCallback, useContext, useState } from "react";
import { Handle, Position,NodeResizeControl } from 'reactflow';
import { Button, Col, Row, Table } from "reactstrap";
import { CLIENT_SYNC_TYPE } from "services/intzEnum";
import { IntzServiceContext } from "services/intzService";
import AppActionNodeModal from "./AppActionNodeModal";
import { useDispatch } from 'react-redux';
import { updateNode } from "store/integration/actions";
import { memo } from 'react';
import ResizeIcon from "./ResizeIcon";
const handleStyle = { left: 10 };
const controlStyle = {
  background: 'transparent',
  border: 'none',
  zIndex: 500
};
function AppActionNode({ data }) {

  const { intzSvc } = useContext(IntzServiceContext);
  const [logModal, setLogModal] = useState(false);
  const [noticeModal, setNoticeModal] = useState(false);
  const [logId, setLogId] = useState();
  const [editModal, setEditModal] = useState(false);
  const [entityData, setEntityData] = useState({})
  const dispatch = useDispatch();
  const onChange = useCallback((evt) => {
    console.log(evt.target.value);
  }, []);

const showLastLog = () => {
  if (data.id) {
    intzSvc.searchIntegrationLog(1,0,'loggedOn','desc','nodeId eq ' + data.id).then(({data}) => {
      if(data['@odata.count']>0) {
        setLogId(data.value[0].id);
        setLogModal(true);
      } else {
        setNoticeModal(true);
      }
  });
  }
}

const showEditModal = () => {
  setEntityData({...data});
  setEditModal(true);
}
const handleEditModalClose = (editedNode) => {
  // update with callback
  if(editedNode) {
    //intzSvc.notifyUpdateNode(editedNode);
    dispatch(updateNode(editedNode));
  }
  setEditModal(false);
  setEntityData({});
}
const handleLogModalClose = () => {
  setLogModal(false);
};
const handleNoticeModalClose = () => {
  setNoticeModal(false);
};
const handleResize = (event,params) => {
  var editedNode = {...data, width:params.width, height:params.height, syncType: data.syncType == CLIENT_SYNC_TYPE.DELETED ? CLIENT_SYNC_TYPE.DELETED : CLIENT_SYNC_TYPE.UPDATED};
  dispatch(updateNode(editedNode));
};
  return (<>
    <NodeResizeControl style={controlStyle} minWidth={400} minHeight={200} onResize={handleResize}>
        <ResizeIcon />
      </NodeResizeControl>
    <div className={data.syncType == CLIENT_SYNC_TYPE.DELETED ? "intz-node intz-node-deleted" : "intz-node"} style={data.isLogging ? {borderColor:"#f1b44c"} : {}}>
      <Handle id="top" type="target" position={Position.Top} isConnectable={!data.isStartNode} />
      <Handle id="left" type="source" position={Position.Left}/>
      {/*<Handle id="right" type="source" position={Position.Right}/>*/}
      <Handle id="bottom" type="source" position={Position.Bottom}/>
      <Row>
        <Col><span className='badge badge-soft-warning'>App Action </span> <b>{data.description} {data.syncType == CLIENT_SYNC_TYPE.UPDATED && "*"}<span className='badge badge-soft-primary'>{data.syncType == CLIENT_SYNC_TYPE.CREATED && "NEW" }</span></b>
        <i className="bx bx-cog" style={{float:"right", marginLeft:"20px"}} onClick={() => {showEditModal()}}></i>
        {data.isGlobalExceptionNode && <span className='badge badge-soft-danger' style={{float:"right", marginLeft:"50px"}}>Global Exception</span>}
        {data.isStartNode && <span className='badge badge-soft-success' style={{float:"right", marginLeft:"50px"}}>Start Node</span>}</Col>
      </Row>
      <Row>
        <Col md="2" style={{minWidth: "150px"}}>Endpoint</Col>
        <Col>{data.appAction?.endpoint?.description || '-'}</Col>
      </Row>
      <Row>
        <Col md="2" style={{minWidth: "150px"}}>Method Type</Col>
        <Col>{data.appAction?.methodType || '-'}</Col>
      </Row>
      <Row>
        <Col md="2" style={{minWidth: "150px"}}>Route</Col>
        <Col style={{wordBreak: "break-all"}}>{data.appAction?.route || '-'}</Col>
      </Row>
      <Row>
        <Col md="2" style={{minWidth: "150px"}}>Request Property</Col>
        <Col>{data.appAction?.requestPropertyName || '-'}</Col>
        
      </Row>
      <Row>
        <Col md="2" style={{minWidth: "150px"}}>Result Property</Col>
        <Col>{data.appAction?.resultPropertyName || '-'}</Col>
      </Row>
      <Row>
        <Col md="2" style={{minWidth: "150px"}}>Assertion</Col>
        <Col style={{wordBreak: "break-all"}}>{data.appAction?.assertion || '-'}</Col>
      </Row>
      {data.nodeParameters?.length > 0 &&<Row style={{padding:"5px"}}>
        <Col>
        <Table striped bordered hover size="sm" style={{borderColor:"#54586d", marginTop:"15px"}}>
      <thead>
        <tr>
          <th>#</th>
          <th>Key</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
      {data.nodeParameters?.map(m => 
            <tr key={m.id}>
                <td>
                {m.id ? <span className={m.syncType == CLIENT_SYNC_TYPE.DELETED ? "text-decoration-line-through text-secondary" : ""}>{m.id}</span> : <span className={"badge badge-soft-success"}>new</span>}
                </td>
                <td>
                {m.key ? <span className={m.syncType == CLIENT_SYNC_TYPE.DELETED ? "text-decoration-line-through text-secondary" : ""}>{m.key}</span> : <span className='badge badge-soft-secondary'>None </span>}
                </td>
                <td>
                {m.value ? <span className={m.syncType == CLIENT_SYNC_TYPE.DELETED ? "text-decoration-line-through text-secondary" : ""}>{m.value}</span> : <span className='badge badge-soft-secondary'>None </span>}
                </td>
            </tr>
        )}
      </tbody>
    </Table>
        
        </Col>
      </Row>}
      {data.syncType != CLIENT_SYNC_TYPE.DELETED && <Button size='sm' style={{position:"absolute", left:"10px",bottom:"10px"}} onClick={showLastLog}>Show last log</Button>}
      
      <IntegrationLogModal
                show={logModal}
                id={logId}
                onCloseClick={handleLogModalClose}/>
      <SimpleNoticeModal show={noticeModal} onCloseClick={handleNoticeModalClose} message="No log found"/>
      <AppActionNodeModal
                show={editModal}
                data={entityData}
                onCloseClick={handleEditModalClose}/>
    </div>
    </>
  );
}


export default AppActionNode;