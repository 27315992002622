import React, { useEffect, useState, useMemo, useContext } from "react";
import Moment from 'react-moment';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
  FormFeedback,
  Label,
  Form,
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import { IntzServiceContext } from "services/intzService";
import ControlledTableContainerV8 from "components/Common/ControlledTableContainerV8";
import { getRecurringTypeCssClasses, getStatusCssClasses } from './ConfigurationUIHelper';
import ConfigurationModal from "./ConfigurationModal";

// Column
const Id = (cell) => {
  return cell.getValue() ? "#CO" + cell.getValue() : '';
};

const Name = (cell) => {
    return cell.getValue() ? cell.getValue() : '';
};

const Description = (cell) => {
    return cell.getValue() ? cell.getValue() : '';
};

const RecurringType = (cell) => {
  return cell.getValue() ? <span className={getRecurringTypeCssClasses(cell.getValue())}>{cell.getValue()}</span> : '';
};
const Cron = (cell) => {
  return cell.getValue() ? cell.getValue() : '';
};

const Status = (cell) => {
    return cell.getValue() ? <span className={getStatusCssClasses(cell.getValue())}>{cell.getValue()}</span> : '';
};

const CreatedOn = (cell) => {
    return cell.getValue() ? <Moment format='ll'>{cell.getValue()}</Moment>: '';
};

const ConfigurationList = props => {

  //meta title
  document.title = "Configurations | Integratiz";
  const { intzSvc } = useContext(IntzServiceContext);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
    //delete 
  const [deleteModal, setDeleteModal] = useState(false);
  const [entityData, setEntityData] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  const [rerender, setRerender] = useState(0);
  const [integrationId, setIntegrationId] = useState();
  const [integrationList, setIntegrationList] = useState([]);
  const handleConfigurationClick = arg => {

  };

  // Customber Column
  const columns = useMemo(
    () => [

      {
        header: 'Id',
        accessorKey: 'id',
        filterable: false,
        enableSorting: true,
        cell: (cellProps) => {
          return <Id {...cellProps} />;
        }
      },
      {
        header: 'Name',
        accessorKey: 'name',
        filterable: false,
        enableSorting: true,
        cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },
      {
        header: 'Description',
        accessorKey: 'description',
        enableSorting: true,
        filterable: false,
        cell: (cellProps) => {
          return <Description {...cellProps} />;
          ;
        }
      },
      {
        header: 'Recurring Type',
        accessorKey: 'recurringType',
        enableSorting: false,
        filterable: false,
        cell: (cellProps) => {
          return <RecurringType {...cellProps} />;
        }
      },
      {
        header: 'Cron',
        accessorKey: 'cronExpression',
        enableSorting: false,
        filterable: false,
        cell: (cellProps) => {
          return <Cron {...cellProps} />;
        }
      },
      {
        header: 'Status',
        accessorKey: 'status',
        enableSorting: false,
        filterable: false,
        cell: (cellProps) => {
          return <Status {...cellProps} />;
        }
      },
      {
        header: 'Created On',
        accessorKey: 'createdOn',
        enableSorting: true,
        cell: (cellProps) => {
          return <CreatedOn {...cellProps} />;
        }
      },
      {
        header: 'Action',
        enableSorting: false,
        cell: (cellProps) => {
          return (
            <UncontrolledDropdown >
              <DropdownToggle tag="a" href="#" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem href="#"
                  onClick={() => {
                    const entityData = cellProps.row.original;
                    onEditClick(entityData);
                  }
                  }
                >
                  <i className="mdi mdi-pencil font-size-16 text-success me-1" id="edittooltip"></i>
                  Edit
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </DropdownItem>
                <DropdownItem href="#"
                  onClick={() => {
                    const entityData = cellProps.row.original;
                    onActivateClick(entityData);
                  }}>
                  <i className="mdi mdi-run-fast font-size-16 text-primary me-1" id="activatetooltip"></i>
                  Activate
                  <UncontrolledTooltip placement="top" target="activatetooltip">
                    Activate
                  </UncontrolledTooltip>
                </DropdownItem>
                <DropdownItem href="#"
                  onClick={() => {
                    const entityData = cellProps.row.original;
                    onDeactivateClick(entityData);
                  }}>
                  <i className="mdi mdi-motion-pause-outline font-size-16 text-warning me-1" id="deactivatetooltip"></i>
                  Deactivate
                  <UncontrolledTooltip placement="top" target="deactivatetooltip">
                    Deactivate
                  </UncontrolledTooltip>
                </DropdownItem>
                <DropdownItem href="#"
                  onClick={() => {
                    const entityData = cellProps.row.original;
                    onDeleteClick(entityData);
                  }}>
                  <i className="mdi mdi-trash-can font-size-16 text-danger me-1" id="deletetooltip"></i>
                  Delete
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        }
      },
    ],
    [rerender]
  );

  const onDeleteClick = (entityData) => {
    setEntityData(entityData);
    setErrorMessage("");
    setDeleteModal(true);
  };

  const onActivateClick = (entityData) => {
    
    if (entityData && entityData.id) {
      intzSvc.activateIntegrationConfiguration(entityData.id).then(({data}) => {
        setRerender(rerender + 1);
      });
    }
  };

  const onDeactivateClick = (entityData) => {
    if (entityData && entityData.id) {
      intzSvc.deactivateIntegrationConfiguration(entityData.id).then(({data}) => {
        setRerender(rerender + 1);
      });
    }
  };

  const handleDeleteEntity = () => {
    if (entityData && entityData.id) {
      intzSvc.deleteIntegrationConfiguration(entityData.id).then(({data}) => {
        setDeleteModal(false);
        setRerender(rerender + 1);
      }).catch(err => {
        setErrorMessage(err.response.data);
      });
    }
  };

  const onEditClick = (entityData) => {
    setEntityData(entityData);
    setErrorMessage("");
    setIsEdit(true);
    setModal(true);
  };
  const onAddClick = () => {
    setEntityData({});
    setErrorMessage("");
    setIsEdit(false);
    setModal(true);
  };
  const handleModalClose = (isUpdated) => {
      if(isUpdated) {
        setRerender(rerender + 1);
      }
      setModal(false);
    
  };
  useEffect(() => {
    intzSvc.searchIntegration(100,0,'createdOn','desc').then(({data}) => {
      setIntegrationList(data.value);
    }).finally(() => {
    });
  }, []);


  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEntity}
        onCloseClick={() => setDeleteModal(false)}
        entityName={"configuration"}
        errorMessage={errorMessage}
      />
      <div className="page-content">
        <Container fluid>
        <Breadcrumbs title="Configuration" breadcrumbItem="List" 
          altTitle={"LIST : "} button={
            <select
            className="form-select form-select-sm mt-1"
            value={integrationId}
            onChange={(e) => {
              setIntegrationId(e.target.value);
              setRerender(rerender +  1);
            }}>
            <option key={''} value={''}>
            All Integrations
              </option>
            {integrationList.map(integration => (
              <option key={integration.id} value={integration.id}>
                {integration.description}
              </option>
            ))}
          </select>
          } />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <ControlledTableContainerV8
                    columns={columns}
                    odataFetch={intzSvc.searchIntegrationConfiguration}
                    initialSortBy={"name"}
                    isGlobalFilter={true}
                    fetchCondition={integrationId ? "integrationId eq "+ integrationId : ""}
                    addButtonLabel={"Configuration"}
                    onAddClick={onAddClick}
                    customPageSize={10}
                    rerender={rerender}
                    className="custom-header-css"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ConfigurationModal
                show={modal}
                isEdit={isEdit}
                id={entityData?.id}
                onClose={handleModalClose}
                errorMessage={errorMessage}
      />
    </React.Fragment>
  );
};

export default ConfigurationList;