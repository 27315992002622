import {
    SET_INTEGRATION,
    UPDATE_NODE,
    UPDATE_TRANSACTION
  } from "./actionTypes"
  
  export const updateNode = (node) => ({
    type: UPDATE_NODE,
    payload: node
  })

  export const updateTransaction = (transactionConfiguration) => ({
    type: UPDATE_TRANSACTION,
    payload: transactionConfiguration
  })

  export const setIntegration = (integration) => ({
    type: SET_INTEGRATION,
    payload: integration
  })