export const ACTION_TYPE = {
    WHEN : 'WHEN',
    DO : 'DO'
}

export const INTEGRATION_STATUS = {
    DRAFT : 'DRAFT',
    ACTIVE : 'ACTIVE',
    DISABLED : 'DISABLED',
    DELETED : 'DELETED'
}

export const INTEGRATION_CONFIGURATION_STATUS = {
    DRAFT : 'DRAFT',
    LIVE : 'LIVE',
    OFFLINE : 'OFFLINE',
    DELETED : 'DELETED'
}

export const RECURRING_TYPE = {
    NONE : 'NONE',
    CRON : 'CRON',
    CHAINED : 'CHAINED',
}

export const METHOD_TYPE = {
    GET : 'GET',
    POST : 'POST',
    PUT : 'PUT',
    PATCH : 'PATCH',
    DELETED : 'DELETED'
}

export const AUTH_TYPE = {
    NO_AUTH : 'NO_AUTH',
    API_KEY : 'API_KEY',
    BEARER_TOKEN : 'BEARER_TOKEN',
    BASIC_AUTH : 'BASIC_AUTH'
}

export const USER_STATUS = {
    ACTIVE : 'ACTIVE',
    INACTIVE : 'INACTIVE',
    DELETED : 'DELETED'
}

export const INTEGRATION_TRANSACTION_STATUS = {
    PROCESSING : 'PROCESSING',
    FAILED : 'FAILED',
    SUCCESS_WITH_WARNING : 'SUCCESS_WITH_WARNING',
    SUCCESS : 'SUCCESS'
}

export const INTEGRATION_LOG_TYPE = {
    ENTRY : 'ENTRY',
    NODE : 'NODE',
    EXIT : 'EXIT',
    ERROR : 'ERROR'
}

export const CLIENT_SYNC_TYPE = {
    NONE : 'NONE',
    CREATED : 'CREATED',
    UPDATED : 'UPDATED',
    DELETED : 'DELETED'
}

export const NODE_TYPE = {
    UNKNOWN : 'UNKNOWN',
    APPACTION : 'APPACTION',
    CONDITION : 'CONDITION',
    TRANSFORM : 'TRANSFORM',
    ITERATOR : 'ITERATOR',
    TRANSACTION : 'TRANSACTION', // Client type only
}

export const ELSE_BEHAVIOR_TYPE = {
    BREAK : 'BREAK',
    EXCEPTION : 'EXCEPTION',
    ELSENODE : 'ELSENODE'
}

export const RATE_LIMIT_THRESHOLD_TYPE = {
    REQUESTS_PER_SECOND: 'REQUESTS_PER_SECOND',
    SECONDS: 'SECONDS'
}

export const TARGET_TYPE = {
    INPUT : 'INPUT',
    OUTPUT : 'OUTPUT',
    CONTEXT : 'CONTEXT',
    PARENT : 'PARENT'
}