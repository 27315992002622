import axios from 'axios';
import React from 'react';

export const IntzServiceContext = React.createContext();


class IntzService {

    constructor() { }

    // integration
    getIntegration(id) {
        return axios.get(process.env.REACT_APP_INTZ_API_URL + "/Integration/" + id);
    }
    updateIntegration(integration) {
        return axios.put(process.env.REACT_APP_INTZ_API_URL + "/Integration/" + integration.id,integration);
    }
    createIntegration(integration) {
        return axios.post(process.env.REACT_APP_INTZ_API_URL + "/Integration" , integration);
    }
    deleteIntegration(id) {
        return axios.delete(process.env.REACT_APP_INTZ_API_URL + "/Integration/" + id);
    }
    searchIntegration(top, skip, orderBy, order, query) {
        return axios.get(process.env.REACT_APP_INTZ_ODATA_URL + "/Integrations?$select=id,name,status,description,createdOn&$expand=ownedBy($select=id,firstName,lastName)&$count=true&$top=" + top + "&$skip=" + skip + "&$orderby=" + orderBy + " " + order + (query ? "&$filter=" + encodeURIComponent(query) : ""));
    }

    //configuration
    getIntegrationConfiguration(id) {
        return axios.get(process.env.REACT_APP_INTZ_API_URL + "/IntegrationConfiguration/" + id);
    }
    createIntegrationConfiguration(configuration) {
        return axios.post(process.env.REACT_APP_INTZ_API_URL + "/IntegrationConfiguration" , configuration);
    }
    updateIntegrationConfiguration(configuration) {
        return axios.put(process.env.REACT_APP_INTZ_API_URL + "/IntegrationConfiguration/" + configuration.id,configuration);
    }
    deleteIntegrationConfiguration(id) {
        return axios.delete(process.env.REACT_APP_INTZ_API_URL + "/IntegrationConfiguration/" + id);
    }
    searchIntegrationConfiguration(top, skip, orderBy, order, query) {
        return axios.get(process.env.REACT_APP_INTZ_ODATA_URL + "/IntegrationConfigurations?$select=id,name,status,recurringType,cronExpression,status,description,createdOn&$count=true&$top=" + top + "&$skip=" + skip + "&$orderby=" + orderBy + " " + order + (query ? "&$filter=" + encodeURIComponent(query) : ""));
    }
    activateIntegrationConfiguration(id) {
        return axios.put(process.env.REACT_APP_INTZ_API_URL + "/IntegrationConfiguration/" + id + "/Activate");
    }
    deactivateIntegrationConfiguration(id) {
        return axios.put(process.env.REACT_APP_INTZ_API_URL + "/IntegrationConfiguration/" + id + "/Deactivate");
    }
    
    // app
    getApp(id) {
        return axios.get(process.env.REACT_APP_INTZ_API_URL + "/App/" + id);
    }
    createApp(app) {
        return axios.post(process.env.REACT_APP_INTZ_API_URL + "/App" , app);
    }
    updateApp(app) {
        return axios.put(process.env.REACT_APP_INTZ_API_URL + "/App/" + app.id,app);
    }
    deleteApp(id) {
        return axios.delete(process.env.REACT_APP_INTZ_API_URL + "/App/" + id);
    }
    searchApp(top, skip, orderBy, order, query) {
        return axios.get(process.env.REACT_APP_INTZ_ODATA_URL + "/Apps?$select=id,description,createdOn&$count=true&$top=" + top + "&$skip=" + skip + "&$orderby=" + orderBy + " " + order + (query ? "&$filter=" + encodeURIComponent(query) : ""));
    }

    // app action
    getAppAction(id) {
        return axios.get(process.env.REACT_APP_INTZ_API_URL + "/AppAction/" + id);
    }
    createAppAction(action) {
        return axios.post(process.env.REACT_APP_INTZ_API_URL + "/AppAction" , action);
    }
    updateAppAction(action) {
        return axios.put(process.env.REACT_APP_INTZ_API_URL + "/AppAction/" + action.id,action);
    }
    deleteAppAction(id) {
        return axios.delete(process.env.REACT_APP_INTZ_API_URL + "/AppAction/" + id);
    }
    searchAppAction(top, skip, orderBy, order, query) {
        return axios.get(process.env.REACT_APP_INTZ_ODATA_URL + "/AppActions?$select=id,description,methodType,route,createdOn&$count=true&$top=" + top + "&$skip=" + skip + "&$orderby=" + orderBy + " " + order + (query ? "&$filter=" + encodeURIComponent(query) : ""));
    }

    // app endpoint
    getAppEndpoint(id) {
        return axios.get(process.env.REACT_APP_INTZ_API_URL + "/AppEndpoint/" + id);
    }
    createAppEndpoint(endpoint) {
        return axios.post(process.env.REACT_APP_INTZ_API_URL + "/AppEndpoint" , endpoint);
    }
    updateAppEndpoint(endpoint) {
        return axios.put(process.env.REACT_APP_INTZ_API_URL + "/AppEndpoint/" + endpoint.id,endpoint);
    }
    deleteAppEndpoint(id) {
        return axios.delete(process.env.REACT_APP_INTZ_API_URL + "/AppEndpoint/" + id);
    }
    searchAppEndpoint(top, skip, orderBy, order, query) {
        return axios.get(process.env.REACT_APP_INTZ_ODATA_URL + "/AppEndpoints?$select=id,description,baseUrl,threshold,authType,createdOn&$count=true&$top=" + top + "&$skip=" + skip + "&$orderby=" + orderBy + " " + order + (query ? "&$filter=" + encodeURIComponent(query) : ""));
    }

    // tranaction
    getIntegrationTransaction(id) {
        return axios.get(process.env.REACT_APP_INTZ_API_URL + "/IntegrationTransaction/" + id);
    }
    searchTransaction(top, skip, orderBy, order, query) {
        return axios.get(process.env.REACT_APP_INTZ_ODATA_URL + "/IntegrationTransactions?$select=id,key,status,retry,result,errorMessage,startedOn,finishedOn&$expand=integrationConfiguration($select=id,name)&$count=true&$top=" + top + "&$skip=" + skip + "&$orderby=" + orderBy + " " + order + (query ? "&$filter=" + encodeURIComponent(query) : ""));
    }


    searchUser(top, skip, orderBy, order, query) {
        return axios.get(process.env.REACT_APP_INTZ_ODATA_URL + "/IntzUsers?$select=id,email,firstName,lastName,status,createdOn&$count=true&$top=" + top + "&$skip=" + skip + "&$orderby=" + orderBy + " " + order + (query ? "&$filter=" + encodeURIComponent(query) : ""));
    }


    // log
    getIntegrationLog(id) {
        return axios.get(process.env.REACT_APP_INTZ_API_URL + "/IntegrationLog/" + id);
    }
    searchIntegrationLog(top, skip, orderBy, order, query) {
        return axios.get(process.env.REACT_APP_INTZ_ODATA_URL + "/IntegrationLogs?$select=id,transactionKey,type,nodeId,loggedOn&$expand=integrationConfiguration($select=id,name)&$count=true&$top=" + top + "&$skip=" + skip + "&$orderby=" + orderBy + " " + order + (query ? "&$filter=" + encodeURIComponent(query) : ""));
    }

    // node
    searchNode(top, skip, orderBy, order, query) {
        return axios.get(process.env.REACT_APP_INTZ_ODATA_URL + "/Nodes?$select=id,description,type,appActionId,conditionId,iteratorId,sideNodeId,nextNodeId,exceptionNodeId,isLogging&$count=true&$top=" + top + "&$skip=" + skip + "&$orderby=" + orderBy + " " + order + (query ? "&$filter=" + encodeURIComponent(query) : ""));
    }
    createNode(node) {
        return axios.post(process.env.REACT_APP_INTZ_API_URL + "/Node", node);
    }

    // transaction configuration
    createTransactionConfiguration(tc) {
        return axios.post(process.env.REACT_APP_INTZ_API_URL + "/TransactionConfiguration", tc);
    }



    makeFilterString(filters) {
        // {field:"",operator:"",value:""}
        var returnString = "";
        filters.forEach(entry => {
            if ((entry.value || entry.value === false || entry.value === null) && entry.field) {
                returnString = returnString.concat((returnString == "" ? "" : " and ") + (entry.field + " " + entry.operator + " " +
                    (entry.value === null ? "null" : (typeof entry.value === 'string' || entry.value instanceof String) ? ("'" + entry.value + "'") :
                        ((typeof entry.value.getMonth === 'function' || entry.value instanceof Date) ? ("cast(" + entry.value.toISOString() + ",Edm.DateTimeOffset)") : 
                        entry.value))));
            } else if (entry.raw) {
                returnString = returnString.concat((returnString == "" ? "" : " and ") + entry.raw);
            }
        });
        return returnString;
    }
    insertUrlParam(kvObj) {
        if (kvObj && Object.keys(kvObj).length>0) {
            let searchParams = new URLSearchParams(window.location.search);
            Object.keys(kvObj).forEach(key => {
                if (kvObj[key] !== '') {
                    searchParams.set(key, kvObj[key]) }
                    else {
                    searchParams.delete(key);
                }
            })
            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
            window.history.replaceState({ path: newurl }, '', newurl);
        } else if (Object.keys(kvObj).length == 0) {
            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
            window.history.replaceState({ path: newurl }, '', newurl);
        }
    }
}

export default IntzService;